import { IdSchemaEnum } from "../../../Configurator/Enums/IdSchemaEnum";
import SDKConfigurationModel from "../../../Configurator/Models/SDKConfiguraitonModel";
import PredictorHttps from "../../../Https/PredictorHttps";
import IdMappingService from "../../../IdMapping/IdMappingService";
import FixturesResponseModel from "../../Predictor/Models/Fixtures/FixturesResponseModel";
import CorrectScoreFixtureModel from "../../Predictor/Models/Fixtures/Markets/CorrectScoreFixtureModel";
import PredictionResponseModel from "../../Predictor/Models/Predictions/PredictionResponseModel";
import PredictorService from "../../Predictor/Service/PredictorService";
import { GameStatusEnum } from "../Enums/GameStatusEnum";
import GamesFilters from "../Models/Games/GamesFilters";
import PaginationModel from "../../../Global/Models/Pagination/PaginationModel";
import TopXPredictionRequestModel from "../Models/Prediction/TopXPredictionRequestModel";
import PredictionsFilters from "../../Predictor/Models/Predictions/PredictionsFilters";
import ClientHttps, { FeatureConfigType } from "../../../Https/ClientHttps";
import { FeaturesConfigModels } from "../../../Global/Types/GlobalTypes";
import GameMarketsResults from "../../MatchQuiz/Models/GameMarketsResults/GameMarketsResults";
import PredictorValidator from "../../Predictor/Validator/PredictorValidator";
import MainFiltersBQ from "../../../Global/Models/Filters/MainFiltersBQ";
import FansUnitedSdkException from "../../../Exception/FansUnitedSdkException";
import { ErrorStatuses } from "../../../Exception/ErrorStatuses";
import { ErrorMessages } from "../../../Global/Messages/Messages";
import { ErrorCodes } from "../../../Exception/ErrorCodes";
import ContestWinners from "../Models/Games/Winners/ContestWinners";
import { ErrorHandlingModeType } from "../../../Configurator/Types/ConfiguratorTypes";
import StandardFansUnitedException from "../../../Exception/StandardFansUnitedException";
import GameByIdModel from "../Models/Games/GameByIdModel";

export default class TopXFacade {
    readonly idMapping: IdMappingService = null;
    readonly predictorHttps: PredictorHttps = null;
    readonly predictorService: PredictorService = null;
    readonly predictorValidator: PredictorValidator = null;
    private clientHttps: ClientHttps = null;
    private errorHandlingMode: ErrorHandlingModeType = null;

    constructor(config: SDKConfigurationModel, idMapping: IdMappingService) {
        this.idMapping = idMapping;
        this.errorHandlingMode = config.errorHandlingMode;
        this.predictorValidator = new PredictorValidator(config.errorHandlingMode);
        this.predictorHttps = new PredictorHttps(config, idMapping);
        this.predictorService = new PredictorService(config, idMapping);
        this.clientHttps = new ClientHttps(config);
    }

    public getConfig = async (): Promise<FeaturesConfigModels> => {
        const config = await this.clientHttps.getConfig(FeatureConfigType.TOP_X);

        if (this.idMapping.idSchema !== IdSchemaEnum.NATIVE) {
            return await this.predictorService.remapCompetitionsFromConfig(config);
        }

        return config;
    };

    public getGames = async (filters?: GamesFilters, disableCache?: boolean): Promise<PaginationModel> => {
        disableCache = !disableCache ? false : disableCache;

        const newFilters = this.predictorService.initGameFilters("TOP_X", filters);
        const paginatedGames = await this.predictorHttps.getGames(newFilters, disableCache);

        return await this.predictorService.remapMatchIdsFixtures(paginatedGames);
    };

    public getGameById = async (gameId: string, disableCache?: boolean): Promise<GameByIdModel> => {
        disableCache = !disableCache ? false : disableCache;
        const game = await this.predictorHttps.getGameById(gameId, disableCache);

        return await this.predictorService.remapMatchIdsFixtures(game);
    };

    public getGamePredictions = async (gameId: string, filters?: PredictionsFilters, disableCache?: boolean): Promise<PaginationModel>=> {
        disableCache = !disableCache ? false : disableCache;

        if (filters) {
            if (filters.type) {
                delete filters.type;
            }

            if (filters.status) {
                delete filters.status;
            }

            filters = new PredictionsFilters(filters);
        }

        return await this.predictorHttps.getGamePredictions(gameId, disableCache, filters);
    };

    public getGameResults = async (gameId: string, filters?: MainFiltersBQ, disableCache?: boolean): Promise<PaginationModel> => {
        disableCache = !disableCache ? false : disableCache;

        if (filters) {
            filters = new GamesFilters(filters);
        }

        const gameResults = await this.predictorHttps.getGameResults(gameId, disableCache, filters);

        return await this.predictorService.remapMatchIdsFixtures(gameResults);
    };

    public getCurrentGameResults = async (disableCache?: boolean) => {
        disableCache = !disableCache ? false : disableCache;

        let newFilters = { status: GameStatusEnum.OPEN, type: "TOP_X"};
        newFilters = new GamesFilters(newFilters);

        const openGames = await this.predictorHttps.getGames(newFilters as GamesFilters, disableCache);
        const gameId = this.predictorService.getCurrentGameId(openGames);

        const activeGameResults = await this.getGameResults(gameId, null, disableCache);

        return activeGameResults.data[0];
    };

    public play = async (topXPrediction: TopXPredictionRequestModel): Promise<PredictionResponseModel> => {
        let prediction = new TopXPredictionRequestModel(topXPrediction);
        prediction = this.predictorValidator.validateBodyFields("TOP_X", prediction);
        //@ts-ignore
        prediction.fixtures = prediction.fixtures.map((fixture: any) => {
            return this.predictorValidator.validateFixture(fixture.matchId, fixture.market, fixture.prediction.value);
        });

        if (this.idMapping.idSchema !== IdSchemaEnum.NATIVE) {
            let matchIds: string[] = [];
            prediction.fixtures.forEach((fixture: CorrectScoreFixtureModel) => matchIds.push(fixture.matchId));

            let nativeMatchIds = await this.predictorService.remapMatchIdToNative(matchIds);
            prediction.fixtures.forEach((fixture: CorrectScoreFixtureModel) => fixture.matchId = nativeMatchIds.shift());
        }

        const response = await this.predictorHttps.makeFootballPrediction(prediction);

        response.fixtures.forEach((fixture: FixturesResponseModel) => {
            fixture.matchId = fixture.matchModel.id;
        });

        return response;
    };

    public delete = async (predictionGameId: string): Promise<boolean> => {
        return await this.predictorHttps.deleteFootballPrediction(predictionGameId);
    };

    public getMyGameEditions = async (filters?: MainFiltersBQ, disableCache?: boolean): Promise<PaginationModel> => {
        if (filters && filters.limit) this.validateLimitFilterOnGames(filters.limit);

        disableCache = !disableCache ? false : disableCache;
        const predictionFilters = this.predictorService.initGameTypePredictions("TOP_X", filters);
        let myGamesIds: string[] = [];

        const myPredictions = await this.predictorHttps.getMyPredictionsNoRemap(predictionFilters);

        if (myPredictions.data.length > 0) {
            myPredictions.data.forEach((prediction: any) => {
                if (prediction.game_instance_id) {
                    myGamesIds.push(prediction.game_instance_id);
                }
            });

            const newFilters = this.predictorService.initGameFilters("TOP_X", null, myGamesIds);
            const myGames = await this.predictorHttps.getUserGameEditions(newFilters, disableCache);

            return await this.predictorService.addPredictionProp(myPredictions, myGames);
        } else {
            return new PaginationModel();
        }
    };

    public getMyGamePrediction = async (gameId: string): Promise<PredictionResponseModel> => {
        const predictionFilters = this.predictorService.initGameTypePredictions("TOP_X");
        const myPredictions = await this.predictorHttps.getMyPredictions(predictionFilters);

        if (myPredictions.data.length > 0) {
            const myGamePrediction = myPredictions.data.filter((prediction: PredictionResponseModel) => prediction.gameInstanceId === gameId);

            return myGamePrediction.length ? myGamePrediction[0] : null;
        }

        return null;
    };

    public getUserGameEditions = async (userId: string, filters?: MainFiltersBQ, disableCache?: boolean): Promise<PaginationModel> => {
        if (filters && filters.limit) this.validateLimitFilterOnGames(filters.limit);

        disableCache = !disableCache ? false : disableCache;
        const predictionFilters = this.predictorService.initGameTypePredictions("TOP_X", filters);
        let gameIds: string[] = [];

        const userPredictions = await this.predictorHttps.getUserPredictionsNoRemap(userId, predictionFilters);

        if (userPredictions.data.length > 0) {
            userPredictions.data.forEach((prediction: any) => {
                if (prediction.game_instance_id) {
                    gameIds.push(prediction.game_instance_id);
                }
            });

            const newFilters = this.predictorService.initGameFilters("TOP_X", null, gameIds);
            const userGames = await this.predictorHttps.getUserGameEditions(newFilters, disableCache);

            return await this.predictorService.addPredictionProp(userPredictions, userGames);
        } else {
            return new PaginationModel();
        }
    };

    public getUserGamePrediction = async (userId: string, gameId: string, disableCache?: boolean): Promise<PredictionResponseModel> => {
        disableCache = !disableCache ? false : disableCache;

        const predictionFilters = this.predictorService.initGameTypePredictions("TOP_X");
        const userPredictions = await this.predictorHttps.getUserPredictions(userId, disableCache, predictionFilters);

        if (userPredictions.data.length > 0) {
            const userGamePrediction = userPredictions.data.filter((prediction: PredictionResponseModel) => prediction.gameInstanceId === gameId);

            return userGamePrediction.length ? userGamePrediction[0] : null;
        } else {
            return null;
        }
    };

    public getMarketsResultsForGame = async (gameId: string, disableCache?: boolean): Promise<GameMarketsResults> => {
        const gameMarketResults = await this.predictorHttps.getMarketResultsForGame(gameId, disableCache);
        const completedGameMarketResults = await this.predictorService.completeGameMarketResults(gameMarketResults, 'TOP_X');

        return completedGameMarketResults;
    };

    public getGameWinners = async (gameId: string): Promise<ContestWinners> => {
        const gameWinners = await this.predictorHttps.getContestWinners(gameId);
        const topXGame = await this.getGameById(gameId);
        gameWinners.contestModel = topXGame;

        return this.predictorService.completeContestWinners(gameWinners);
    };

    /**
     * To prevent passing too long URL for fetching data from Predictor API, the maximum limit is set to 50
     */

    private validateLimitFilterOnGames = (limit: number) => {
        const limitByApi = 50;
        if (this.errorHandlingMode === "default" && limit > limitByApi)
        throw new FansUnitedSdkException(ErrorCodes.BAD_METHOD_CALL, ErrorStatuses.EXCEEDED_LENGTH, ErrorMessages.QUERY_PARAM_FILTERS_LIMIT_EXCEEDED)
        else if (this.errorHandlingMode === "standard" && limit > limitByApi)
        throw new StandardFansUnitedException(ErrorCodes.BAD_METHOD_CALL, ErrorStatuses.EXCEEDED_LENGTH, ErrorMessages.QUERY_PARAM_FILTERS_LIMIT_EXCEEDED).response
    };
}