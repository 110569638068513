import { ErrorCodes } from "../../../Exception/ErrorCodes";
import { ErrorStatuses } from "../../../Exception/ErrorStatuses";
import FansUnitedSdkException from "../../../Exception/FansUnitedSdkException";
import { invalidFieldMessage, invalidMarketMessage, invalidMarketTopXMessage, invalidPredictionMessage, invalidTypeMessage, missingFieldsMessage } from "../../../Global/Messages/Messages";
import { MarketEnum, MarketsOverCorners, MarketsOverGoals, playerMarketNobodyPrediction, playerMarkets } from "../Enums/MarketEnum";
import { DoubleChanceEnum, HalfTimeFullTimeEnum, OneXTwoEnum } from "../Enums/ValueEnums";
import BothTeamsFixtureModel from "../Models/Fixtures/Markets/BothTeamsFixtureModel";
import CornersFixtureModel from "../Models/Fixtures/Markets/CornersFixtureModel";
import CorrectScoreFixtureModel from "../Models/Fixtures/Markets/CorrectScoreFixtureModel";
import DoubleChanceFixtureModel from "../Models/Fixtures/Markets/DoubleChanceFixtureModel";
import OneXTwoFixtureModel from "../Models/Fixtures/Markets/OneXTwoFixtureModel";
import HalfTimeFullTimeFixtureModel from "../Models/Fixtures/Markets/HalfTimeFullTimeFixtureModel";
import OverCornersFixtureModel from "../Models/Fixtures/Markets/OverCornersFixtureModel";
import OverGoalsFixtureModel from "../Models/Fixtures/Markets/OverGoalsFixtureModel";
import PenaltyMatchFixtureModel from "../Models/Fixtures/Markets/PenaltyMatchFixtureModel";
import PlayerFixtureModel from "../Models/Fixtures/Markets/PlayerFixtureModel";
import RedCardFixtureModel from "../Models/Fixtures/Markets/RedCardFixtureModel";
import PredictionRequestModel from "../Models/Predictions/PredictionRequestModel";
import { validFixtureFields } from "../../../Global/Constants/Constants";
import { TypeGames } from "../../TopX/types/types";
import TopXPredictionRequestModel from "../../TopX/Models/Prediction/TopXPredictionRequestModel";
import TiebreakerModel from "../../TopX/Models/Games/TiebreakerModel";
import { ErrorHandlingModeType } from "../../../Configurator/Types/ConfiguratorTypes";
import StandardFansUnitedException from "../../../Exception/StandardFansUnitedException";

export default class PredictorValidator {
    private errorHandlingMode: ErrorHandlingModeType = null;

    constructor(errorHandlingMode: ErrorHandlingModeType){
        this.errorHandlingMode = errorHandlingMode;
    }

    /**
     * Validate all field for game prediction (Top X and Match Quiz). The following cases are covered:
     * 1. Unsupported field is provided (Top X and Match Quiz)
     * 2. Validates tiebreak if is provided (Top X)
     * 3. Validates missing required fields for game request body
     * 4. Validates fixtures
     * @param gameType MATCH_QUIZ, TOP_X and SINGLE. In our case we will use only MATCH_QUIZ and TOP_X.
     * @param param Game request body.
     * @returns Game request body if is valid. If not throws specific message.
     */

    public validateBodyFields = (gameType: TypeGames, param: PredictionRequestModel | TopXPredictionRequestModel) => {
        const mainKeys = Object.keys(param);
        const mainValidFields = Object.keys(new PredictionRequestModel());

        switch (gameType) {
            case "MATCH_QUIZ":
                mainKeys.forEach((key: string) => {
                    if (!mainValidFields.includes(key)) this.throwInvalidParamException([key])
                })
                break;
            case "TOP_X":
                const topXBody = param as TopXPredictionRequestModel;
                topXBody.fixtures.forEach((fixture: any) => this.validateMarketTopX(fixture.market))
                const tiebreakerField = 'tiebreaker';
                if (mainKeys.includes(tiebreakerField) && topXBody.tiebreaker) {
                    this.validateTiebreaker(topXBody.tiebreaker)
                }
                mainKeys.forEach((key: string) => {
                    if (key !== "tiebreaker" && !mainValidFields.includes(key)) this.throwInvalidParamException([key])
                })
                break;
        }

        const missingFields = mainValidFields.filter((field: string) => !mainKeys.includes(field));
        missingFields.length && this.throwMissingFieldsException("game prediction body", missingFields);

        param.fixtures.forEach((fixture: any) => {
            this.validateFixtureFields(fixture);
        })

        return param;
    };

    /**
     * Constructs object types for specific markets. This is done for easier reconstructing when sending the request
     * body to API. Validates the following cases:
     * 1. Market is valid.
     * 2. Prediction is a valid one for the specific market
     * 3. Regex test for CORRECT_SCORE market prediction (String(int:int))
     * 4. Validates playerId except for nobody market (PLAYER_SCORE_FIRST_GOAL)
     * @param matchId Match ID to predict.
     * @param market Market to predict
     * @param prediction The value of prediction.
     * @param playerId Optional. Used only for player markets.
     * @returns Specific type of fixture.
     */

    public validateFixture = (matchId: string, market: MarketEnum, prediction: any, playerId?: string) => {
        this.validateMarket(market);
        const oneXTwoValues = Object.values(OneXTwoEnum);
        const halfTimeFullTimeValues = Object.values(HalfTimeFullTimeEnum);
        const doubleChanceValues = Object.values(DoubleChanceEnum);

        if (market === MarketEnum.FT_1X2 || market === MarketEnum.HT_1X2) {
            if (oneXTwoValues.includes(prediction)) {
                return new OneXTwoFixtureModel(matchId, market, prediction);
            }
        } else if (market === MarketEnum.HT_FT) {
            if (halfTimeFullTimeValues.includes(prediction)) {
                return new HalfTimeFullTimeFixtureModel(matchId, prediction);
            }
        } else if (market === MarketEnum.DOUBLE_CHANCE) {
            if (doubleChanceValues.includes(prediction)) {
                return new DoubleChanceFixtureModel(matchId, prediction);
            }
        } else if (market === MarketEnum.CORRECT_SCORE || market === MarketEnum.CORRECT_SCORE_ADVANCED
            || market === MarketEnum.CORRECT_SCORE_HT) {
            //Regexp check for format in value (int:int)
            const regexCorrectScore = /\d+:\d+/;
            if (typeof prediction === "string" && regexCorrectScore.test(prediction)) {
                return new CorrectScoreFixtureModel(matchId, market, prediction);
            }
        } else if (market === MarketEnum.CORNERS_MATCH) {
            if (typeof prediction === "number") {
                return new CornersFixtureModel(matchId, prediction);
            }
        } else if (market === MarketEnum.BOTH_TEAMS_SCORE) {
            if (this.isPredictionBoolean(prediction)) {
                return new BothTeamsFixtureModel(matchId, prediction);
            }
        } else if (market === MarketEnum.RED_CARD_MATCH) {
            if (this.isPredictionBoolean(prediction)) {
                return new RedCardFixtureModel(matchId, prediction);
            }
        } else if (market === MarketEnum.PENALTY_MATCH) {
            if (this.isPredictionBoolean(prediction)) {
                return new PenaltyMatchFixtureModel(matchId, prediction);
            }
        } else if (playerMarkets.includes(market)) {
            const ownGoal = "OWN_GOAL";
            this.validatePlayerId(market, playerId);
            if (market === playerMarketNobodyPrediction && this.isPredictionBoolean(prediction)) {
                const newPlayerId = playerId && playerId === ownGoal ? ownGoal : playerId;
                return new PlayerFixtureModel(matchId, market, prediction, newPlayerId);
            }

            if (playerId && this.isPredictionBoolean(prediction)) {
                return new PlayerFixtureModel(matchId, market, prediction, playerId);
            }
        } else if (MarketsOverGoals.includes(market)) {
            if (this.isPredictionBoolean(prediction)) {
                return new OverGoalsFixtureModel(matchId, market, prediction);
            }
        } else if (MarketsOverCorners.includes(market)) {
            if (this.isPredictionBoolean(prediction)) {
                return new OverCornersFixtureModel(matchId, market, prediction);
            }
        }

        this.throwInvalidPredictionException(prediction, market);
    };

    /**
     * Validate fixture fields. Throws exception for the following cases:
     * 1. When invalid field is provided
     * 2. When required field is missing
     * @param fixture Fixture fields to validate.
     */

    private validateFixtureFields = (fixture: any) => {
        const keys = Object.keys(fixture);

        keys.forEach((key: string) => {
            if (!validFixtureFields.includes(key)) this.throwInvalidParamException([key])
        })

        const missingFields = validFixtureFields.filter((validField: string) => {
            if (validField !== "matchType" && !keys.includes(validField)) return validField
        })

        if (missingFields.length) this.throwMissingFieldsException("fixtures", missingFields)

        this.validatePredictionKey(fixture.market, fixture.prediction);
    };

    /**
     * Validates tiebreaker if provided (Top X). Throws exception for the following cases:
     * 1. If tiebreaker is not an object
     * 2. If the keys in tiebreaker object are incorrect
     * 3. If the required key is missing
     * 4. If the required key is invalid type
     * @param tiebreaker Tiebreaker for Top X prediciton.
     */

    private validateTiebreaker = (tiebreaker: TiebreakerModel) => {
        const keys = Object.keys(tiebreaker);
        if (!keys.length || typeof tiebreaker !== 'object') {
            this.throwInvalidType("tiebreaker", "object");
        }

        const correctKey = Object.keys(new TiebreakerModel())[0];
        keys.forEach((key: string) => {
            if (correctKey !== key) this.throwInvalidParamException([key])
        })

        if (!keys.includes(correctKey)) this.throwMissingFieldsException("tiebreaker", [correctKey])

        if (typeof tiebreaker.goldenGoal !== "number") this.throwInvalidType("goldenGoal", "number")
    };

    /**
     * Validates prediction in every fixture. Throws for the following cases:
     * 1. When prediction is rather than object type
     * 2. When field 'value' is missing
     * 3. When field rather than 'value' is provided
     * 4. When invalid fields are provided
     * @param market Market of fixture
     * @param prediction Prediction of fixture
     */

    private validatePredictionKey = (market: MarketEnum, prediction: any) => {
        if (typeof prediction === 'object') {
            const predictionKeys = Object.keys(prediction);
            let validKeys = ["value"];
            if (!predictionKeys.length) this.throwMissingFieldsException("prediction", validKeys)

            if (predictionKeys.length === 1 && !predictionKeys.includes(validKeys[0])) this.throwInvalidParamException(predictionKeys)

            if (predictionKeys.length > 1) {
                if (playerMarkets.includes(market)) {
                    validKeys.push("playerId");
                }
                const invalidKeys = predictionKeys.filter((key: string) => !validKeys.includes(key));
                invalidKeys.length && this.throwInvalidParamException(invalidKeys);
            }
        } else {
            this.throwInvalidType("prediction", "object");
        }
    };

    /**
     * Validates player ID for all related player markets EXCEPT PLAYER_SCORE_FIRST_GOAL. The following cases are covered:
     * 1. playerId is falsy value
     * 2. playerId is invalid type
     * @param market Player market.
     * @param playerId Player ID.
     */

    private validatePlayerId = (market: MarketEnum, playerId: string) => {
        const field = "playerId";
        if (market !== playerMarketNobodyPrediction) {
            if (!playerId) this.throwInvalidParamException([field])
            if (typeof playerId !== "string") this.throwInvalidType(field, "string")
        }
    };

    /**
     * Validates the market of every fixture. The market should contain in the enumerated list.
     * @param market Market of fixture.
     */

    private validateMarket = (market: MarketEnum) => {
        const allMarkets = Object.values(MarketEnum) as string[];
        if (!allMarkets.includes(market)) this.throwInvalidMarketException(market)
    };

    /**
     * Validates the market for Top X Game.
     */
    private validateMarketTopX = (market: MarketEnum) => {
        if (market !== MarketEnum.CORRECT_SCORE && market !== MarketEnum.CORRECT_SCORE_ADVANCED) this.throwInvalidMarketException(market, "TOP_X")
    }

    /**
     * Used for type check for prediction field in fixture.
     * @param prediction Fixture's prediction
     * @returns True or false.
     */

    private isPredictionBoolean = (prediction: boolean): boolean => typeof prediction === "boolean";

    /**
     * Throws exception for invalid prediction for specific market.
     */

    private throwInvalidPredictionException = (prediction: string, market: MarketEnum) => {
        const message = invalidPredictionMessage(prediction, market);

        if (this.errorHandlingMode === "default") {
            throw new FansUnitedSdkException(ErrorCodes.BAD_METHOD_CALL, ErrorStatuses.INVALID_PREDICTION, message);
        }

        throw new StandardFansUnitedException(ErrorCodes.BAD_METHOD_CALL, ErrorStatuses.INVALID_TYPE, message).response;
    };

    /**
     * Throws exception when provided market is not supported from Fans United.
     */

    private throwInvalidMarketException = (market: string, type?: TypeGames) => {
        let message: string = null;
        if (type && type === 'TOP_X') message = invalidMarketTopXMessage(market)
        else message = invalidMarketMessage(market)

        if (this.errorHandlingMode === "default") {
            throw new FansUnitedSdkException(ErrorCodes.BAD_METHOD_CALL, ErrorStatuses.INVALID_MARKET, message);
        }

        throw new StandardFansUnitedException(ErrorCodes.BAD_METHOD_CALL, ErrorStatuses.INVALID_TYPE, message).response;
    };

    /**
     * Throws exception for invalid fields.
     */

    private throwInvalidParamException = (key: string[]) => {
        const message = invalidFieldMessage(key);

        if (this.errorHandlingMode === "default") {
            throw new FansUnitedSdkException(ErrorCodes.BAD_METHOD_CALL, ErrorStatuses.INVALID_FIELD, message);
        }

        throw new StandardFansUnitedException(ErrorCodes.BAD_METHOD_CALL, ErrorStatuses.INVALID_TYPE, message).response;
    };

    /**
     * Throws exception for missing fields.
     */

    private throwMissingFieldsException = (field: string, missingFields: string[]) => {
        const message = missingFieldsMessage(field, missingFields);

        if (this.errorHandlingMode === "default") {
            throw new FansUnitedSdkException(ErrorCodes.BAD_METHOD_CALL, ErrorStatuses.INVALID_FIELD, message);
        }

        throw new StandardFansUnitedException(ErrorCodes.BAD_METHOD_CALL, ErrorStatuses.INVALID_TYPE, message).response;

    };

    /**
     * Throws exception for field invalid type.
     */

    private throwInvalidType = (field: string, correctType: string) => {
        const message = invalidTypeMessage(field, correctType);

        if (this.errorHandlingMode === "default") {
            throw new FansUnitedSdkException(ErrorCodes.BAD_METHOD_CALL, ErrorStatuses.INVALID_TYPE, message);
        }

        throw new StandardFansUnitedException(ErrorCodes.BAD_METHOD_CALL, ErrorStatuses.INVALID_TYPE, message).response;
    };
}