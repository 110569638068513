import SDKConfigurationModel from "../../Configurator/Models/SDKConfiguraitonModel";
import IdMappingService from "../../IdMapping/IdMappingService";
import PredictionResponseModel from "../Predictor/Models/Predictions/PredictionResponseModel";
import TopXFacade from "./Facade/TopXFacade";
import GameModel from "./Models/Games/GameModel";
import GamesFilters from "./Models/Games/GamesFilters";
import PaginationModel from "../../Global/Models/Pagination/PaginationModel";
import TopXPredictionRequestModel from "./Models/Prediction/TopXPredictionRequestModel";
import PredictionsFilters from "../Predictor/Models/Predictions/PredictionsFilters";
import GameMarketsResults from "../MatchQuiz/Models/GameMarketsResults/GameMarketsResults";
import MainFiltersBQ from "../../Global/Models/Filters/MainFiltersBQ";
import ContestWinners from "./Models/Games/Winners/ContestWinners";
import GameByIdModel from "./Models/Games/GameByIdModel";

export default class TopX {
    private topXFacade: TopXFacade = null;
    private idMapping: IdMappingService = null;

    constructor(config: SDKConfigurationModel) {
        this.idMapping = new IdMappingService(config);
        this.topXFacade = new TopXFacade(config, this.idMapping);
    }

    public getConfig = async () => {
        return await this.topXFacade.getConfig();
    };

    public getGames = async (filters?: GamesFilters, disableCache?: boolean): Promise<PaginationModel> => {
        return await this.topXFacade.getGames(filters, disableCache);
    };

    public getGameById = async (gameId: string, disableCache?:boolean): Promise<GameByIdModel> => {
        return await this.topXFacade.getGameById(gameId, disableCache);
    };

    public getGamePredictions = async (gameId: string, filters?: PredictionsFilters, disableCache?: boolean): Promise<PaginationModel> => {
        return await this.topXFacade.getGamePredictions(gameId, filters, disableCache);
    };

    public getGameResults = async (gameId: string, filters?: MainFiltersBQ, disableCache?: boolean): Promise<PaginationModel> => {
        return await this.topXFacade.getGameResults(gameId, filters, disableCache);
    };

    public getCurrentGameResults = async (disableCache?: boolean): Promise<GameModel> => {
        return await this.topXFacade.getCurrentGameResults(disableCache);
    };

    public play = async (topXPrediction: TopXPredictionRequestModel): Promise<PredictionResponseModel> => {
        return await this.topXFacade.play(topXPrediction);
    };

    public delete = async (predictionGameId: string): Promise<boolean> => {
        return await this.topXFacade.delete(predictionGameId);
    };

    public getMyGameEditions = async (filters?: MainFiltersBQ, disableCache?: boolean): Promise<PaginationModel> => {
        return await this.topXFacade.getMyGameEditions(filters, disableCache);
    };

    public getMyGamePrediction = async (gameId: string): Promise<PredictionResponseModel> => {
        return await this.topXFacade.getMyGamePrediction(gameId);
    };

    public getUserGameEditions = async (userId: string, filters?: MainFiltersBQ, disableCache?: boolean): Promise<PaginationModel> => {
        return await this.topXFacade.getUserGameEditions(userId, filters, disableCache);
    };

    public getUserGamePrediction = async (userId: string, gameId: string, disableCache?: boolean): Promise<PredictionResponseModel> => {
        return await this.topXFacade.getUserGamePrediction(userId, gameId, disableCache);
    };

    public getMarketsResultsForGame = async (gameId: string, disableCache?: boolean): Promise<GameMarketsResults> => {
        return await this.topXFacade.getMarketsResultsForGame(gameId, disableCache);
    };

    public getGameWinners = async (gameId: string): Promise<ContestWinners> => {
        return await this.topXFacade.getGameWinners(gameId);
    };
}