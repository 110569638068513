import { IdSchemaEnum } from "../../Configurator/Enums/IdSchemaEnum";
import SDKConfigurationModel from "../../Configurator/Models/SDKConfiguraitonModel";
import { EntityTypeEnum } from "../../Global/Enums/EntityTypeEnum";
import { SportSourceEnum } from "../../Global/Enums/SportSourceEnum";
import { sortArrayInAscOrder } from "../../Global/Helper";
import { IdEntities } from "../../Global/Types/GlobalTypes";
import IdMappingService from "../../IdMapping/IdMappingService";
import FootballFacade from "../../Namespaces/Football/Facades/FootballFacade";
import CompetitionBasicModel from "../../Namespaces/Football/Models/Competition/CompetitionBasicModel";
import TeamBasicModel from "../../Namespaces/Football/Models/Team/TeamBasicModel";
import FullInterestModel from "../../Namespaces/Profile/Models/FullInterestModel";
import InterestModel from "../../Namespaces/Profile/Models/InterestModel";
import ProfileStatsModel from "../../Namespaces/Profile/Models/Stats/ProfileStatsModel";
import SuccessRatePercent from "../../Namespaces/Profile/Models/Stats/SuccessRatePercent";

type SuccessRateEntityType = "competition" | "team";

export default class ProfileOperations {
    private idMapping: IdMappingService = null;
    private idSchema: string = null;
    private readonly native = IdSchemaEnum.NATIVE;
    private footballFacade: FootballFacade = null;

    constructor(config: SDKConfigurationModel, footballFacade: FootballFacade, idMapping: IdMappingService) {
        this.idMapping = idMapping;
        this.idSchema = config.idSchema;
        this.footballFacade = footballFacade;
    }

    /**
     * Method is used to remaped competition and team ids from entities in successRates field.
     * @param profileStats Profile stats from Loyalty API.
     * @returns A new successRates with remaped ids keys.
     */

     public remapSuccessRatesEntities = async (profileStats: ProfileStatsModel): Promise<ProfileStatsModel> => {
        const { successRates } = profileStats;
        const idsObj: IdEntities = {
            competition: this.getIdsKeys(successRates.byFootballCompetition),
            team: this.getIdsKeys(successRates.byFootballTeam)
        };

        if (Object.values(idsObj.competition).length) {
            const competitionRecord = profileStats.successRates.byFootballCompetition;
            profileStats.successRates.byFootballCompetition = await this.setModels(competitionRecord, idsObj.competition, EntityTypeEnum.COMPETITION);
        }

        if (Object.values(idsObj.team).length) {
            const teamRecord = profileStats.successRates.byFootballTeam;
            profileStats.successRates.byFootballTeam = await this.setModels(teamRecord, idsObj.team, EntityTypeEnum.TEAM);
        }

        return profileStats;
    };

    private setModels = async (oldRecord: Record<string, SuccessRatePercent>, nativeIds: string[], type: SuccessRateEntityType) => {
        let entityMap: any = null;
        let newRecord: Record<string, SuccessRatePercent>;

        switch (type) {
            case 'competition':
                entityMap = await this.footballFacade.getCompetitionsMapWithNativeIds(nativeIds);
                break;
            case 'team':
                entityMap = await this.footballFacade.getTeamsMapWithNativeIds(nativeIds);
                break;
        }

        Object.keys(oldRecord).forEach((key: string) => {
            const entity = entityMap.get(key);
            newRecord = {
                ...newRecord,
                [entity.id]: oldRecord[key]
            }

            newRecord[entity.id].model = entity;
        });

        return newRecord;
    };

    /**
     * Keys for success rates entiteis are ids. This method extracts them in string array.
     * @param record The record containing native ids keys.
     * @returns New list of native ids.
     */

    private getIdsKeys = (record: Record<string, SuccessRatePercent>): string[] => {
        const copyObj = JSON.parse(JSON.stringify(record));

        return Object.keys(copyObj);
    };

    /**
     * Initializing object objectIds with key type and value id from interests.
     * Passing that object to function getEntitiesByIds in IdMappingFacade.
     * For replacing the new ids there is a object interestsByType with key type and
     * value interests. The order in interestsByType is same as objectIds.
     * We aren't remapping any custom or native interests.
     * @param interests
     * @returns Remapped interests from native to idSchema.
     */

     private remapInterestsToIdSchema = async (interests: InterestModel[]): Promise<InterestModel[]> => {
        if (this.idSchema !== this.native) {
            if (interests && interests.length > 0) {
                let objectIds: any = {};
                let interestsByType: any = {};

                //TODO: Think about refactoring when new sports are supported from Fans United
                const footballInterests = interests.filter((interest: InterestModel) => interest.source.toLocaleUpperCase() === SportSourceEnum.FOOTBALL);
                const otherSportInterests = interests.filter((interest: InterestModel) => interest.source.toLocaleUpperCase() !== SportSourceEnum.FOOTBALL);

                //Extracting interests ids -> {type: [ids]}
                footballInterests.forEach((interest: InterestModel) => {
                    if (!objectIds.hasOwnProperty(interest.type)) {
                        objectIds[interest.type] = [];
                        interestsByType[interest.type] = [];
                    }
                    objectIds[interest.type].push(interest.id);
                    interestsByType[interest.type].push(interest);
                });

                // remapping ids
                const remappedIds = await this.remapIdsObj(objectIds, this.native, this.idSchema);

                // replacing new ids
                let remappedInterests: InterestModel[] = [];
                for (const [type, interestsType] of Object.entries(interestsByType)) {
                    //@ts-ignore
                    interestsType.forEach((interest: InterestModel, idx: number) => {
                        interest.id = remappedIds[type][idx];
                        remappedInterests.push(interest);
                    });
                }
                return remappedInterests.concat(otherSportInterests);
            } else {
                return interests;
            }
        }
            return interests;
    };

    /**
     * Using id and type from @param interest to initialize object which we are passing
     * to @function getEntitiesByIds in @class IdMappingFacade.
     * We are using this function when we want to add or remove interest from our profile.
     * @param interest
     * @returns Remapped interest from idSchema to native.
     */

    public remapInterestToNative = async (interest: InterestModel): Promise<InterestModel> => {
        if (this.idSchema !== this.native && interest.source.toLocaleUpperCase() === SportSourceEnum.FOOTBALL) {
            let objectId: any = {};
            let { id, type } = interest;

            objectId[type] = [id];
            const remappedIds = await this.remapIdsObj(objectId, this.idSchema, this.native)
            interest.id = remappedIds[type][0];

            return interest;
        }

        return interest;
    };

    /**
     * Extracting custom interests from list of interests in profile.
     * @param interests
     * @returns Custom interests.
     */

     private inquiryCustomInterests = (interests: InterestModel[], isFullModel: boolean): InterestModel[] | FullInterestModel[] => {
        let customInterests: any[] = [];

        if (interests && interests.length > 0) {
            customInterests = interests.filter((profileInterest: InterestModel) => profileInterest.source.toLocaleUpperCase() === SportSourceEnum.CUSTOM);

            if (isFullModel) {
                return customInterests.map((interest: FullInterestModel) => {
                    const newFullInterest = new FullInterestModel();
                    newFullInterest.id = interest.id;
                    newFullInterest.type = interest.type;
                    newFullInterest.source = interest.source;
                    newFullInterest.favourite = interest.favourite;

                    return newFullInterest;
                })
            }
        }

        return customInterests;
    };


    /**
     * We are using this function when we want to show interests from profile.
     * @param interests
     * @returns Utilizing @function remapInterestsToIdSchema for remapping to idSchema.
     */

    public remapInterests = async (interests: InterestModel[]): Promise<InterestModel[]> => {
        const isFullModel = false;
        const customInterests: InterestModel[] = this.inquiryCustomInterests(interests, isFullModel);

        if (customInterests && customInterests.length > 0) {
            const interestsToRemap = interests.filter((profileInterest: InterestModel) => profileInterest.source.toLocaleUpperCase() !== SportSourceEnum.CUSTOM);
            const remappedInterests = await this.remapInterestsToIdSchema(interestsToRemap);
            customInterests.forEach((customInterest: InterestModel) => remappedInterests.push(customInterest));

            return remappedInterests;
        }

        return await this.remapInterestsToIdSchema(interests);
    };

    /**
     * In case we have custom interest we don't remap the id. When the id for the entity is not remaped successfully or
     * is not found in Football API, the model is set to null.
     * @param interests
     * @returns Details for each interest in profile
     */

     public showFullInterests = async (interests: InterestModel[]) => {
        let fullInterests: any[] = [];

        if (interests && interests.length > 0) {
            const isFullModel = true;
            const customInterests: InterestModel[] = this.inquiryCustomInterests(interests, isFullModel);
            fullInterests.push(customInterests);
            const fansUnitedInterests = interests.filter((interest: InterestModel) => interest.source.toLocaleUpperCase() !== SportSourceEnum.CUSTOM);
            const { competitionsMap, teamsMap, playersMap } = await this.getInterestsEntitiesMap(fansUnitedInterests);

            const newInterests = fansUnitedInterests.map((interest: any) => {
                let model: any = null;

                if (interest.source.toLocaleUpperCase() === SportSourceEnum.FOOTBALL) {
                    if (interest.type === EntityTypeEnum.COMPETITION) {
                        model = competitionsMap.get(interest.id);
                        if (model) interest.id = model.id;
                        interest.model = model;

                        return interest;
                    }

                    if (interest.type === EntityTypeEnum.TEAM) {
                        model = teamsMap.get(interest.id);
                        if (model) interest.id = model.id;
                        interest.model = model;

                        return interest;
                    }

                    if (interest.type === EntityTypeEnum.PLAYER) {
                        if (playersMap) {
                            interest.model = playersMap[interest.id];
                            interest.id = playersMap[interest.id].id;
                        } else {
                            interest.model = model;
                        }

                        return interest;
                    }
                } else {
                    // All other sports who are not supported yet from FansUnited will be returned with model: null
                    interest.model = model;

                    return interest;
                }
            });

            fullInterests.push(newInterests);

            return fullInterests.flat();
        }

        return fullInterests;
    };

    /**
     * Using FootballFacade to get information for teams, players or competitions with remapped ids.
     * Fetch entities models from Football Facade and return them as type: { entityId: model }.
     * @param interests Profile interests containing entitie native ids.
     * @returns A Map value of key entity native ID and value entity model.
     */

    private getInterestsEntitiesMap = async (interests: InterestModel[]) => {
        let competitionIds: string[] = [];
        let teamIds: string[] = [];
        let playerIds: string[] = [];
        let competitionsMap = new Map<string, CompetitionBasicModel>();
        let teamsMap = new Map<string, TeamBasicModel>();
        let playersMap: any = null;

        interests.forEach((interest: InterestModel) => {
            if (interest.type === EntityTypeEnum.COMPETITION && interest.source.toLocaleUpperCase() === SportSourceEnum.FOOTBALL) competitionIds.push(interest.id)
            else if (interest.type === EntityTypeEnum.TEAM && interest.source.toLocaleUpperCase() === SportSourceEnum.FOOTBALL) teamIds.push(interest.id)
            else if (interest.type === EntityTypeEnum.PLAYER && interest.source.toLocaleUpperCase() === SportSourceEnum.FOOTBALL) playerIds.push(interest.id)
        })

        if (competitionIds.length) {
            competitionsMap = await this.footballFacade.getCompetitionsMapWithNativeIds(competitionIds);
        }

        if (teamIds.length) {
            teamsMap = await this.footballFacade.getTeamsMapWithNativeIds(teamIds);
        }

        if (playerIds.length) {
            playersMap = await this.footballFacade.getPlayersMapWithNativeIds(playerIds);
        }

        return { competitionsMap, teamsMap, playersMap };
    };

    /**
     * Calls id mapping facade to fetch id entities from storage or Id Mapping API.
     * @param idsObj Object which key is entity and value is string array of ids.
     * @returns Remapped ids by entities.
     */

     private remapIdsObj = async (idsObj: IdEntities, fromIdSchema: string, toIdSchema: string) => {
        return await this.idMapping.idMappingFacade.getEntitiesByIds(idsObj, fromIdSchema, toIdSchema);
    };
}