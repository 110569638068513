import { TypeGames } from "../../../TopX/types/types";
import { StatusEnum } from "../../Enums/StatusEnum";

export default class PredictionsFilters {
    public limit: number = null;
    public startAfter: string = null;
    public status: StatusEnum[] = null;
    public type: TypeGames = null;
    public matchIds: string[] = null

    constructor(filters: any) {
        this.limit = filters.limit ? filters.limit : null;
        this.startAfter = filters.startAfter ? filters.startAfter : null;
        this.status = filters.status ? filters.status : null;
        this.type = filters.type ? filters.type : null;
        this.matchIds = filters.matchIds ? filters.matchIds : null;
    }

    public constructFiltersUrl = () => {
        let url = "";
        let correctStatus: boolean = false;

        if (this.status) {
            correctStatus = Object.values(StatusEnum).some(status => {
                return this.status.includes(status)
            });
        }

        if (this.limit) {
            url += '&';
            url += `limit=${this.limit}`;
        }
        if (this.startAfter) {
            url += '&';
            url += `start_after=${this.startAfter}`;
        }
        if (this.status && correctStatus) {
            url += '&';
            url += `status=${this.status.join(",")}`;
        }
        if (this.type) {
            url += '&';
            url += `game_types=${this.type}`;
        }
        if (this.matchIds) {
            url += '&';
            url += `match_ids=${this.matchIds.join(",")}`;
        }

        return url;
     };
}