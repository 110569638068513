import SDKConfigurationModel from "../Configurator/Models/SDKConfiguraitonModel";
import IdMappingService from "../IdMapping/IdMappingService";
import FootballFacade from "../Namespaces/Football/Facades/FootballFacade";
import InterestModel from "../Namespaces/Profile/Models/InterestModel";
import ProfileStatsModel from "../Namespaces/Profile/Models/Stats/ProfileStatsModel";
import ProfileOperations from "./Profile/ProfileOperations";

/*
    TODO: Global Remapper. Init this class in every namespace (where id remapping is available).
    That way we avoid declaring everywhere IdMappingService. Think about dependency injection.
*/

export default class SDKIdsRemapper {
    private idMapping: IdMappingService = null;
    private profileOperations: ProfileOperations = null

    constructor(config: SDKConfigurationModel, footballFacade: FootballFacade) {
        this.idMapping = new IdMappingService(config);
        this.profileOperations = new ProfileOperations(config, footballFacade, this.idMapping);
    }

    // Profile Remapping Operations

    public remapSuccessRatesEntities = async (profileStats: ProfileStatsModel): Promise<ProfileStatsModel> => {
        return await this.profileOperations.remapSuccessRatesEntities(profileStats);
    };

    public remapInterestToNative = async (interest: InterestModel): Promise<InterestModel> => {
        return await this.profileOperations.remapInterestToNative(interest);
    };

    public remapInterests = async (interests: InterestModel[]): Promise<InterestModel[]> => {
        return await this.profileOperations.remapInterests(interests);
    };

    public showFullInterests = async (interests: InterestModel[]): Promise<InterestModel[]> => {
        return await this.profileOperations.showFullInterests(interests);
    };
}