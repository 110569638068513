import { MarketEnum, playerMarkets } from "../Enums/MarketEnum";
import IdMappingService from "../../../IdMapping/IdMappingService";
import PredictionsModel from "../Models/Predictions/PredictionsModel";
import PredictorConfigModel from "../Models/Config/PredictorConfigModel";
import NoYesModel from "../Models/Predictions/NoYesModel";
import GamesTopXListModel from "../../TopX/Models/Games/GamesTopXListModel";
import FixturesTopXModel from "../../TopX/Models/Fixtures/FixturesTopXModel";
import GameModel from "../../TopX/Models/Games/GameModel";
import GameResultsModel from "../../TopX/Models/Games/GameResultsModel";
import SDKConfigurationModel from "../../../Configurator/Models/SDKConfiguraitonModel";
import FootballHttps from "../../../Https/FootballHttps";
import MatchFacade from "../../Football/Facades/MatchFacade";
import PredictionsFilters from "../Models/Predictions/PredictionsFilters";
import { TypeGames } from "../../TopX/types/types";
import GamesFilters from "../../TopX/Models/Games/GamesFilters";
import { transformIdsToBeUnique } from "../../../Global/Helper";
import ResultModel from "../Models/Fixtures/ResultModel";
import GamesListModel from "../../TopX/Models/Games/GamesListModel";
import FixturesGamesModel from "../../TopX/Models/Fixtures/FixturesGamesModel";
import GamesMatchQuizListModel from "../../MatchQuiz/Models/Games/GamesMatchQuizListModel";
import FixturesMatchQuizModel from "../../MatchQuiz/Models/Fixtures/FixturesMatchQuizModel";
import PlayerFacade from "../../Football/Facades/PlayerFacade";
import PaginationModel from "../../../Global/Models/Pagination/PaginationModel";
import TopXConfigModel from "../../TopX/Models/Config/TopXConfigModel";
import MatchQuizConfigModel from "../../MatchQuiz/Models/Config/MatchQuizConfigModel";
import { FeaturesConfigModels, FilterCase } from "../../../Global/Types/GlobalTypes";
import { pastStatuses, StatusEnum } from "../Enums/StatusEnum";
import { WarningMessages } from "../../../Global/Messages/Messages";
import GameMarketsResults, { GoldenGoalsType, MarketsResults } from "../../MatchQuiz/Models/GameMarketsResults/GameMarketsResults";
import { IdSchemaEnum } from "../../../Configurator/Enums/IdSchemaEnum";
import MatchSummaryModel from "../Models/Summary/MatchSummaryModel";
import MainFiltersBQ from "../../../Global/Models/Filters/MainFiltersBQ";
import ContestWinners from "../../TopX/Models/Games/Winners/ContestWinners";
import Profile from "../../Profile/Profile";
import UserListWinners from "../../TopX/Models/Games/Winners/UserListWinners";
import ProfileModel from "../../Profile/Models/ProfileModel";
import { ID_TYPES, marketToKeySummaryMap } from "../../../Global/Constants/Constants";
import GameByIdModel from "../../TopX/Models/Games/GameByIdModel";
export default class PredictorService {
    private footballHttps: FootballHttps = null;
    private idMapping: IdMappingService = null;
    private matchFacade: MatchFacade = null;
    private playerFacade: PlayerFacade = null;
    private profileNamespace: Profile = null;

    constructor(config: SDKConfigurationModel, idMapping: IdMappingService) {
        this.footballHttps = new FootballHttps(config);
        this.idMapping = idMapping;
        this.matchFacade = new MatchFacade(config, this.footballHttps, idMapping);
        this.playerFacade = new PlayerFacade(config, this.footballHttps, idMapping);
        this.profileNamespace = new Profile(config);
    }

    public remapMatchIdToNative = async (matchIds: string[]): Promise<string[]> => {
        const objMatchId = await this.idMapping.getEntityIdsBySchemaId(matchIds, "match", "native");

        return objMatchId.match;
    };

    public remapPlayerIdToNative = async (playerIds: string[]) => {
        const objPlayerId = await this.idMapping.getEntityIdsBySchemaId(playerIds, "player", "native");
        const nativePlayerIds = objPlayerId.player[0];

        return nativePlayerIds;
    };

    public remapPlayerIdMapToNative = async (playerIds: string[]) => {
        let playerIdsMap: any = {};
        const uniqueIds = transformIdsToBeUnique(playerIds)

        const nativeObjPlayerIds = await this.idMapping.getEntityIdsBySchemaId(uniqueIds, "player", "native");
        const nativePlayerIds = nativeObjPlayerIds.player;

        uniqueIds.forEach((id: string, idx: number) => {
            return playerIdsMap[id] = nativePlayerIds[idx];
        });

        return playerIdsMap;
    };

    /**
     * In case there is prediction with player market, it property's name is the player id itself so it will be remaped to idSchema.
     * @param predictions
     * @returns Predictions with remapped ids.
     */

    public remapPlayerIdToIdSchema = async (predictions: PredictionsModel) => {
        const playerMarkets = ["playerScore", "playerYellowCard", "playerRedCard", "playerScoreFirstGoal", "playerScoreHattrick", "playerScoreTwice"];
        const playerIds: string[] = [];

        for (const [key, value] of Object.entries(predictions)) {
            if (playerMarkets.includes(key)) {
                for (const key of Object.keys(value)) {
                    if (key !== "NOBODY" && key !== "OWN_GOAL") {
                        playerIds.push(key);
                    }
                }
            }
        }

        if (!playerIds.length) return predictions;

        const playersMap = await this.playerFacade.getPlayersMapWithNativeIds(playerIds);

        for (const [summaryKey, summaryValue] of Object.entries(predictions)) {
            if (playerMarkets.includes(summaryKey)) {
                for (const playerIdKey of Object.keys(summaryValue)) {
                    if (playerIdKey !== "NOBODY" && playerIdKey !== "OWN_GOAL") {
                        // Construct new object with the remapped player id as key, the yes/no data from API as value and additional player model
                        summaryValue[playersMap[playerIdKey].id] = summaryValue[playerIdKey];
                        summaryValue[playersMap[playerIdKey].id].model = playersMap[playerIdKey];

                        // Prevent deleting the summary value when idSchema is native
                        if (this.idMapping.idSchema !== IdSchemaEnum.NATIVE) delete summaryValue[playerIdKey];
                    }
                }
            }
        }

        return predictions;
    };

    /**
     * Convert market from API request response to market in MarketEnums
     * @param market
     * @returns Market that fits response model
     */

    public convertMarketForResponseModel = (market: any, target: number): string => {
        const targetString = target.toString();
        const newMarket = market + "_" + targetString;
        return newMarket
    };

    public getMarketSummary = async (matchSummary: MatchSummaryModel, market: MarketEnum, playerId?: string) => {
        const convertedMarket = marketToKeySummaryMap.get(market);
        let marketSummary: any = {};
        marketSummary.matchId = matchSummary.matchId;
        marketSummary.matchType = matchSummary.matchType;

        const markets = Object.keys(matchSummary.predictions);

        if (markets.includes(convertedMarket)) {
            //@ts-ignore
            marketSummary[convertedMarket] = matchSummary.predictions[convertedMarket];
        } else {
            throw new Error("You have passed invalid market");
        }

        if (playerMarkets.includes(market) && playerId) {
            let nativePlayerId: string = "";

            if (playerId !== "OWN_GOAL" && playerId !== "NOBODY") {
                // Remap the provided player id from client to native schema
                const objRemappedId = await this.idMapping.idMappingFacade.getEntitiesByIds({player: [playerId]}, this.idMapping.idSchema, "native");
                nativePlayerId = objRemappedId.player[0];
            }

            if (nativePlayerId && marketSummary[convertedMarket][nativePlayerId]) {
                // Fetch the player model and set the model for it
                const playerMap = await this.playerFacade.getPlayersMapWithNativeIds([nativePlayerId]);
                marketSummary[convertedMarket][nativePlayerId].model = playerMap[nativePlayerId];

                return marketSummary[convertedMarket][nativePlayerId];
            } else if (!nativePlayerId) {
                // In this case playerId is NOBODY or OWN_GOAL
                return marketSummary[convertedMarket][playerId];
            } else {
                // When client provided playerId but he doesn't exis in the market summary
                marketSummary[convertedMarket][nativePlayerId] = new NoYesModel();

                return marketSummary[convertedMarket][nativePlayerId];
            }
        } else if (playerMarkets.includes(market) && !playerId) {
            // Client provides only player market with no player id.
            const playerIds = Object.keys(marketSummary[convertedMarket]).filter((key: string) => key !== "NOBODY" && key !== "OWN_GOAL")
            const playersMap = playerIds.length ? await this.playerFacade.getPlayersMapWithNativeIds(playerIds) : {};

            if (Object.keys(playersMap).length) {
                Object.keys(marketSummary[convertedMarket]).forEach((playerId: string) => {
                    if (playerId !== "NOBODY" && playerId !== "OWN_GOAL") {
                        // Construct new object with key remapped id and value yes/no object from original object plus model.
                        marketSummary[convertedMarket][playersMap[playerId].id] = marketSummary[convertedMarket][playerId];
                        marketSummary[convertedMarket][playersMap[playerId].id].model = playersMap[playerId]

                        // Prevent deleting the summary value when idSchema is native
                        if (this.idMapping.idSchema !== IdSchemaEnum.NATIVE) delete marketSummary[convertedMarket][playerId];
                    }
                })
            }
        }

        return marketSummary;
    };

    public remapCompetitionsFromConfig = async (config: FeaturesConfigModels): Promise<FeaturesConfigModels> => {
        if (config instanceof PredictorConfigModel) {
            const objFullCoverageCompetitionsIds = { competition: config.fullCoverageCompetitions };
            const objSuccessRateScopesCompetitionsIds = { competition: config.successRateScopes.competitions };
            const objSuccessRateScopesTeamsIds = { team: config.successRateScopes.teams };

            const remappedfullCoverageIds = await this.idMapping.idMappingFacade.getEntitiesByIds(objFullCoverageCompetitionsIds, "native", this.idMapping.idSchema);
            const remappedSuccessRateScopesCompetitionsIds = await this.idMapping.idMappingFacade.getEntitiesByIds(objSuccessRateScopesCompetitionsIds, "native", this.idMapping.idSchema);
            const remappedSuccessRateScopesTeamsIds = await this.idMapping.idMappingFacade.getEntitiesByIds(objSuccessRateScopesTeamsIds, "native", this.idMapping.idSchema);

            config.fullCoverageCompetitions = remappedfullCoverageIds.competition;
            config.successRateScopes.competitions = remappedSuccessRateScopesCompetitionsIds.competition;
            config.successRateScopes.teams = remappedSuccessRateScopesTeamsIds.team;

            return config;
        }

        if (config instanceof TopXConfigModel || config instanceof MatchQuizConfigModel) {
            const objCompetitionIds = { competition: config.competitionsWhitelist };

            const remappedIds = await this.idMapping.idMappingFacade.getEntitiesByIds(objCompetitionIds, "native", this.idMapping.idSchema);
            config.competitionsWhitelist = remappedIds.competition;

            return config;
        }
    };

     /**
     * Verifying parameter games for array or object type.
     * @param games
     * @returns Games/game with remapped matchIds in fixtures/results property.
     */

    public remapMatchIdsFixtures = async (games: any) => {
        if (Array.isArray(games.data)) {
            if (games.data.length > 0) {
                let matchIds: string[] = [];
                let playerIds: string[] = [];

                games.data.forEach((game: any) => {
                    if (game instanceof GamesListModel || game instanceof GamesTopXListModel
                            || game instanceof GamesMatchQuizListModel || game instanceof GameByIdModel) {
                        game.fixtures.forEach((fixture: any) => matchIds.push(fixture.matchId));

                        if (game instanceof GamesMatchQuizListModel) {
                            game.fixtures.forEach((fixture: FixturesMatchQuizModel) => {
                                if (fixture.prediction.playerId && fixture.prediction.playerId !== "OWN_GOAL") {
                                    playerIds.push(fixture.prediction.playerId);
                                }
                            });
                        }

                    } else if (game instanceof GameModel) {
                        game.results.forEach((result: GameResultsModel) => matchIds.push(result.matchId));
                    }
                });

                return await this.refactorResponseArrModel(games, matchIds, playerIds);
            } else {
                return games;
            }
        } else {
            return await this.refactorResponseObjModel(games);
        }
    };

    /**
     * Making one request to GET /matches with all match ids for all fixtures or results.
     * @param games
     * @returns Games with remapped matchIds in fixtures or results property.
     */

    private refactorResponseArrModel = async (games: any, matchIds: string[], playerIds: string[]) => {
        let newGames: GamesListModel[] | GamesTopXListModel | GameModel[] = [];
        const matchesMap = await this.matchFacade.getMatchesMapWithNativeIds(matchIds);
        let playersMap: any = {};

        if (playerIds.length > 0) {
            playersMap = await this.playerFacade.getPlayersMapWithNativeIds(playerIds);
        }

        games.data.forEach((game: GamesListModel | GamesTopXListModel | GameModel) => {

            if (game instanceof GamesListModel || game instanceof GamesTopXListModel || game instanceof GamesMatchQuizListModel) {
                game.fixtures.forEach((fixture: any) => {
                    this.handleMatchModel(matchesMap, fixture);

                    if (game instanceof GamesMatchQuizListModel && fixture.prediction.playerId && fixture.prediction.playerId !== "OWN_GOAL") {
                        fixture.prediction.playerModel = playersMap[fixture.prediction.playerId];
                        fixture.prediction.playerId = playersMap[fixture.prediction.playerId].id;
                    }
                });

                //@ts-ignore
                newGames.push(game);
            } else if (game instanceof GameModel) {
                game.results.forEach((result: GameResultsModel) => {
                    result.matchModel = matchesMap[result.matchId];
                    delete result.matchModel.availableMarkets;
                    result.matchId = result.matchModel.id;
                });

                //@ts-ignore
                newGames.push(game);
            }
        });

        games.data = newGames;

        return games;
    };

    /**
     * Making one request to GET /matches with all match ids for all fixtures
     * @param games
     * @returns Specific game with remapped matchIds in fixtures property.
     */

    private refactorResponseObjModel = async (games: any) => {
        let matchIds: string[] = [];
        games.fixtures.forEach((fixture: FixturesTopXModel) => matchIds.push(fixture.matchId));
        const matches = await this.matchFacade.getMatchesMapWithNativeIds(matchIds);

        games.fixtures.forEach((fixture: FixturesGamesModel) => this.handleMatchModel(matches, fixture));

        return games;
    };

    /**
     * Function receives list of all open games. When they are more than one it gets sorted by predictionsCutoff property.
     * @param openGames
     * @returns Id of the first game in the list.
     */

    public getCurrentGameId = (openGames: PaginationModel): string => {
        const now = new Date();

        if (openGames.data.length > 1) {
            let predictionsCutoffDates: string[] = [];
            openGames.data.forEach((game: any) => predictionsCutoffDates.push(game.predictionsCutoff));

            const closest = openGames.data.sort((a: any, b: any) => {
                //@ts-ignore
                const [aDate, bDate] = [a.predictionsCutoff, b.predictionsCutoff].map((d: string) => Math.abs(new Date(d) - now));

                return aDate - bDate;
            });

            return closest[0].id;
        } else {
            return openGames.data[0].id;
        }
    };

    public initGameTypePredictions = (gameType: TypeGames, providedFilters?: MainFiltersBQ): PredictionsFilters => {
        let filters: { [key: string]: any } = { type: gameType, limit: null, startAfter: null };

        if (providedFilters) {
            filters = {...filters, limit: providedFilters.limit, startAfter: providedFilters.startAfter}
        }

        filters = new PredictionsFilters(filters);

        return filters as PredictionsFilters;
    };

    public initGameFilters = (gameType: TypeGames, filters?: GamesFilters, gameIds?: string[]): GamesFilters => {
        if (filters && gameIds) {
            filters.type = gameType;
            filters.gameIds = gameIds;
            filters = new GamesFilters(filters);

            return filters;
        } else if (filters && !gameIds) {
            filters.type = gameType;
            filters = new GamesFilters(filters);

            return filters;
        } else if (!filters && gameIds) {
            let newFilters: any = { type: gameType, gameIds: gameIds };
            newFilters = new GamesFilters(newFilters);

            return newFilters;
        } else {
            let newFilters: any = { type: gameType };
            newFilters = new GamesFilters(newFilters);

            return newFilters;
        }
    };

    public addPredictionProp = async (predictions: any, paginatedGames: PaginationModel) => {
        paginatedGames.data.forEach((game: GamesTopXListModel | GamesMatchQuizListModel) => {
            predictions.data.forEach((prediction: any) => {
                if (game.id === prediction.game_instance_id) {
                    game.predictionId = prediction.id;
                    game.outcome = prediction.status;
                    game.fixtures.forEach((fixture: FixturesTopXModel | FixturesMatchQuizModel, idx: number) => {
                        if (prediction.fixtures[idx].goals_home !== undefined && prediction.fixtures[idx].goals_away !== undefined) {
                            fixture.prediction.value = prediction.fixtures[idx].goals_home + ":" + prediction.fixtures[idx].goals_away;
                        } else {
                            fixture.prediction.value = prediction.fixtures[idx].prediction;
                        }

                        if (prediction.fixtures[idx].player_id) {
                            //@ts-ignore
                            fixture.prediction.playerId = prediction.fixtures[idx].player_id;
                        } else if (prediction.fixtures[idx].target) {
                            //@ts-ignore
                            fixture.prediction.target = prediction.fixtures[idx].target;
                        }
                        fixture.prediction.result = this.remapResult(prediction.fixtures[idx].result);
                    });
                    game.predictionsMadeAt = prediction.created_at;
                    game.points = prediction.points;

                    if (game instanceof GamesTopXListModel) {
                        if (prediction.tiebreaker) {
                            game.predictionTiebreaker.goldenGoal = prediction.tiebreaker.golden_goal;
                        } else {
                            game.predictionTiebreaker = prediction.tiebreaker;
                        }
                    }
                }
            })
        });

        paginatedGames.meta.pagination.nextPageStartsAfter = predictions.meta.pagination.next_page_starts_after;

        return await this.remapMatchIdsFixtures(paginatedGames);
    };

    private remapResult = (result: any) => {
        let resultResponse = new ResultModel();

        resultResponse.settledAt = result.settled_at;
        resultResponse.resettledAt = result.resettled_at;
        resultResponse.status = result.status;
        resultResponse.outcome = result.outcome;
        resultResponse.points = result.points;

        return resultResponse;
    };

    /**
     * Checks whether the length of array is more than the declared limit.
     * @param ids String array for which length is limited.
     * @returns True/false result from ternary operator.
     */
    public areIdsExceeded = (ids: string[]): boolean => {
        // Prediction API limits match_ids query param to 10.
        const limitIds = 10;

        return ids.length > limitIds ? true : false;
    };

    public initPredictionsFilters = (filters: PredictionsFilters, filtersFor: FilterCase, matchIds?: string[]) => {
        switch (filtersFor) {
            case 'user':
                if (filters) {
                    filters = new PredictionsFilters(filters);
                }
                break;
            case 'current':
                if (filters) {
                    this.deleteUnexpectedProperties(filters);
                    filters.status = [StatusEnum.ACTIVE];
                    filters = new PredictionsFilters(filters);
                } else {
                    const newFilters = { status: [StatusEnum.ACTIVE] };
                    filters = new PredictionsFilters(newFilters);
                }
                break;
            case 'past':
                if (filters) {
                    this.deleteUnexpectedProperties(filters);

                    filters.status = pastStatuses;
                    filters = new PredictionsFilters(filters);
                } else {
                    const newFilters = { status: pastStatuses };
                    filters = new PredictionsFilters(newFilters);
                }
                break;
            case 'matches':
                if (filters) {
                    this.deleteUnexpectedProperties(filters);
                    filters.matchIds = matchIds;
                    filters = new PredictionsFilters(filters);
                } else {
                    const newFilters = { matchIds };
                    filters = new PredictionsFilters(newFilters);
                }
                break;
        }

        return filters;
    };

    public completeGameMarketResults = async (gameMarketResults: GameMarketsResults, gameType: TypeGames): Promise<GameMarketsResults> => {
        switch (gameType) {
            case 'MATCH_QUIZ':
                let playersMap: any = {};
                const matchId = gameMarketResults.results[0].matchId;
                const playerIds = gameMarketResults.results.map((value: MarketsResults) => {
                    //@ts-ignore
                    if (playerMarkets.includes(value.market) && !value.result.includes("OWN_GOAL")) {
                        return [...value.result as string[]];
                    } else {
                        return [];
                    }
                }).flat();

                const matchModelMap = await this.matchFacade.getMatchesMapWithNativeIds([matchId]);

                if (playerIds.length) {
                    playersMap = await this.playerFacade.getPlayersMapWithNativeIds(playerIds);
                }

                gameMarketResults.results = gameMarketResults.results.map((value: MarketsResults) => {
                    value.matchModel = matchModelMap[value.matchId] ? matchModelMap[value.matchId] : null;

                    if (value.playerModels && Object.keys(playersMap).length) {
                        const playerIdsResult = value.result as string[];
                        playerIdsResult.forEach((playerId: string) => {
                            const playerBasicModel = playersMap[playerId];

                            value.playerModels.push(playerBasicModel);
                        });
                    }

                    if (this.idMapping.idSchema !== IdSchemaEnum.NATIVE) {
                        this.remapMatchIdForResultsMarkets(matchModelMap, value);

                        if (value.playerModels && Object.keys(playersMap).length) {
                            const playerIdsResult = value.result as string[];
                            value.result = playerIdsResult.map((id: string) => {
                                return playersMap[id].id;
                            });
                        }
                    }

                    return value;
                });

                return gameMarketResults;
            case "TOP_X":
                const matchIds = gameMarketResults.results.map((value: MarketsResults) => value.matchId);
                const matchModelsMap = await this.matchFacade.getMatchesMapWithNativeIds(matchIds);

                gameMarketResults.results = gameMarketResults.results.map((value: MarketsResults) => {
                    value.matchModel = matchModelsMap[value.matchId] ? matchModelsMap[value.matchId] : null;

                    if (this.idMapping.idSchema !== IdSchemaEnum.NATIVE) {
                        this.remapMatchIdForResultsMarkets(matchModelsMap, value);
                    }

                    return value;
                });

                if (gameMarketResults.tieBreakers.goldenGoals.length) {
                    const goldenGoalsPlayerIds = gameMarketResults.tieBreakers.goldenGoals.map((value: GoldenGoalsType) => value.playerId);
                    const goldenGoalsPlayersMap = await this.playerFacade.getPlayersMapWithNativeIds(goldenGoalsPlayerIds);

                    gameMarketResults.tieBreakers.goldenGoals = gameMarketResults.tieBreakers.goldenGoals.map((value: GoldenGoalsType) => {
                        value.matchModel = matchModelsMap[value.matchId];
                        value.playerModel = goldenGoalsPlayersMap[value.playerId];

                        if (this.idMapping.idSchema !== IdSchemaEnum.NATIVE) {
                            value.matchId = matchModelsMap[value.matchId].id;
                            value.playerId = goldenGoalsPlayersMap[value.playerId].id;
                        }

                        return value;
                    })

                }

                return gameMarketResults;
        }
    };

    public completeContestWinners = async (contestWinners: ContestWinners): Promise<ContestWinners> => {
        const profileIds = contestWinners.userList.map((value: UserListWinners) => value.profileId);
        const profiles = await this.profileNamespace.getByIds(profileIds);
        contestWinners.userList.forEach((userWinner: UserListWinners) => {
            const profileModel = profiles.filter((profileModel: ProfileModel) => profileModel.id === userWinner.profileId)[0];
            delete profileModel.interests;
            userWinner.profileModel = profileModel;
        })


        return contestWinners;
    };

    /**
     * Assigns match model for the specific fixture. When no model is available, null value will be assign to matchModel property.
     * @param matchesMap Matches map returned from Football API
     * @param fixture Fixtures to remap
     */
    private handleMatchModel = (matchesMap: any, fixture: any) => {
        if (matchesMap[fixture.matchId]) {
            fixture.matchModel = matchesMap[fixture.matchId];
            delete fixture.matchModel.availableMarkets;
            fixture.matchId = fixture.matchModel.id;
        } else {
            fixture.matchModel = null;
            if (this.idMapping.idSchema !== IdSchemaEnum.NATIVE) {
                // Remap match ids to set the fixture.matchId
                this.idMapping.idMappingFacade.getEntityById(fixture.matchId, "match", "native").then((result: any) => {
                    //@ts-ignore
                    fixture.matchId = result[ID_TYPES[this.idMapping.idSchema]]
                })
            }
        }
    };

    /**
     * Remaps match id for market result response. When no match model is available make additional request to get the desired provider id.
     * @param matchModelMap Matches map returned from Football API
     * @param value Results for market
     */
    private remapMatchIdForResultsMarkets = (matchModelMap: any, value: any) => {
        if (matchModelMap[value.matchId]) {
            value.matchId = matchModelMap[value.matchId].id;
        } else {
            this.idMapping.idMappingFacade.getEntityById(value.matchId, "match", "native").then((result: any) => {
                //@ts-ignore
                value.matchId = result[ID_TYPES[this.idMapping.idSchema]]
            })
        }
    };

    private deleteUnexpectedProperties = (filters: PredictionsFilters) => {
        if (filters.status) {
            console.warn(WarningMessages.UNEXPECTED_STATUS_PROP);
            delete filters.status;
        }

        if (filters.type) {
            console.warn(WarningMessages.UNEXPECTED_TYPE_PROP);
            delete filters.type;
        }

        return filters;
    };
}