import SDKConfiguraitonModel from "../Configurator/Models/SDKConfiguraitonModel";
import PredictionRequestModel from "../Namespaces/Predictor/Models/Predictions/PredictionRequestModel";
import PredictionRemapper from "../Namespaces/Predictor/Models/Predictions/PredictionRemapper";
import PredictionResponseModel from "../Namespaces/Predictor/Models/Predictions/PredictionResponseModel";
import MatchSummaryRemapper from "../Namespaces/Predictor/Models/Summary/MatchSummaryRemapper";
import MatchSummaryModel from "../Namespaces/Predictor/Models/Summary/MatchSummaryModel";
import { initDisableCache, isDisabledCache, setBaseUrl } from "../Global/Helper";
import PredictionsFilters from "../Namespaces/Predictor/Models/Predictions/PredictionsFilters";
import GamesFilters from "../Namespaces/TopX/Models/Games/GamesFilters";
import GamesRemapper from "../Namespaces/TopX/Models/Games/GamesRemapper";
import IdMappingService from "../IdMapping/IdMappingService";
import TopXPredictionRequestModel from "../Namespaces/TopX/Models/Prediction/TopXPredictionRequestModel";
import GamesListModel from "../Namespaces/TopX/Models/Games/GamesListModel";
import PaginationModel from "../Global/Models/Pagination/PaginationModel";
import MetaRemapper from "../Global/Models/Pagination/Remapper/MetaRemapper";
import MainFiltersBQ from "../Global/Models/Filters/MainFiltersBQ";
import Https from "./Https";
import ContestWinners from "../Namespaces/TopX/Models/Games/Winners/ContestWinners";
import GameByIdModel from "../Namespaces/TopX/Models/Games/GameByIdModel";

export default class PredictorHttps extends Https {
    private predictionRemapper: PredictionRemapper = null;
    private matchSummaryRemapper: MatchSummaryRemapper = null;
    private gamesRemapper: GamesRemapper = null;
    private metaRemapper: MetaRemapper = null;

    constructor(config: SDKConfiguraitonModel, idMapping: IdMappingService) {
        super(config, setBaseUrl(config.environment, "predictor"));
        this.predictionRemapper = new PredictionRemapper(config, idMapping);
        this.matchSummaryRemapper = new MatchSummaryRemapper(config, idMapping);
        this.gamesRemapper = new GamesRemapper();
        this.metaRemapper = new MetaRemapper();
    }

    public makeFootballPrediction = async (prediction: PredictionRequestModel | TopXPredictionRequestModel): Promise<PredictionResponseModel> => {
        try {
            const requestData = this.predictionRemapper.remapRequestBody(prediction);
            const response = await this.instanceAuth.post(`/predictions${this.apiSignInUrl}`, JSON.stringify(requestData));
            return await this.predictionRemapper.remapResponseObjBody(response.data);
        } catch (e) {
            const fansUnitedException = this.transformAxiosErrorToFansUnitedException(e);
            console.warn('There was a problem making POST request: ', fansUnitedException);
            throw fansUnitedException.response;
        }
    };

    public getMatchSummary = async (matchId: string, disableCache: boolean): Promise<MatchSummaryModel> => {
        const url = isDisabledCache(disableCache) ?
            `/predictions/summary/${matchId}${this.apiSignInUrl}${initDisableCache()}` : `/predictions/summary/${matchId}${this.apiSignInUrl}`;

        try {
            const response = await this.instance.get(url);
            return this.matchSummaryRemapper.remapResponse(response.data);
        } catch (e) {
            const fansUnitedException = this.transformAxiosErrorToFansUnitedException(e);
            console.warn(`There was a problem fetching predictions for match: ${matchId} `, fansUnitedException);
            throw fansUnitedException.response;
        }
    };

    public deleteFootballPrediction = async (predictionId: string): Promise<boolean> => {
        try {
            await this.instanceAuth.delete(`/predictions/${predictionId}${this.apiSignInUrl}`);
            return true;
        } catch (e) {
            const fansUnitedException = this.transformAxiosErrorToFansUnitedException(e);
            console.warn(`There was a problem deleting your prediction: ${predictionId}`, fansUnitedException);
            throw fansUnitedException.response;
        }
    };

    public getMyPredictions = async (filters?: PredictionsFilters): Promise<PaginationModel> => {
        try {
            let myPredictionsPaginationModel = new PaginationModel();
            let url = `/predictions${this.apiSignInUrl}`;

            if (filters) {
                url += `${filters.constructFiltersUrl()}`;
            }

            const response = await this.instanceAuth.get(url);
            myPredictionsPaginationModel.meta = this.metaRemapper.remapMetaData(response.data.meta);
            myPredictionsPaginationModel.data = await this.predictionRemapper.remapResponseArrayBody(response.data.data);

            return myPredictionsPaginationModel;
        } catch (e) {
            const fansUnitedException = this.transformAxiosErrorToFansUnitedException(e);
            console.warn('There was a problem fetching own predictions', fansUnitedException);
            throw fansUnitedException.response;
        }
    };

    public getMyPredictionsNoRemap = async (filters?: PredictionsFilters): Promise<any> => {
        try {
            const response = await this.instanceAuth.get(`/predictions${this.apiSignInUrl}${filters.constructFiltersUrl()}`);

            return response.data;
        } catch (e) {
            const fansUnitedException = this.transformAxiosErrorToFansUnitedException(e);
            console.warn('There was a problem fetching own predictions', fansUnitedException);
            throw fansUnitedException.response;
        }
    };

    public getUserPredictions = async (userId: string, disableCache: boolean, filters?: PredictionsFilters): Promise<PaginationModel> => {
        try {
            let userPredictionsPaginationModel = new PaginationModel();
            let url = isDisabledCache(disableCache) ?
                `/predictions/users/${userId}${this.apiSignInUrl}${initDisableCache()}` :
                `/predictions/users/${userId}${this.apiSignInUrl}`;

            if (filters) {
                url += `${filters.constructFiltersUrl()}`;
            }

            const response = await this.instance.get(url);
            userPredictionsPaginationModel.meta = this.metaRemapper.remapMetaData(response.data.meta);
            userPredictionsPaginationModel.data = await this.predictionRemapper.remapResponseArrayBody(response.data.data);

            return userPredictionsPaginationModel;
        } catch (e) {
            const fansUnitedException = this.transformAxiosErrorToFansUnitedException(e);
            console.warn(`There was a problem fetching predictions for user: ${userId}`, fansUnitedException);
            throw fansUnitedException.response;
        }
    };

    public getUserPredictionsNoRemap = async (userId: string, filters?: PredictionsFilters): Promise<any> => {
        try {
            const response = await this.instance.get(`/predictions/users/${userId}${this.apiSignInUrl}${filters.constructFiltersUrl()}`);

            return response.data;
        } catch (e) {
            const fansUnitedException = this.transformAxiosErrorToFansUnitedException(e);
            console.warn(`There was a problem fetching predictions for user: ${userId}`, fansUnitedException);
            throw fansUnitedException.response;
        }
    };

    public getGames = async (filters: GamesFilters, disableCache?: boolean): Promise<PaginationModel> => {
        try {
            const url = isDisabledCache(disableCache) ?
                `/games/${this.apiSignInUrl}${filters.constructFiltersUrl()}${initDisableCache()}` : `/games/${this.apiSignInUrl}${filters.constructFiltersUrl()}`;

            const response = await this.instance.get(url);
            return this.gamesRemapper.remapPaginatedGames(response.data);
        } catch (e) {
            const fansUnitedException = this.transformAxiosErrorToFansUnitedException(e);
            console.warn(`There was a problem fetching the games list`, fansUnitedException);
            throw fansUnitedException.response;
        }
    };

    public getGameById = async (gameId: string, disableCache: boolean): Promise<GameByIdModel> => {
        try {
            const url = isDisabledCache(disableCache) ? `/games/${gameId}${this.apiSignInUrl}${initDisableCache()}` : `/games/${gameId}${this.apiSignInUrl}`;

            const response = await this.instance.get(url);
            return this.gamesRemapper.remapGameByIdResponse(response.data);
        } catch (e) {
            const fansUnitedException = this.transformAxiosErrorToFansUnitedException(e);
            console.warn(`There was a problem fetching the following game: ${gameId}`, fansUnitedException);
            throw fansUnitedException.response;
        }
    };

    public getGamePredictions = async (gameId: string, disableCache: boolean, filters?: PredictionsFilters): Promise<PaginationModel> => {
        try {
            let gamePredictionsPaginationModel = new PaginationModel();
            let url = isDisabledCache(disableCache) ?
                `/games/${gameId}/predictions${this.apiSignInUrl}${initDisableCache()}` : `/games/${gameId}/predictions${this.apiSignInUrl}`;

            if (filters) {
                url += `${filters.constructFiltersUrl()}`;
            }

            const response = await this.instance.get(url);
            gamePredictionsPaginationModel.meta = this.metaRemapper.remapMetaData(response.data.meta);
            gamePredictionsPaginationModel.data = await this.predictionRemapper.remapResponseArrayBody(response.data.data);

            return gamePredictionsPaginationModel;
        } catch (e) {
            const fansUnitedException = this.transformAxiosErrorToFansUnitedException(e);
            console.warn(`There was a problem fetching the predictions for game: ${gameId}`, fansUnitedException);
            throw fansUnitedException.response;
        }
    }

    public getUserGameEditions = async (filters: GamesFilters, disableCache: boolean): Promise<PaginationModel> => {
        try {
            let userGameEditionsPagination: PaginationModel = null;
            const url = isDisabledCache(disableCache) ?
                `/games/${this.apiSignInUrl}${filters.constructFiltersUrl()}${initDisableCache()}` : `/games/${this.apiSignInUrl}${filters.constructFiltersUrl()}`;
            const response = await this.instance.get(url);

            response.data.data.forEach((resp: any) => {
                if (resp.type === "TOP_X") {
                    userGameEditionsPagination = this.gamesRemapper.remapPaginatedUserTopXEditions(response.data);
                } else if (resp.type === "MATCH_QUIZ") {
                    userGameEditionsPagination = this.gamesRemapper.remapPaginatedUserMathQuizEditions(response.data);
                }
            });

            return userGameEditionsPagination;
        } catch (e) {
            const fansUnitedException = this.transformAxiosErrorToFansUnitedException(e);
            console.warn(`There was a problem fetching the games list`, fansUnitedException);
            throw fansUnitedException.response;
        }
    };

    public getGameResults = async (gameId: string, disableCache: boolean, filters?: MainFiltersBQ): Promise<PaginationModel> => {
        try {
            let url = isDisabledCache(disableCache) ?
                `/games/${gameId}/results/${this.apiSignInUrl}${initDisableCache()}` : `/games/${gameId}/results/${this.apiSignInUrl}`;

            if (filters) {
                url += `${filters.constructMainFiltersUrl()}`;
            }

            const response = await this.instance.get(url);

            return this.gamesRemapper.remapPaginatedGameResults(response.data)
        } catch (e) {
            const fansUnitedException = this.transformAxiosErrorToFansUnitedException(e);
            console.warn(`There was a problem fetching the results for the following game: ${gameId}`, fansUnitedException);
            throw fansUnitedException.response;
        }
    };

    public getMarketResultsForGame = async (gameId: string, disableCache: boolean) => {
        let url = `/games/${gameId}/correct-results/${this.apiSignInUrl}`;

        if (isDisabledCache(disableCache)) {
            url += `${initDisableCache()}`;
        }

        try {
            const response = await this.instance.get(url);

            return this.gamesRemapper.remapGameMarketsResults(response.data.data);
        } catch (e) {
            const fansUnitedException = this.transformAxiosErrorToFansUnitedException(e);
            console.warn(`There was a problem fetching the markets correct results for the following game: ${gameId}`, fansUnitedException);
            throw fansUnitedException.response;
        }
    };

    public getContestWinners = async (gameId: string): Promise<ContestWinners> => {
        try {
            const response = await this.instance.get(`/winners/${this.apiSignInUrl}&contest_id=${gameId}`);

            return this.gamesRemapper.remapContestWinners(response.data);
        } catch (e) {
            const fansUnitedException = this.transformAxiosErrorToFansUnitedException(e);
            console.warn(`There was a problem fetching winners for the following game: ${gameId}`, fansUnitedException);
            throw fansUnitedException.response;
        }
    };

    public getPredictionById = async (predictionId: string): Promise<PredictionResponseModel> => {
        try {
            let url = `/predictions/${predictionId}${this.apiSignInUrl}`;
            const response = await this.instanceAuth.get(url);
            const remappedPrediction = await this.predictionRemapper.remapResponseArrayBody([response.data.data]);

            return remappedPrediction[0];
        } catch (e) {
            const fansUnitedException = this.transformAxiosErrorToFansUnitedException(e);
            console.warn(`There was a problem fetching info for the following prediction: ${predictionId}`, fansUnitedException);
            throw fansUnitedException.response;
        }
    };

}