import BadgesModel from "../../../Loyalty/Models/Badges/BadgesModel";
import BadgesValue from "../../../Loyalty/Models/Badges/BadgesValue";
import RequirementModel from "../../../Loyalty/Models/Badges/RequirementModel";
import LoyaltyConfigModel from "../../../Loyalty/Models/ClientFeatures/LoyaltyConfigModel";
import PointsModel from "../../../Loyalty/Models/Points/PointsModel";
import RewardsModel from "../../../Loyalty/Models/Rewards/RewardsModel";
import MatchQuizConfigModel from "../../../MatchQuiz/Models/Config/MatchQuizConfigModel";
import TopXConfigModel from "../../../TopX/Models/Config/TopXConfigModel";
import PredictorConfigModel from "./PredictorConfigModel";
import UserDataConfigModel from "./UserDataConfigModel";

export default class FeatureConfigRemapper {

    public remapPredictorConfig = (response: any) => {
        let model = new PredictorConfigModel();

        model.enabled = response.enabled;
        model.clientId = response.client_id;
        model.fullCoverageCompetitions = response.full_coverage_competitions;
        model.markets = response.markets;
        model.successRateScopes = response.success_rate_scopes;
        model.userDataConfig = response.user_data_config ? this.remapUserDataConfig(response.user_data_config) : null;

        return model;
    };

    public remapTopXConfig = (response:any) => {
        let model = new TopXConfigModel();

        model.enabled = response.enabled;
        model.clientId = response.client_id;
        model.minFixtures = response.min_fixtures;
        model.maxFixtures = response.max_fixtures;
        model.competitionsWhitelist = response.competitions_whitelist;

        return model;
    };

    public remapMatchQuizConfig = (response:any) => {
        let model = new MatchQuizConfigModel();

        model.enabled = response.enabled;
        model.clientId = response.client_id;
        model.defaultMarkets = response.default_markets;
        model.competitionsWhitelist = response.competitions_whitelist;

        return model;
    };

    public remapLoyaltyConfig = (response: any): LoyaltyConfigModel => {
        const configModel = new LoyaltyConfigModel()

        configModel.enabled = response.enabled;
        configModel.clientId = response.client_id;
        configModel.conditions.list = response.conditions.list;
        configModel.actions.value= response.actions.value;
        configModel.rewards = this.remapRewards(response.rewards)

        return configModel;
    };

    private remapRewards = (rewards: any): RewardsModel => {
        const rewardsModel = new RewardsModel();

        rewardsModel.points = this.remapPoints(rewards.points);
        rewardsModel.tiers = rewards.tiers;
        rewardsModel.badges = this.remapBadges(rewards.badges);

        return rewardsModel;
    };

    private remapPoints = (points: any): PointsModel => {
        const pointsModel = new PointsModel();

        pointsModel.general = points.general;
        pointsModel.comments = points.comments;
        pointsModel.predictor = points.predictor;
        pointsModel.activity = points.activity;
        pointsModel.topX = points.top_x;
        pointsModel.matchQuiz = points.match_quiz;

        return pointsModel;
    };

    private remapBadges = (badges: any): BadgesModel => {
        const badgesModel = new BadgesModel();

        badgesModel.general = badges.general;
        badgesModel.predictor= this.remapBadgeValues(badges.predictor);
        badgesModel.topX = this.remapBadgeValues(badges.top_x);
        badgesModel.matchQuiz = this.remapBadgeValues(badges.match_quiz);

        return badgesModel;
    };

    private remapBadgeValues = (badgeValues: any[]) => {
        return badgeValues.map((value: any) => {
            const badgeValue = new BadgesValue()

            badgeValue.id = value.id;
            badgeValue.label = value.label;
            badgeValue.enabled = value.enabled;

            if (value.assets) {
                badgeValue.assets.mainImageUrl = value.assets.main_image_url;
            } else {
                badgeValue.assets = value.assets;
            }

            badgeValue.requirements = this.remapRequirements(value.requirements);

            return badgeValue;
        })
    };

    private remapRequirements = (badgesRequirements: any): RequirementModel => {
        const requirementModel = new RequirementModel();

        requirementModel.points = badgesRequirements.points;
        requirementModel.gameParticipationCount = badgesRequirements.game_participation_count;
        requirementModel.entityId = badgesRequirements.entity_id;
        requirementModel.entityType = badgesRequirements.entity_type;
        requirementModel.predictionsMade = badgesRequirements.predictions_made;
        requirementModel.correctPredictions = badgesRequirements.correct_predictions;

        return requirementModel;
    };

    private remapUserDataConfig = (response: any) => {
        const model = new UserDataConfigModel();

        model.storeIp = response.store_ip;
        model.storeDeviceId = response.store_device_id;
        model.storeAgent = response.store_agent;

        return model;
    }
}