import LocalStorage, { ExparationType } from "../../../Global/LocalStorage";
import ProfileHttps from "../../../Https/ProfileHttps";
import ProfileModel from "../Models/ProfileModel";
import ProfileCountryModel from "../Models/ProfileCountryModel";
import FullnessProfile from "../Models/FullnessProfile/FullnessProfile";
import InterestModel from "../Models/InterestModel";
import FollowFilters from "../Models/FollowFilters";
import PaginationModel from "../../../Global/Models/Pagination/PaginationModel";
import ProfileStatsModel from "../Models/Stats/ProfileStatsModel";
import ActivityHttps from "../../../Https/ActivityHttps";
import FollowersModel from "../Models/Followers/FollowersModel";
import FollowingModel from "../Models/Following/FollowingModel";

export default class ProfileFacade {
    private isBrowser:boolean = false;
    private localStorage: LocalStorage = {} as LocalStorage;
    private profileHttps: ProfileHttps = null;
    private activityHttps: ActivityHttps = null;

    constructor(localStorage: LocalStorage, profileHttps: ProfileHttps, activityHttps?: ActivityHttps) {
        this.isBrowser = typeof window !== 'undefined';
        this.localStorage = localStorage;
        this.profileHttps = profileHttps;
        this.activityHttps = activityHttps;
    }


    public getOwn = async (): Promise<ProfileModel> => {
        if (this.isBrowser) {
            const ownProfile = this.getLocalStorageData('profile');
            if (ownProfile) {
                return JSON.parse(JSON.stringify(ownProfile));
            } else {
                try {
                    const profileResponse = await this.profileHttps.getProfile();
                    this.localStorage.setOwnProfile(profileResponse);
                    return profileResponse;
                } catch (e) {
                    console.warn('There was a problem retrieving your profile: ', e);
                    throw e;
                }
            }
        } else {
            try {
                const profileResponse = await this.profileHttps.getProfile();
                return profileResponse;
            } catch (e) {
                console.warn('There was a problem retrieving your profile: ', e);
                throw e;
            }

        }
    };

    public updateOwnProfile = async () => {
        try {
            const profileResponse = await this.profileHttps.getProfile();
            this.localStorage.setOwnProfile(profileResponse);
            return profileResponse;
        } catch (e) {
            console.warn('There was a problem retrieving your profile: ', e);
            throw e;
        }
    }

    public getByIds = async (ids: string[], disableCache?: boolean): Promise<ProfileModel[]> => {
        try {
            disableCache = !disableCache ? false : disableCache;

            return await this.profileHttps.getProfiles(ids, disableCache);
        } catch (e) {
            console.warn(`There was a problem retrieving the selected profiles: ${ids.join(",")} `, e);
            throw e;
        }
    };

    public getById = async (id: string): Promise<ProfileModel> => {
        try {
            return await this.profileHttps.getProfile(id);
        } catch (e) {
            console.warn(`There was a problem retrieving the selected profile: ${id} `, e);
            throw e;
        }
    };

    public getCountries = async (disableCache?: boolean): Promise<ProfileCountryModel[]> => {
        disableCache = !disableCache ? false : disableCache;

        if (this.isBrowser) {
            const countries = this.localStorage.getProfileCountries();

            if (countries && countries.length > 0) {
                return countries;
            } else {
                try {
                    const profileCountries = await this.profileHttps.getCountries(disableCache);
                    this.localStorage.setProfileCountries(profileCountries);
                    return profileCountries;
                } catch (e) {
                    console.warn('There was a problem retrieving profile countries: ', e);
                    throw e;
                }
            }
        } else {
            try {
                return await this.profileHttps.getCountries(disableCache);
            } catch (e) {
                console.warn('There was a problem retrieving profile countries: ', e);
                throw e;
            }
        }
    };

    public getFollowers = async (filters?: FollowFilters, id?: string): Promise<PaginationModel> => {
        try {
            if (filters) {
                filters = new FollowFilters(filters);
            }
            const followers = await this.profileHttps.getFollowers(filters, id);
            const profileIds = followers.data.map((followerModel: FollowersModel) => followerModel.followerId);
            if (profileIds.length) {
                const profiles = await this.getByIds(profileIds);
                const newFollowers = followers.data.map((value: FollowersModel) => {
                    profiles.forEach((profile: ProfileModel) => {
                        if (value.followerId === profile.id) {
                            value.name = profile.name;
                            value.nickname = profile.nickname;
                            value.avatar = profile.avatar;
                        }
                    })
                    return value;

                });
                followers.data = newFollowers;

                return followers;
            }

            return followers;
        } catch (e) {
            console.warn('There was a problem retrieving your followers: ', e);
            throw e;
        }
    };

    public getFollowing = async (filters?: FollowFilters, id?: string): Promise<PaginationModel> => {
        try {
            if (filters) {
                filters = new FollowFilters(filters);
            }

            const following = await this.profileHttps.getFollowing(filters, id);
            const profileIds = following.data.map((followingModel: FollowingModel) => followingModel.followingId);
            if (profileIds.length) {
                const profiles = await this.getByIds(profileIds);
                const newFollowers = following.data.map((value: FollowingModel) => {
                    profiles.forEach((profile: ProfileModel) => {
                        if (value.followingId === profile.id) {
                            value.name = profile.name;
                            value.nickname = profile.nickname;
                            value.avatar = profile.avatar;
                        }
                    })
                    return value;

                });
                following.data = newFollowers;

                return following;
            }

            return following;
        } catch (e) {
            console.warn('There was a problem retrieving your following profiles: ', e);
            throw e;
        }
    };

    public follow = async (ids: string[]) => {
        try {
            return await this.profileHttps.follow(ids);
        } catch (e) {
            console.warn(`There was a problem following profiles: ${ids.join(",")} `, e);
            throw e;
        }
    };

    public unfollow = async (ids: string[]) => {
        try {
            return await this.profileHttps.unfollow(ids);
        } catch (e) {
            console.warn(`There was a problem unfollowing profiles: ${ids.join(",")} `, e);
            throw e;
        }
    };

    public showFullnessProfile = (profile: ProfileModel): FullnessProfile => {
        let fullnessProfile = new FullnessProfile();
        let totalPercentage: number = 0;

        if (profile.avatar) {
            fullnessProfile.breakdown.addedAvatar.completed = true;
            totalPercentage += fullnessProfile.breakdown.addedAvatar.percentage;
        }

        if (profile.birthDate) {
            fullnessProfile.breakdown.addedBirthdate.completed = true;
            totalPercentage += fullnessProfile.breakdown.addedBirthdate.percentage;
        }

        if (profile.country) {
            fullnessProfile.breakdown.addedCountry.completed = true;
            totalPercentage += fullnessProfile.breakdown.addedCountry.percentage;
        }

        if (profile.gender) {
            fullnessProfile.breakdown.addedGender.completed = true;
            totalPercentage += fullnessProfile.breakdown.addedGender.percentage;
        }

        if (profile.interests && profile.interests.length > 0) {
            const favouritedTeam = profile.interests.find((interest: InterestModel) => interest.type === "team" && interest.favourite);
            if (favouritedTeam) {
                fullnessProfile.breakdown.teamFavourite.completed = true;
                totalPercentage += fullnessProfile.breakdown.teamFavourite.percentage;
            }

            const followedTeam = profile.interests.find((interest: InterestModel) => interest.type === "team");
            if (followedTeam) {
                fullnessProfile.breakdown.teamFollowed.completed = true;
                totalPercentage += fullnessProfile.breakdown.teamFollowed.percentage;
            }

            const favouritedPlayer = profile.interests.find((interest: InterestModel) => interest.type === "player" && interest.favourite);
            if (favouritedPlayer) {
                fullnessProfile.breakdown.playerFavourite.completed = true;
                totalPercentage += fullnessProfile.breakdown.playerFavourite.percentage;
            }

            const followedPlayer = profile.interests.find((interest: InterestModel) => interest.type === "player");
            if (followedPlayer) {
                fullnessProfile.breakdown.playerFollowed.completed = true;
                totalPercentage += fullnessProfile.breakdown.playerFollowed.percentage;
            }

            const favouriteCompetition = profile.interests.find((interest: InterestModel) => interest.type === "competition" && interest.favourite);
            if (favouriteCompetition) {
                fullnessProfile.breakdown.competitionFavourite.completed = true;
                totalPercentage += fullnessProfile.breakdown.competitionFavourite.percentage;
            }

            const followedCompetition = profile.interests.find((interest: InterestModel) => interest.type === "competition");
            if (followedCompetition) {
                fullnessProfile.breakdown.competitionFollowed.completed = true;
                totalPercentage += fullnessProfile.breakdown.competitionFollowed.percentage;
            }

        }

        fullnessProfile.totalPercentage = totalPercentage;

        return fullnessProfile;
    };

    public getStats = async (): Promise<ProfileStatsModel> => {
        if (this.isBrowser) {
            const stats = this.getLocalStorageData('statistics');
            if (stats && Object.keys(stats).length) {
                return JSON.parse(JSON.stringify(stats));
            } else {
                try {
                    const profileStats = await this.activityHttps.getStats();
                    this.localStorage.setProfileStats(profileStats);
                    return profileStats;
                } catch (e) {
                    console.warn('There was a problem retrieving own profile statistics: ', e);
                    throw e;
                }
            }
        } else {
            try {
                return await this.activityHttps.getStats();
            } catch (e) {
                console.warn('There was a problem retrieving own profile statistics: ', e);
                throw e;
            }

        }
    };

    public getBadges = async (): Promise<string[]> => {
        if (this.isBrowser) {
            const badges = this.getLocalStorageData('badges');
            if (badges) {
                return badges;
            } else {
                try {
                    const profileBadges = await this.profileHttps.getBadges();
                    this.localStorage.setProfileBadges(profileBadges);
                    return profileBadges;
                } catch (e) {
                    console.warn('There was a problem retrieving own profile badges: ', e);
                    throw e;
                }
            }
        } else {
            try {
                return await this.profileHttps.getBadges();
            } catch (e) {
                console.warn('There was a problem retrieving own profile badges: ', e);
                throw e;
            }

        }
    };

    private getLocalStorageData = (typeData: ExparationType) => {
        const now = Math.floor(Date.now() / 1000);
        const expiration = this.localStorage.getExpiration("profile", typeData);

        switch (typeData) {
            case "profile":
                if (expiration > now) {
                    return this.localStorage.getOwnProfile();
                }
            case "statistics":
                if (expiration > now) {
                    return this.localStorage.getProfileStats();
                }
            case "badges":
                if (expiration > now) {
                    return this.localStorage.getProfileBadges();
                }
            default:
                return null;
        }
    };
}
