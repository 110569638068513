import LocalStorage from "../Global/LocalStorage";
import SDKConfigurationModel from "../Configurator/Models/SDKConfiguraitonModel";
import IdMappingHttps from "../Https/IdMappingHttps";
import {ID_TYPES} from "../Global/Constants/Constants";

export default class IdMappingFacade {
    private isBrowser: boolean = false;
    private localStorage: LocalStorage = null;
    private https: IdMappingHttps = null;

    constructor(configuration: SDKConfigurationModel) {
        this.isBrowser = typeof window !== 'undefined';
        this.localStorage = new LocalStorage(configuration);
        this.https = new IdMappingHttps(configuration);
    }

    getEntitiesByIds = async (idsObj: any, configSchema?: string, toSchema?: string) => {
        if (this.isBrowser) {
            await this.setTopIdMappings();
            try {
                const checkedIdsObj = this.localStorage.checkForExistingIdObjs(idsObj, configSchema);
                const splitIdObjs = this.splitForRequestAndExisting(checkedIdsObj);
                const retrievedIdObjs = await this.https.getEntitiesByIdObjs(splitIdObjs.request, configSchema);
                this.localStorage.addIdMappings(retrievedIdObjs);
                return this.localStorage.getIdsByType(idsObj, configSchema, toSchema);
           } catch (e) {
                console.warn('There was a problem retrieving the entity ids ', e);
                return Promise.resolve([]);
           }
        } else {
            try {
                const result = await this.https.getEntitiesByIdObjs(idsObj, configSchema);
                Object.keys(idsObj).forEach((key: string) => {
                    //@ts-ignore
                    idsObj[key] = result.filter((idObj: any) => idObj.resource === key).map((idObj: any) => idObj[ID_TYPES[toSchema]]);
                })
                return idsObj;
            } catch (e) {
                console.warn('There was a problem retrieving the entity ids ', e);
                return Promise.resolve([]);
            }
        }
    };

    public getEntityById = async (ids: string | string[], type: string, schemaId: string) => {
        if (this.isBrowser) {
            const entity = this.localStorage.getEntity(ids, type, schemaId);
            if (entity) {
                return entity;
            } else {
                const requestedEntities = await this.requestEntity(ids, type, schemaId);
                this.localStorage.addIdMappings(requestedEntities);
                return requestedEntities;
            }
        } else {
            return await this.requestEntity(ids, type, schemaId);
        }
    };

    /**
     * Checks ids if they exist in the local storage, this function requires the already checked ids object
     * @param checkedIdsObj
     */
    private splitForRequestAndExisting = (checkedIdsObj: any) => {
        let toRequestIdsObj = {};
        let existingIdsObj = {};

        Object.keys(checkedIdsObj).forEach((key: string) => {
            //@ts-ignore
            toRequestIdsObj[key] = checkedIdsObj[key].filter((checkedId: any) => !checkedId.exists).map((checkedId: any) => checkedId.id);
            //@ts-ignore
            existingIdsObj[key] = checkedIdsObj[key].filter((checkedId: any) => checkedId.exists).map((checkedId: any) => checkedId.id);
        });

        return {existing: existingIdsObj, request: toRequestIdsObj};
    };

    private requestEntity = async (ids: string | string[], type: string, schemaId: string) => {
        try {
            ids = Array.isArray(ids) ? ids : [ids];
            return await this.https.getEntitiesByIds(type, ids, schemaId);
        } catch (e) {
            console.warn(`There was a problem retrieving the requested entity: ${e}`);
            return null;
        }
    };

    /**
     * Fetches top id mappings and store them in local storage whether they don't exist or their
     * expiration time has passed
     */
    private setTopIdMappings = async () => {
        const now = Math.floor(Date.now() / 1000);
        const expiration = this.localStorage.getExpiration("football", "ids");

        if (expiration > now) {
            return Promise.resolve();
        }

        const topIdMappings = await this.https.getTopEntities();
        this.localStorage.addTopIdMappings(topIdMappings);
    };

}
