import LocalStorage from "../../../Global/LocalStorage";
import PaginationModel from "../../../Global/Models/Pagination/PaginationModel";
import SDKIdsRemapper from "../../../Remapper/SDKIdsRemapper";
import ActivityHttps from "../../../Https/ActivityHttps";
import ProfileHttps from "../../../Https/ProfileHttps";
import ProfileFacade from "../Facade/ProfileFacade";
import FollowFilters from "../Models/FollowFilters";
import FullnessProfile from "../Models/FullnessProfile/FullnessProfile";
import InterestModel from "../Models/InterestModel";
import ProfileModel from "../Models/ProfileModel";
import ProfileStatsModel from "../Models/Stats/ProfileStatsModel";

export default class ProfileBuilder {
    private idsRemapper: SDKIdsRemapper = null;
    private profileHttps: ProfileHttps = null;
    private activityHttps: ActivityHttps = null;
    private profileFacade: ProfileFacade = null;
    private profileId: string = "";


    constructor(idsRemapper: SDKIdsRemapper, localStorage: LocalStorage, profileHttps: ProfileHttps, activityHttps: ActivityHttps) {
        this.idsRemapper = idsRemapper;
        this.profileHttps = profileHttps;
        this.activityHttps = activityHttps;
        this.profileFacade = new ProfileFacade(localStorage, profileHttps, activityHttps);
    }

    public setId = (id: string) => {
        this.profileId = id;
        return this;
    }

    private getProfile = async (): Promise<ProfileModel> => {
        return await this.profileFacade.getById(this.profileId);

    };

    public getInfo = async (): Promise<ProfileModel> => {
        const profile = await this.getProfile();
        delete profile.interests;

        return profile;
    };

    public showInterests = async (): Promise<InterestModel[]> => {
        const profile = await this.getProfile();
        const profileCopy = JSON.parse(JSON.stringify(profile));

        return await this.idsRemapper.remapInterests(profileCopy.interests);
    };

    public showFullInterests = async () => {
        const profile = await this.getProfile();
        const profileCopy = JSON.parse(JSON.stringify(profile));

        return await this.idsRemapper.showFullInterests(profileCopy.interests);
    };

    public getFollowers = async (filters?: FollowFilters): Promise<PaginationModel> => {
        return await this.profileFacade.getFollowers(filters, this.profileId);
    };

    public getFollowing = async (filters?: FollowFilters): Promise<PaginationModel> => {
        return await this.profileFacade.getFollowing(filters, this.profileId);
    };

    public showFullnessProfile = async (): Promise<FullnessProfile> => {
        const profile = await this.getProfile();

        return this.profileFacade.showFullnessProfile(profile);
    };

    public getStats = async (disableCache?: boolean): Promise<ProfileStatsModel> => {
        const profileStats = await this.activityHttps.getStats(this.profileId, disableCache);

        return await this.idsRemapper.remapSuccessRatesEntities(profileStats);
    };

    public getBadges = async (): Promise<string[]> => {
        return await this.profileHttps.getBadges(this.profileId);
    };

}