import {  AxiosResponse } from "axios";
import SDKConfiguraitonModel from "../Configurator/Models/SDKConfiguraitonModel";
import { initDisableCache, isDisabledCache, setBaseUrl } from "../Global/Helper";
import ActivityResponseBody from "../Namespaces/Activity/Models/ActivityResponseBody";
import PaginationActivities from "../Namespaces/Activity/Models/PaginationActivities";
import CreateActivityRequestBody from "../Namespaces/Activity/Models/RequestBody/CreateActivityRequestBody";
import ActivityRemapper from "../Namespaces/Activity/Remapper/ActivityRemapper";
import ProfileStatsModel from "../Namespaces/Profile/Models/Stats/ProfileStatsModel";
import Https from "./Https";
import OwnActivityFilters from "../Namespaces/Activity/Models/Filters/OwnActivityFilters";
import UserActivityFilters from "../Namespaces/Activity/Models/Filters/UserActivityFilters";

export default class ActivityHttps extends Https {
    private activityRemapper: ActivityRemapper = null;

    constructor(config: SDKConfiguraitonModel) {
        super(config, setBaseUrl(config.environment, "loyalty"));
        this.activityRemapper = new ActivityRemapper();
    }

    public createActivity = async (requestBody: CreateActivityRequestBody): Promise<ActivityResponseBody> => {
        try {
            const url = `/activities${this.apiSignInUrl}`;
            const response = await this.instanceAuth.post(url, JSON.stringify(requestBody));

            return this.activityRemapper.remapSingleResponse(response.data);
        } catch (e) {
            const fansUnitedException = this.transformAxiosErrorToFansUnitedException(e);
            console.warn('There was a problem with creating activity for user', fansUnitedException);
            throw fansUnitedException.response;
        }
    }

    public deleteActivity = async (activityId: string): Promise<boolean> => {
        try {
            const url = `/activities/${activityId}${this.apiSignInUrl}`;
            await this.instanceAuth.delete(url);

            return true;
        } catch (e) {
            const fansUnitedException = this.transformAxiosErrorToFansUnitedException(e);
            console.warn(`There was a problem deleting the following activity: ${activityId}`, fansUnitedException);
            throw fansUnitedException.response;
        }
    }

    public getOwnActivities = async (filters: OwnActivityFilters, disableCache: boolean): Promise<PaginationActivities> => {
        try {
            let url = `/activities${this.apiSignInUrl}`;
            const timestampCache = Date.now();

            if (filters) {
                url += `${filters.constructFilterUrl()}`;
            }

            if (disableCache) {
                url += `disable_cache=${timestampCache}`;
            }

            const response = await this.instanceAuth.get(url);

            return this.activityRemapper.remapActivitiesResponse(response.data);
        } catch (e) {
            const fansUnitedException = this.transformAxiosErrorToFansUnitedException(e);
            console.warn('There was a problem with fetching own activities', fansUnitedException);
            throw fansUnitedException.response;
        }
    }

    public getActivitiesForUser = async (userId: string, filters: UserActivityFilters, disableCache: boolean): Promise<PaginationActivities> => {
        try {
            let url = `/users/${userId}/activities${this.apiSignInUrl}`;
            const timestampCache = Date.now();

            if (filters) {
                url += `${filters.constructFilterUrl()}`;
            }

            if (disableCache) {
                url += `disable_cache=${timestampCache}`;
            }

            const response = await this.instance.get(url);

            return this.activityRemapper.remapActivitiesResponse(response.data);
        } catch (e) {
            const fansUnitedException = this.transformAxiosErrorToFansUnitedException(e);
            console.warn(`There was a problem with fetching activities for user ${userId}`, fansUnitedException);
            throw fansUnitedException.response;
        }
    }

    public getStats = async (userId?: string, disableCache?: boolean): Promise<ProfileStatsModel> => {
        try {
            let url = '';
            let response: AxiosResponse = null;

            if (userId) {
                url += isDisabledCache(disableCache) ?
                `/users/${userId}/statistics${this.apiSignInUrl}${initDisableCache()}` :
                `/users/${userId}/statistics${this.apiSignInUrl}`;

                response = await this.instance.get(url);
            } else {
                url += `/statistics${this.apiSignInUrl}`;
                response = await this.instanceAuth.get(url);
            }

            return this.activityRemapper.remapStatsReponse(response.data.data);
        } catch (e) {
            const fansUnitedException = this.transformAxiosErrorToFansUnitedException(e);
            console.warn('There was a problem fetching profile`s statistics:', fansUnitedException);
            throw fansUnitedException.response;
        }
    }

}