import axios, { AxiosError, AxiosInstance } from "axios";
import jwtDecode from "jwt-decode";
import SDKConfigurationModel from "../Configurator/Models/SDKConfiguraitonModel";
import { ErrorHandlingModeType } from "../Configurator/Types/ConfiguratorTypes";
import { ErrorCodes } from "../Exception/ErrorCodes";
import { ErrorStatuses } from "../Exception/ErrorStatuses";
import StandardFansUnitedException from "../Exception/StandardFansUnitedException";
import { axiosError } from "../Global/axioError";
import { ErrorMessages } from "../Global/Messages/Messages";

export default class Https {
  protected apiSignInUrl: string = null;
  protected instanceAuth: AxiosInstance = null;
  protected instance: AxiosInstance = null;
  private errorHandlingMode: ErrorHandlingModeType = null;

  constructor(configuration: SDKConfigurationModel, baseUrl: string) {
    this.apiSignInUrl = `?key=${configuration.apiKey}&client_id=${configuration.clientId}`;
    this.errorHandlingMode = configuration.errorHandlingMode;
    this.instanceAuth = axios.create({
      baseURL: baseUrl,
      headers: {
        Authorization: `Bearer ${configuration.authProvider.getIdToken()}`,
        "Content-Type": "application/json",
      },
    });

    this.instanceAuth.interceptors.request.use(
      (config) => {
        let decodeToken: any = null;
        try {
          decodeToken = jwtDecode(configuration.authProvider.getIdToken()) as {
            [key: string]: any;
          };
        } catch (error) {
          // Throws only when invalid token is provided.
          if (this.errorHandlingMode === "default") {
            throw axiosError(config, 401, error.message);
          } else if (this.errorHandlingMode === "standard") {
            throw new StandardFansUnitedException(
              ErrorCodes.UNAUTHORIZED,
              ErrorStatuses.INVALID_TOKEN,
              ErrorMessages.INVALID_TOKEN
            );
          }
        }
        const isExpired = Date.now() > decodeToken.exp;

        if (!isExpired) return config;

        axios.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${configuration.authProvider.getIdToken()}`;
        config.headers.Authorization = `Bearer ${configuration.authProvider.getIdToken()}`;

        return config;
      },
      (error) => {
        Promise.reject(error);
      }
    );

    this.instanceAuth.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        const originalRequest = error.config;

        if (
          (error.response?.status === 403 || error.response?.status === 401) &&
          !originalRequest._retry
        ) {
          originalRequest._retry = true;
          const accessToken = configuration.authProvider.getIdToken();
          originalRequest.headers["Authorization"] = `Bearer ${accessToken}`;

          return this.instanceAuth(originalRequest);
        }

        // Returns error for expired token
        if (
          this.errorHandlingMode === "standard" &&
          error.response?.status === 401
        ) {
          const standardError = new StandardFansUnitedException(
            ErrorCodes.UNAUTHORIZED,
            ErrorStatuses.UNAUTHORIZED,
            ErrorMessages.EXPIRED_TOKEN
          );

          return Promise.reject(standardError);
        }

        return Promise.reject(error);
      }
    );

    this.instance = axios.create({
      baseURL: baseUrl,
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  /**
   * Returns the axios error param when handling mode is set to default
   * Otherwise returns a new StandardFansUnitedException from API's returned exception body.
   * @param e Axios error. Catched from throwed CRUD operations.
   * @returns Axios error or StandardFansUnitedException
   */
  protected transformAxiosErrorToFansUnitedException = (
    e: AxiosError
  ): AxiosError | StandardFansUnitedException => {
    if (this.errorHandlingMode === "standard" && e.response && e.response.data && e.response.data.error) {
      const { code, status, message } = e.response.data.error;
      return new StandardFansUnitedException(code, status, message);
    }

    return e;
  };
}
