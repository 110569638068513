import { MarketEnum } from "../../Namespaces/Predictor/Enums/MarketEnum";
import { documentationLinks } from "../Constants/Constants";

export enum ErrorMessages {
  LOYALTY_INVALID_TEMPLATE_ID = "Provided template ID does NOT exist! Try again with existing template ID.",
  INVALID_MATCH_ID = "Match id is invalid!",
  INVALID_PLAYER_ID = "Player id is invalid!",
  QUERY_PARAM_MATCH_IDS_EXCEEDED = "Maximum matchIds in PredictionFilters should be 10 or less.",
  QUERY_PARAM_FILTERS_LIMIT_EXCEEDED = "Maximum limit for filtering games is 50 or less.",
  INVALID_PREDICTION_FIELD = "Prediction field is invalid. For more information please visit our documentation",
  INVALID_TOKEN = "Invalid JWT token is provided",
  EXPIRED_TOKEN = "The provided JWT token is expired",
  ENTITIES_NEEDED = "At least one of footballCompetitions, footballTeams or footballPlayers properties should be provided for filters.",
  ENTITIES_FOLLOWS_FILTERS_EXCEEDED = "Maximum length per entity should be 24 or less.",
  INVALID_TYPE = "You have provided invalid type. The valid type is ",
  INVALID_VALUE = "You have provided invalid value. The valid value is ",
  INVALID_AVATAR = "Invalid URL syntax for avatar image."
}

export const WarningMessages = {
  UNEXPECTED_STATUS_PROP: `Unexpected provided property 'status'. You can NOT use this property for this method. For more information visit our documentation: \n ${documentationLinks.PREDICTOR_USER_RELATED_OPERATION}`,
  UNEXPECTED_TYPE_PROP: `Unexpected provided property 'type'. You can NOT use this property for this method. For more information visit our documentation: \n ${documentationLinks.PREDICTOR_USER_RELATED_OPERATION}`,
};

export const invalidPredictionMessage = (
  prediction: string,
  market: MarketEnum
) => {
  return `The prediction '${prediction}' is invalid for market '${market}'. Please check again have you passed appropriate prediction model. For more information please visit our documentation: https://docs.fansunitedmedia.com/sdks/js/match.quiz#gamerelatedoperations`;
};

export const invalidMarketMessage = (market: string) => {
  return `The market '${market}' is not supported from Fans United. For more information please visit our documentation: https://docs.fansunitedmedia.com/docs/predictor-operations#make-football-prediction`;
};

export const invalidMarketTopXMessage = (market: string) => {
  return `The market '${market}' is not valid for Top X game. The only available market is CORRECT_SCORE.`;
};

export const invalidFieldMessage = (fields: string[]) => {
  return `The field/s '${
    fields.length > 1 ? fields.join(", ") : fields[0]
  }' is/are invalid. For more information please visit our documentation: https://docs.fansunitedmedia.com/docs/javascript-sdk`;
};

export const missingFieldsMessage = (
  field: string,
  missingFields: string[]
) => {
  return `The following field/s is/are missing from ${field}: '${
    missingFields.length > 1 ? missingFields.join(", ") : missingFields[0]
  }'. For more information please visit our documentation: https://docs.fansunitedmedia.com/docs/predictor-operations#make-football-prediction`;
};

export const invalidTypeMessage = (field: string, correctType: string) => {
  return `The field ${field} has incorrect type. The correct type is ${correctType}`;
};
