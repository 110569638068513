import LocalStorage from "../../../Global/LocalStorage";
import FootballHttps from "../../../Https/FootballHttps";
import IdMappingService from "../../../IdMapping/IdMappingService";
import FootballCountryModel from "../Models/Country/FootballCountryModel"

export default class CountriesFacade {
    private isBrowser:boolean = false;
    private localStorage: LocalStorage = {} as LocalStorage;
    private https: FootballHttps = null;
    private idMapping: IdMappingService = null;

    constructor(localStorage: LocalStorage, https: FootballHttps, idMapping: IdMappingService) {
        this.isBrowser = typeof window !== 'undefined';
        this.localStorage = localStorage;
        this.idMapping = idMapping;
        this.https = https;
    }

    public getCountriesWithIdMapping = async (disableCache? : boolean): Promise<FootballCountryModel[]> => {
        try {
            const countries = await this.getCountries(disableCache);
            return this.idMapping.remapEntities(countries, 'country')
        } catch (e) {
            console.warn('There was a problem browsing countries as football entities', e);
            throw e;
        }
    };

    private getCountries = async (disableCache?: boolean): Promise<FootballCountryModel[]> => {
        disableCache = !disableCache ? false : disableCache;

        if (this.isBrowser) {
            const countries = this.localStorage.getFootballCountries();
            if (countries && countries.length > 0) {
                return countries;
            } else {
                try {
                    const footballCountries = await this.https.getCountries(disableCache);
                    this.localStorage.setFootballCountries(footballCountries);
                    return footballCountries;
                } catch (e) {
                    console.warn('There was a problem retrieving countries as football entities', e);
                    throw e;
                }
            }
        } else {
            return this.https.getCountries(disableCache);
        }
    };
}
