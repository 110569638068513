import SDKConfiguraitonModel from "../Configurator/Models/SDKConfiguraitonModel";
import { initDisableCache, isDisabledCache, setBaseUrl } from "../Global/Helper";
import PaginationModel from "../Global/Models/Pagination/PaginationModel";
import FootballPaginationModel from "../Namespaces/Football/Models/Pagination/FootballPaginationModel";
import LoyaltyFilters from "../Namespaces/Loyalty/Models/Filters/LoyaltyFilters";
import RankingsFilters from "../Namespaces/Loyalty/Models/Filters/RankingsFilters";
import LeaderboardRemapper from "../Namespaces/Loyalty/Models/Leaderboard/LeaderboardRemapper";
import RankingsRemapper from "../Namespaces/Loyalty/Models/Rankings/RankingsRemapper";
import TemplateModel from "../Namespaces/Loyalty/Models/Template/TemplateModel";
import TemplateRemapper from "../Namespaces/Loyalty/Models/Template/TemplateRemapper";
import HighestSuccessRateModel from "../Namespaces/Loyalty/Models/HighestSuccessRate/HighestSuccessRateModel";
import HighestSuccessRateRemapper from "../Namespaces/Loyalty/Models/HighestSuccessRate/HighestSuccessRateRemapper";
import Https from "./Https";
import EntitiesFollowsFilters from "../Namespaces/Loyalty/Models/Filters/EntitiesFollowsFilters";
import EntitiesFollows from "../Namespaces/Loyalty/Models/EntitiesFollows/EntitiesFollows";
import TemplateByIdModel from "../Namespaces/Loyalty/Models/Template/TemplateByIdModel";
import HighestSuccessRateFilters from "../Namespaces/Loyalty/Models/Filters/HighestSuccessRateFilters";
export default class LoyaltyHttps extends Https {
    private templateRemapper: TemplateRemapper = null;
    private leaderboardRemapper: LeaderboardRemapper = null;
    private rankingsRemapper: RankingsRemapper = null;
    private highestSuccessRateRemapper: HighestSuccessRateRemapper = null;

    constructor(config: SDKConfiguraitonModel) {
        super(config, setBaseUrl(config.environment, "loyalty"));
        this.templateRemapper = new TemplateRemapper();
        this.leaderboardRemapper = new LeaderboardRemapper();
        this.rankingsRemapper = new RankingsRemapper();
        this.highestSuccessRateRemapper = new HighestSuccessRateRemapper();
    }


    public getTemplates = async (disableCache: boolean, isPaginated: boolean): Promise<PaginationModel | TemplateModel[]> => {
        try {
            let url = isDisabledCache(disableCache) ? `/leaderboard/templates/${this.apiSignInUrl}${initDisableCache()}` : `/leaderboard/templates/${this.apiSignInUrl}`;
            const response = await this.instance.get(url);

            if (isPaginated) {
                const copyResponse = JSON.parse(JSON.stringify(response.data));
                copyResponse.meta = this.templateRemapper.remapMeta(response.data.meta);
                copyResponse.data = this.templateRemapper.remapResponse(response.data.data);

                return copyResponse;
            }

            return this.templateRemapper.remapResponse(response.data.data);
        } catch (e) {
            const fansUnitedException = this.transformAxiosErrorToFansUnitedException(e);
            console.warn(`There was a problem fetching the list of templates`, fansUnitedException);
            throw fansUnitedException.response;
        }
    };

    public getTemplateById = async (templateId: string, disableCache: boolean): Promise<TemplateByIdModel> => {
        try {
            const url = isDisabledCache(disableCache) ? `/leaderboard/templates/${templateId}${this.apiSignInUrl}${initDisableCache()}`
                : `/leaderboard/templates/${templateId}${this.apiSignInUrl}`;
            const response = await this.instance.get(url);

            return this.templateRemapper.remapResponseId(response.data.data);
        } catch (e) {
            const fansUnitedException = this.transformAxiosErrorToFansUnitedException(e);
            console.warn(`There was a problem requesting the following template: ${templateId}`, fansUnitedException);
            throw fansUnitedException.response;
        }
    };

    public getLeaderboard = async (templateId: string, disableCache: boolean, filters: LoyaltyFilters): Promise<PaginationModel> => {
        try {
            let url = isDisabledCache(disableCache) ? `/leaderboard/${templateId}/${this.apiSignInUrl}${initDisableCache()}` : `/leaderboard/${templateId}/${this.apiSignInUrl}`;

            if (filters) {
                url += `${filters.constructFiltersUrl()}`;
            }

            const response = await this.instance.get(url);
            const copyResponse = JSON.parse(JSON.stringify(response.data));

            return this.leaderboardRemapper.remapResponse(copyResponse);
        } catch (e) {
            const fansUnitedException = this.transformAxiosErrorToFansUnitedException(e);
            console.warn(`There was a problem fetching the leaderboard for the following template: ${templateId}`, fansUnitedException);
            throw fansUnitedException.response;
        }
    };

    public getLeaderboardForGame = async (gameId: string, disableCache: boolean, filters: LoyaltyFilters): Promise<PaginationModel> => {
        try {
            let url = isDisabledCache(disableCache) ? `/leaderboard/games/${gameId}/${this.apiSignInUrl}${initDisableCache()}` : `/leaderboard/games/${gameId}/${this.apiSignInUrl}`;

            if (filters) {
                url += `${filters.constructFiltersUrl()}`;
            }

            const response = await this.instance.get(url);
            const copyResponse = JSON.parse(JSON.stringify(response.data));

            return this.leaderboardRemapper.remapResponse(copyResponse);
        } catch (e) {
            const fansUnitedException = this.transformAxiosErrorToFansUnitedException(e);
            console.warn(`There was a problem fetching the leaderboard for the following game: ${gameId}`, fansUnitedException);
            throw fansUnitedException.response;
        }
    };

    public getUserRankings = async (userId: string, filters: RankingsFilters, disableCache: boolean): Promise<FootballPaginationModel> => {
        let url = `/leaderboard/users/${userId}/rankings/${this.apiSignInUrl}`;

        if (filters) {
            url += `${filters.constructFiltersUrl()}`;
        }

        if (isDisabledCache(disableCache)) {
            url += `${initDisableCache()}`;
        }

        try {
            const response = await this.instance.get(url);

            return this.rankingsRemapper.remapRankings(response.data);
        } catch (e) {
            const fansUnitedException = this.transformAxiosErrorToFansUnitedException(e);
            console.warn(`There was a problem fetching the rankings for the following user: ${userId}`, fansUnitedException);
            throw fansUnitedException.response;
        }
    };

    public getHighestSuccessRate = async (filters: HighestSuccessRateFilters, disableCache: boolean): Promise<HighestSuccessRateModel[]> => {
        let url = `/statistics/top${this.apiSignInUrl}`;

        if (filters) {
            url +=  `${filters.constructFiltersUrl()}`;
        }

        if (isDisabledCache(disableCache)) {
            url += `${initDisableCache()}`;
        }

        try {
            const response = await this.instance.get(url);

            return this.highestSuccessRateRemapper.remapHighestSuccessRate(response.data.data);
        } catch (e) {
            const fansUnitedException = this.transformAxiosErrorToFansUnitedException(e);
            console.warn(`There was a problem fetching highest success rate`, fansUnitedException);
            throw fansUnitedException.response;
        }
    };

    public getEntitiesFollows = async (filters: EntitiesFollowsFilters, disableCache: boolean): Promise<EntitiesFollows> => {
        let url = isDisabledCache(disableCache) ?
            `/statistics/follows${this.apiSignInUrl}${initDisableCache()}` :
            `/statistics/follows${this.apiSignInUrl}`;

        if (filters.footballCompetitions) {
            url += `&competitions=${filters.footballCompetitions.join(",")}`;
        }

        if (filters.footballTeams) {
            url += `&teams=${filters.footballTeams.join(",")}`;
        }

        if (filters.footballPlayers) {
            url += `&players=${filters.footballPlayers.join(",")}`;
        }

        try {
            const response = await this.instance.get(url);

            return response.data.data;
        } catch (e) {
            const fansUnitedException = this.transformAxiosErrorToFansUnitedException(e);
            console.warn(`There was a problem fetching follows for entities`, fansUnitedException);
            throw fansUnitedException.response;
        }
    };
}