import SDKConfiguraitonModel from "../Configurator/Models/SDKConfiguraitonModel";
import { limitIds, setBaseUrl } from "../Global/Helper";
import ProfileModel from "../Namespaces/Profile/Models/ProfileModel";
import ProfileCountryModel from "../Namespaces/Profile/Models/ProfileCountryModel";
import ProfileRemapper from "../Namespaces/Profile/Models/Remappers/ProfileRemapper";
import FollowingRemapper from "../Namespaces/Profile/Models/Remappers/FollowingRemapper";
import FollowersRemapper from "../Namespaces/Profile/Models/Remappers/FollowersRemapper";
import FollowingModel from "../Namespaces/Profile/Models/Following/FollowingModel";
import FollowModel from "../Namespaces/Profile/Models/Following/FollowModel";
import FollowFilters from "../Namespaces/Profile/Models/FollowFilters";
import PaginationModel from "../Global/Models/Pagination/PaginationModel";
import { LangType } from "../Configurator/Types/ConfiguratorTypes";
import Https from "./Https";

const COUNTRIES_REQUEST = '/countries';
const PROFILE_REQUEST = '/profile';
const PROFILES_REQUEST = '/profiles';

export default class ProfileHttps extends Https {
    private profileRemapper: ProfileRemapper = null;
    private followingRemapper: FollowingRemapper = null;
    private followersRemapper: FollowersRemapper = null;
    private lang: LangType = null;
    private limitIds: number = 100;

    constructor(configuration: SDKConfiguraitonModel) {
        super(configuration, setBaseUrl(configuration.environment, "profile"));
        this.lang = configuration.lang;
        this.profileRemapper = new ProfileRemapper();
        this.followingRemapper = new FollowingRemapper();
        this.followersRemapper = new FollowersRemapper();
    }

    public getCountries = async (disableCache: boolean): Promise<ProfileCountryModel[]> => {
        try {
            const langParam = `&lang=${this.lang}`;
            let url = `${COUNTRIES_REQUEST}${this.apiSignInUrl}${langParam}`;
            const timestampCache = Date.now();

            if (disableCache) {
                url += `&disable_cache=${timestampCache}`;
            }

            const response = await this.instance.get(url);
            return response.data.data;
        } catch (e) {
            const fansUnitedException = this.transformAxiosErrorToFansUnitedException(e);
            console.warn('There was a problem fetching the countries:', fansUnitedException);
            throw fansUnitedException.response;
        }
    };

    public getProfile = async (id?: string): Promise<ProfileModel> => {
        let url = `${PROFILE_REQUEST}${this.apiSignInUrl}`;

        if (id) {
            url = `${PROFILES_REQUEST}/${id}${this.apiSignInUrl}`;
            try {
                const response = await this.instance.get(url);
                return this.profileRemapper.fullProfileFromResponse(response.data.data);
            } catch (e) {
                const fansUnitedException = this.transformAxiosErrorToFansUnitedException(e);
                console.warn(`There was a problem fetching the following profile: ${id}`, fansUnitedException);
                throw fansUnitedException.response;
            }
        } else {
            try {
                const response = await this.instanceAuth.get(url);
                return this.profileRemapper.fullProfileFromResponse(response.data.data);
            } catch (e) {
                const fansUnitedException = this.transformAxiosErrorToFansUnitedException(e);
                console.warn(`There was a problem fetching your profile from API:`, fansUnitedException);
                throw fansUnitedException.response;
            }
        }
    };

    public getProfiles = async (profiles: string[], disableCache: boolean): Promise<ProfileModel[]> => {
        try {
            const timestampCache = Date.now();

            if (profiles.length > this.limitIds) {
                let profileModels: ProfileModel[] = [];
                const limitedIds = limitIds(profiles, this.limitIds);

                for (const ids of limitedIds) {
                    let url = `${PROFILES_REQUEST}${this.apiSignInUrl}&profile_ids=${ids.join(',')}`;

                    if (disableCache) {
                        url += `&disable_cache=${timestampCache}`;
                    }

                    const response = await this.instance.get(url);
                    profileModels.push(...this.profileRemapper.profilesFromResponse(response.data.data));
                }

                return profileModels;
            }
            let url = `${PROFILES_REQUEST}${this.apiSignInUrl}&profile_ids=${profiles.join(',')}`;

            if (disableCache) {
                url += `&disable_cache=${timestampCache}`;
            }

            const response = await this.instance.get(url);
            return this.profileRemapper.profilesFromResponse(response.data.data);
        } catch (e) {
            const fansUnitedException = this.transformAxiosErrorToFansUnitedException(e);
            console.warn(`There was a problem fetching the following profiles: ${profiles.join(",")}`, fansUnitedException);
            throw fansUnitedException.response;
        }
    };

    public getFollowers = async (filters?: FollowFilters, id?: string): Promise<PaginationModel> => {
        let url = `${PROFILE_REQUEST}/followers${this.apiSignInUrl}`;

        if (filters && !id) {
            url = `${PROFILE_REQUEST}/followers${this.apiSignInUrl}${filters.constructFilterUrl()}`;
            try {
                const response = await this.instanceAuth.get(url);
                return this.followersRemapper.followersFromResponse(response.data);
            } catch (e) {
                const fansUnitedException = this.transformAxiosErrorToFansUnitedException(e);
                console.warn(`There was a problem fetching the followers for own profile`, fansUnitedException);
                throw fansUnitedException.response;
            }
        } else if (!filters && !id) {
            try {
                const response = await this.instanceAuth.get(url);
                return this.followersRemapper.followersFromResponse(response.data);
            } catch (e) {
                const fansUnitedException = this.transformAxiosErrorToFansUnitedException(e);
                console.warn(`There was a problem fetching the followers for own profile`, fansUnitedException);
                throw fansUnitedException.response;
            }
        }

        if (filters && id) {
            url = `${PROFILE_REQUEST}/${id}/followers${this.apiSignInUrl}${filters.constructFilterUrl()}`;
        } else if (!filters && id) {
            url = `${PROFILE_REQUEST}/${id}/followers${this.apiSignInUrl}`;
        }

        try {
            const response = await this.instance.get(url);
            return this.followersRemapper.followersFromResponse(response.data);
        } catch (e) {
            const fansUnitedException = this.transformAxiosErrorToFansUnitedException(e);
            console.warn(`There was a problem fetching the followers for profile: ${id}`, fansUnitedException);
            throw fansUnitedException.response;
        }

    };

    public getFollowing = async (filters?: FollowFilters, id?: string): Promise<PaginationModel> => {
        let url = `${PROFILE_REQUEST}/following${this.apiSignInUrl}`;

        if (filters && !id) {
            url = `${PROFILE_REQUEST}/following${this.apiSignInUrl}${filters.constructFilterUrl()}`;
            try {
                const response = await this.instanceAuth.get(url);
                return this.followingRemapper.followingFromResponse(response.data);
            } catch (e) {
                const fansUnitedException = this.transformAxiosErrorToFansUnitedException(e);
                console.warn(`There was a problem fetching the followers of own profile`, fansUnitedException);
                throw fansUnitedException.response;
            }
        } else if (!filters && !id) {
            try {
                const response = await this.instanceAuth.get(url);
                return this.followingRemapper.followingFromResponse(response.data);
            } catch (e) {
                const fansUnitedException = this.transformAxiosErrorToFansUnitedException(e);
                console.warn(`There was a problem fetching the followers of own profile`, fansUnitedException);
                throw fansUnitedException.response;
            }
        }

        if (filters && id) {
            url = `${PROFILE_REQUEST}/${id}/following${this.apiSignInUrl}${filters.constructFilterUrl()}`;
        } else if (!filters && id) {
            url = `${PROFILE_REQUEST}/${id}/following${this.apiSignInUrl}`;
        }

        try {
            const response = await this.instance.get(url);
            return this.followingRemapper.followingFromResponse(response.data);
        } catch (e) {
            const fansUnitedException = this.transformAxiosErrorToFansUnitedException(e);
            console.warn(`There was a problem fetching the followers of the following profile: ${id}`, fansUnitedException);
            throw fansUnitedException.response;
        }
    };

    public follow = async (profiles: string[]): Promise<FollowingModel[]> => {
        const followIds = new FollowModel();
        followIds.profile_ids = profiles;
        try {
            const response = await this.instanceAuth.post(`${PROFILE_REQUEST}/follow${this.apiSignInUrl}`, JSON.stringify(followIds));
            return this.followingRemapper.followResponse(response.data.data);
        } catch (e) {
            const fansUnitedException = this.transformAxiosErrorToFansUnitedException(e);
            console.warn(`There was a problem with POST request for profiles: ${profiles.join(",")} `, fansUnitedException);
            throw fansUnitedException.response;
        }
    };

    public unfollow = async (profiles: string[]): Promise<boolean> => {
        const unfollowIds = new FollowModel();
        unfollowIds.profile_ids = profiles;
        try {
            await this.instanceAuth.delete(`${PROFILE_REQUEST}/follow${this.apiSignInUrl}`, { data: JSON.stringify(unfollowIds) });
            return true;
        } catch (e) {
            const fansUnitedException = this.transformAxiosErrorToFansUnitedException(e);
            console.warn(`There was a problem with DELETE request for profiles: ${profiles.join(",")} `, fansUnitedException);
            throw fansUnitedException.response;
        }
    };

    public getBadges = async (profileId?: string): Promise<string[]> => {
        let url = `${PROFILE_REQUEST}/badges${this.apiSignInUrl}`;
        try {
            if (profileId) {
                url = `${PROFILE_REQUEST}/${profileId}/badges${this.apiSignInUrl}`;

                const response = await this.instance.get(url);

                return response.data.data;
            }
            const response = await this.instanceAuth.get(url);

            return response.data.data;
        } catch (e) {
            const fansUnitedException = this.transformAxiosErrorToFansUnitedException(e);
            console.warn('There was a problem with fetching the badges', fansUnitedException);
            throw fansUnitedException.response;
        }
    };

    public updateProfile = async (profile: ProfileModel): Promise<ProfileModel> => {
        try {
            const response = await this.instanceAuth.patch(`${PROFILE_REQUEST}${this.apiSignInUrl}`, profile);
            return this.profileRemapper.fullProfileFromResponse(response.data.data);
        } catch (e) {
            const fansUnitedException = this.transformAxiosErrorToFansUnitedException(e);
            console.warn('There was a problem updating the own profile:', fansUnitedException);
            throw fansUnitedException.response;
        }
    };

}
