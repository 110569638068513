import { ErrorHandlingModeType } from "../../../Configurator/Types/ConfiguratorTypes";
import { ErrorCodes } from "../../../Exception/ErrorCodes";
import { ErrorStatuses } from "../../../Exception/ErrorStatuses";
import FansUnitedSdkException from "../../../Exception/FansUnitedSdkException";
import StandardFansUnitedException from "../../../Exception/StandardFansUnitedException";
import {
  ErrorMessages,
  invalidMarketMessage,
} from "../../../Global/Messages/Messages";
import { MarketEnum } from "../../Predictor/Enums/MarketEnum";
import HighestSuccessRateFilters from "../Models/Filters/HighestSuccessRateFilters";

export default class LoyaltyValidator {
  public validate = (
    filters: HighestSuccessRateFilters,
    errorHandlingMode: ErrorHandlingModeType
  ) => {
    if (filters.market) {
      this.validateMarket(filters.market, errorHandlingMode);
    }

    if (filters.fromDate) {
      this.validateDate(filters.fromDate, "fromDate", errorHandlingMode);
    }

    if (filters.toDate) {
      this.validateDate(filters.toDate, "toDate", errorHandlingMode);
    }

    if (filters.usersCount) {
      this.validateNumberFilters(
        filters.usersCount,
        "usersCount",
        errorHandlingMode
      );
    }

    if (filters.minimumPredictions) {
      this.validateNumberFilters(
        filters.minimumPredictions,
        "minimumPredictions",
        errorHandlingMode
      );
    }
  };

  private validateMarket = (
    market: string,
    errorHandlingMode: ErrorHandlingModeType
  ) => {
    const markets = Object.keys(MarketEnum);

    if (!markets.includes(market)) {
      const message = invalidMarketMessage(market);

      if (errorHandlingMode === "default") {
        throw new FansUnitedSdkException(
          ErrorCodes.BAD_METHOD_CALL,
          ErrorStatuses.INVALID_MARKET,
          message
        );
      }

      throw new StandardFansUnitedException(
        ErrorCodes.BAD_METHOD_CALL,
        ErrorStatuses.INVALID_MARKET,
        message
      ).response;
    }
  };

  private validateDate = (
    filter: string,
    field: "fromDate" | "toDate",
    errorHandlingMode: ErrorHandlingModeType
  ) => {
    const regexValidDate = /^\d{4}-\d{2}-\d{2}$/;

    if (typeof filter !== "string") {
      if (errorHandlingMode === "default") {
        throw new FansUnitedSdkException(
          ErrorCodes.BAD_METHOD_CALL,
          ErrorStatuses.INVALID_FILTERS,
          ErrorMessages.INVALID_TYPE + `string for ${field} filter`
        );
      }

      throw new StandardFansUnitedException(
        ErrorCodes.BAD_METHOD_CALL,
        ErrorStatuses.INVALID_FILTERS,
        ErrorMessages.INVALID_TYPE + `string for ${field} filter`
      ).response;
    }

    if (!regexValidDate.test(filter)) {
      if (errorHandlingMode === "default") {
        throw new FansUnitedSdkException(
          ErrorCodes.BAD_METHOD_CALL,
          ErrorStatuses.INVALID_FILTERS,
          ErrorMessages.INVALID_VALUE + `YYYY-MM-DD for ${field}`
        );
      }

      throw new StandardFansUnitedException(
        ErrorCodes.BAD_METHOD_CALL,
        ErrorStatuses.INVALID_FILTERS,
        ErrorMessages.INVALID_VALUE + `YYYY-MM-DD for ${field}`
      ).response;
    }
  };

  private validateNumberFilters = (
    filter: number,
    field: "usersCount" | "minimumPredictions",
    errorHandlingMode: ErrorHandlingModeType
  ) => {
    const minValue = field === "usersCount" ? 1 : 0;
    const maxValue = field === "usersCount" ? 1_000 : 1_000_000;

    if (typeof filter !== "number") {
      if (errorHandlingMode === "default") {
        throw new FansUnitedSdkException(
          ErrorCodes.BAD_METHOD_CALL,
          ErrorStatuses.INVALID_FILTERS,
          ErrorMessages.INVALID_TYPE + `number for ${field} filter`
        );
      }

      throw new StandardFansUnitedException(
        ErrorCodes.BAD_METHOD_CALL,
        ErrorStatuses.INVALID_FILTERS,
        ErrorMessages.INVALID_TYPE + `number for ${field} filter`
      ).response;
    }

    if (filter < minValue || filter > maxValue) {
      if (errorHandlingMode === "default") {
        throw new FansUnitedSdkException(
          ErrorCodes.BAD_METHOD_CALL,
          ErrorStatuses.INVALID_FILTERS,
          ErrorMessages.INVALID_VALUE + `between ${minValue} and ${maxValue}`
        );
      }

      throw new StandardFansUnitedException(
        ErrorCodes.BAD_METHOD_CALL,
        ErrorStatuses.INVALID_FILTERS,
        ErrorMessages.INVALID_VALUE + `between ${minValue} and ${maxValue}`
      ).response;
    }
  };
}
