import { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";

export const axiosError = (
  config: AxiosRequestConfig,
  code: number,
  message: string
) => {
  const response: AxiosResponse = {
    data: config.data,
    status: code,
    statusText: message,
    headers: config.headers,
    config,
  };
  const customError: AxiosError = {
    code: String(code),
    config,
    isAxiosError: true,
    toJSON: () => {
      return {};
    },
    name: "Unauthorized",
    message,
    response,
  };

  return customError;
};
