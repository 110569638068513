import ProfileHttps from "../../Https/ProfileHttps";
import IdMappingService from "../../IdMapping/IdMappingService";
import ProfileFacade from "./Facade/ProfileFacade";
import LocalStorage from "../../Global/LocalStorage";
import OwnProfileBuilder from "./Builders/OwnProfileBuilder";
import ProfileBuilder from "./Builders/ProfileBuilder";
import SDKConfigurationModel from "../../Configurator/Models/SDKConfiguraitonModel";
import FootballFacade from "../Football/Facades/FootballFacade";
import FootballHttps from "../../Https/FootballHttps";
import ActivityHttps from "../../Https/ActivityHttps";
import SDKIdsRemapper from "../../Remapper/SDKIdsRemapper";

export default class Profile {
    private idsRemapper: SDKIdsRemapper = null;
    private profileHttps: ProfileHttps = null;
    private activityHttps: ActivityHttps = null;
    private footballHttps: FootballHttps = null;
    private footballFacade: FootballFacade = null;
    private idMapping: IdMappingService = null;
    private localStorage: LocalStorage = null;
    private profileFacade: ProfileFacade = null;
    private ownProfileBuilder: OwnProfileBuilder = null;
    private profileBuilder: ProfileBuilder = null;

    constructor(config: SDKConfigurationModel) {
        this.profileHttps = new ProfileHttps(config);
        this.activityHttps = new ActivityHttps(config);
        this.footballHttps = new FootballHttps(config);
        this.idMapping = new IdMappingService(config);
        this.localStorage = new LocalStorage(config);
        this.footballFacade = new FootballFacade(config, this.localStorage, this.footballHttps, this.idMapping);
        this.profileFacade = new ProfileFacade(this.localStorage, this.profileHttps, this.activityHttps);
        this.idsRemapper = new SDKIdsRemapper(config, this.footballFacade);
        this.ownProfileBuilder = new OwnProfileBuilder(this.idsRemapper, this.localStorage, this.profileHttps, this.activityHttps, config.errorHandlingMode);
        this.profileBuilder = new ProfileBuilder(this.idsRemapper, this.localStorage, this.profileHttps, this.activityHttps);
    }

    public getOwn = () => {
        return this.ownProfileBuilder;
    };

    public getById = (id:string) => {
        return this.profileBuilder.setId(id);
    };

    public getCountries = async (disableCache?: boolean) => {
        return await this.profileFacade.getCountries(disableCache);
    };

    public getByIds = async (profileIds: string[], disableCache?: boolean) => {
        return await this.profileFacade.getByIds(profileIds, disableCache);
    };

}