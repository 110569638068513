import FootballPaginationModel from "../../../Football/Models/Pagination/FootballPaginationModel";
import MetaFootballModel from "../../../Football/Models/Pagination/MetaFootballModel";
import RankingsModel from "./RankingsModel";

export default class RankingsRemapper {
    public remapRankings = (response: any): FootballPaginationModel => {
        response.meta = this.remapMeta(response.meta);
        response.data = this.remapData(response.data);

        return response;
    };

    private remapMeta = (meta: any): MetaFootballModel => {
        const rankingsMeta = new MetaFootballModel();

        rankingsMeta.pagination.currentPage = meta.pagination.current_page;
        rankingsMeta.pagination.itemsPerPage = meta.pagination.items_per_page;
        rankingsMeta.pagination.numberOfPages = meta.pagination.number_of_pages;
        rankingsMeta.pagination.totalItems = meta.pagination.total_items;

        return rankingsMeta;
    };

    private remapData (responseData: any[]): RankingsModel[] {
        let rankingsModel: RankingsModel[] = [];

        responseData.forEach((rankings: any) => {
            const dataModel = new RankingsModel();

            dataModel.id = rankings.id;
            dataModel.rankType = rankings.rank_type;
            dataModel.position = rankings.position;
            dataModel.points = rankings.points;
            dataModel.gameType = rankings.game_type;
            delete dataModel.profileId;
            delete dataModel.profileModel;
            delete dataModel.predictionsMade;

            rankingsModel.push(dataModel);
        });

        return rankingsModel;
    };
}