import { EnvironmentEnum } from '../Configurator/Enums/EnvironmentEnum';

export const requestChunkBuild = (ids: string[]) => {
    let maxLength: number = 200;
    const chunks: any = [];
    let chunkIndex = 0;
    chunks[chunkIndex] = [];

    ids.forEach((id: string) => {
        if (chunks[chunkIndex].length <= maxLength - 1) {
            chunks[chunkIndex].push(id);
        } else {
            chunkIndex++;
            chunks[chunkIndex] = [id];
        }
    })

    return chunks;
};

export const limitIds = (ids: string[], limit: number) => {
    let result: any = [];

    while (ids.length > limit) {
        const maxIds = ids.splice(0, limit);
        result.push(maxIds);
    }

    result.push(ids);

    return result
};

export const setBaseUrl = (environment: string, namespace: string) => {
    let baseUrl = "";

    const PROD_API_URLS: {[key: string]: string} = {
        profile: "https://profile.fansunitedapi.com/v1",
        football: "https://football.fansunitedapi.com/v1",
        idMapping: "https://id-mapping.fansunitedapi.com/v1",
        predictor: "https://prediction.fansunitedapi.com/v1",
        client: "https://client.fansunitedapi.com/v1",
        loyalty: "https://loyalty.fansunitedapi.com/v1",
    };

    const DEV_API_URLS: {[key: string]: string} = {
        profile: "http://localhost:8010/v1",
        football: "http://localhost:8080/v1",
        idMapping: "http://localhost:8030/v1",
        predictor: "http://localhost:8040/v1",
        client: "http://localhost:8070/v1",
        loyalty: "http://localhost:8060/v1"
    };

    const STAGING_API_URLS: {[key: string]: string} = {
        profile: "https://profile.fansunitedstagingapi.com/v1",
        football: "https://football.fansunitedstagingapi.com/v1",
        idMapping: "https://id-mapping.fansunitedstagingapi.com/v1",
        predictor: "https://prediction.fansunitedstagingapi.com/v1",
        client: "https://client.fansunitedstagingapi.com/v1",
        loyalty: "https://loyalty.fansunitedstagingapi.com/v1",
    };


    if (environment === EnvironmentEnum.PROD) {
        baseUrl = PROD_API_URLS[namespace];
    } else if (environment === EnvironmentEnum.DEV) {
        baseUrl = DEV_API_URLS[namespace];
    } else {
        baseUrl = STAGING_API_URLS[namespace];
    }

    return baseUrl;
};

export const transformIdsToBeUnique = (ids: string[]) => {
    const uniqueIds = ids.filter((elem: string, index: number, self: any) => {
        return index === self.indexOf(elem);
    });

    return uniqueIds;
};

export const isDisabledCache = (disableCache: boolean): boolean => disableCache ? disableCache : false;

export const initDisableCache = () => `&disable_cache=${Date.now()}`;

export const sortArrayInAscOrder = (array: string[]) => JSON.parse(JSON.stringify(array.sort((a: string, b: string) => a.localeCompare(b))));

/**
 * Used for test cases when we want an array with more than X length
 * @param value The value that will contain in the array
 * @param length The length of the array
 * @returns New array with provided length and values
 */

export const constructStringArrayWithProvidedLength = (value: string, length: number) => {
    let array: string[] = [];

    for (let i = 0; i < length; i++) {
        array[i] = value;
    }

    return array;
}
