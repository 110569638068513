import IValidator from "./IValidator";
import {ValidationResponseEnum} from "../Enums/ValidationResponseEnum";
import {ValidationMessageEnum} from "../Enums/ValidationMessageEnum";
import {LogEnum} from "../Enums/LogEnum";
import {EnvironmentEnum} from "../Enums/EnvironmentEnum";
import MessageLogModel from "../Models/MessageLogModel";
import SDKConfigurationModel from "../Models/SDKConfiguraitonModel";
import ValidationItemModel from "../Models/ValidationItemModel";

export default class EnvironmentValidator implements IValidator {

    validate(arg: SDKConfigurationModel): ValidationItemModel {
        const validation: ValidationResponseEnum = this.validateField(arg.environment);
        const message: MessageLogModel = this.generateMessage(validation);

        return {validation, message};
    }

    private validateField = (environment: string): ValidationResponseEnum => {
        const environmentValues = [EnvironmentEnum.DEV, EnvironmentEnum.STAGING, EnvironmentEnum.PROD];
        let validation: ValidationResponseEnum = null;

        if (environment !== undefined && environment !== null && environment.length > 0) {
            if (environmentValues.includes(environment as EnvironmentEnum)) {
                validation = ValidationResponseEnum.VALID_VALUE;
            } else {
                validation = ValidationResponseEnum.INVALID_VALUE;
            }
        } else {
            validation = ValidationResponseEnum.MISSING_VALUE;
        }

        return validation;
    };

    private generateMessage = (validation: ValidationResponseEnum): MessageLogModel => {
        let text: ValidationMessageEnum = null;
        let type: LogEnum = LogEnum.WARNING;

        if (validation === ValidationResponseEnum.MISSING_VALUE) {
            text = ValidationMessageEnum.ENVIRONMENT_MISSING;
        }
        if (validation === ValidationResponseEnum.INVALID_VALUE) {
            text = ValidationMessageEnum.ENVIRONMENT_INVALID;
        }
        if (validation === ValidationResponseEnum.VALID_VALUE) {
            text = null;
            type = null;
        }


        return {text: text, type: type};
    };

}