import { IdSchemaEnum } from "../../../Configurator/Enums/IdSchemaEnum";
import SDKConfigurationModel from "../../../Configurator/Models/SDKConfiguraitonModel";
import { ErrorCodes } from "../../../Exception/ErrorCodes";
import { ErrorStatuses } from "../../../Exception/ErrorStatuses";
import FansUnitedSdkException from "../../../Exception/FansUnitedSdkException";
import StandardFansUnitedException from "../../../Exception/StandardFansUnitedException";
import { ErrorMessages } from "../../../Global/Messages/Messages";
import IdMappingService from "../../../IdMapping/IdMappingService";
import FootballFacade from "../../Football/Facades/FootballFacade";
import Football from "../../Football/Football";
import CompetitionBasicModel from "../../Football/Models/Competition/CompetitionBasicModel";
import CompetitionFilters from "../../Football/Models/Competition/CompetitionFilters";
import FootballPaginationModel from "../../Football/Models/Pagination/FootballPaginationModel";
import PlayerBasicModel from "../../Football/Models/Player/PlayerBasicModel";
import PlayerFilters from "../../Football/Models/Player/PlayerFilters";
import TeamBasicModel from "../../Football/Models/Team/TeamBasicModel";
import TeamFilters from "../../Football/Models/Team/TeamFilters";
import ProfileModel from "../../Profile/Models/ProfileModel";
import Profile from "../../Profile/Profile";
import GamesListModel from "../../TopX/Models/Games/GamesListModel";
import ContestWinners from "../../TopX/Models/Games/Winners/ContestWinners";
import UserListWinners from "../../TopX/Models/Games/Winners/UserListWinners";
import BadgesModel from "../Models/Badges/BadgesModel";
import BadgesValue from "../Models/Badges/BadgesValue";
import ClientBadges from "../Models/Badges/ClientBadges";
import EntitiesFollows, {
  EntitiesFollowsBreakdown,
} from "../Models/EntitiesFollows/EntitiesFollows";
import EntitiesFollowsFilters from "../Models/Filters/EntitiesFollowsFilters";
import LoyaltyFilters from "../Models/Filters/LoyaltyFilters";
import RankingsFilters from "../Models/Filters/RankingsFilters";
import HighestSuccessRateModel from "../Models/HighestSuccessRate/HighestSuccessRateModel";
import LeaderboardModel from "../Models/Leaderboard/LeaderboardModel";
import RankingsModel from "../Models/Rankings/RankingsModel";
import TemplateByIdModel from "../Models/Template/TemplateByIdModel";
import TemplateModel from "../Models/Template/TemplateModel";
import { FiltersType } from "../Types/LoyaltyTypes";
export default class LoyaltyService {
  private idMappingService: IdMappingService = null;
  private profileNamespace: Profile = null;
  private footballNamespace: Football = null;
  private errorHandlingMode: string = null;

  constructor(config: SDKConfigurationModel) {
    this.idMappingService = new IdMappingService(config);
    this.profileNamespace = new Profile(config);
    this.footballNamespace = new Football(config);
    this.errorHandlingMode = config.errorHandlingMode;
  }

  public remapTemplatesIds = async (
    templates: TemplateModel[]
  ): Promise<TemplateModel[] | TemplateByIdModel[]> => {
    if (templates.length > 0) {
      let teamIds: string[] = [];
      let remappedTeamIds: string[] = [];

      let matchIds: string[] = [];
      let remappedMatchIds: string[] = [];

      let competitionIds: string[] = [];
      let remappedCompetitionIds: string[] = [];

      templates.forEach((template: TemplateModel) => {
        if (template.teamIds && template.teamIds.length > 0) {
          teamIds = [...teamIds, ...template.teamIds];
        } else if (template.matchIds && template.matchIds.length > 0) {
          matchIds = [...matchIds, ...template.matchIds];
        } else if (
          template.competitionIds &&
          template.competitionIds.length > 0
        ) {
          competitionIds = [...competitionIds, ...template.competitionIds];
        }
      });

      const entitiesToRemap = {
        team: teamIds,
        match: matchIds,
        competition: competitionIds,
      };

      const response =
        await this.idMappingService.idMappingFacade.getEntitiesByIds(
          entitiesToRemap,
          "native",
          this.idMappingService.idSchema
        );

      remappedTeamIds = [...response.team];
      remappedMatchIds = [...response.match];
      remappedCompetitionIds = [...response.competition];

      templates.forEach((template: TemplateModel) => {
        if (template.teamIds && template.teamIds.length > 0) {
          template.teamIds = template.teamIds.map(
            (id: string) => (id = response.team.shift())
          );
        } else if (template.matchIds && template.matchIds.length > 0) {
          template.matchIds = template.matchIds.map(
            (id: string) => (id = response.match.shift())
          );
        } else if (
          template.competitionIds &&
          template.competitionIds.length > 0
        ) {
          template.competitionIds = template.competitionIds.map(
            (id: string) => (id = response.competition.shift())
          );
        }
      });

      return templates;
    }

    return templates;
  };

  public addProfileModelToLeaderboad = async (
    leaderboard: any[]
  ): Promise<LeaderboardModel[]> => {
    if (leaderboard.length > 0) {
      let newLeaderboard: LeaderboardModel[] = [];

      const profileIds = leaderboard.map(
        (leaderboard: LeaderboardModel) => leaderboard.profileId
      );

      if (!profileIds.length) return leaderboard;

      const profiles = await this.profileNamespace.getByIds(profileIds);

      leaderboard.forEach((leaderboard: LeaderboardModel) => {
        profiles.forEach((profile: ProfileModel) => {
          if (profile.id === leaderboard.profileId) {
            delete profile.interests;
            leaderboard.profileModel = profile;
          }
        });

        if (!leaderboard.profileModel) {
          leaderboard.profileModel = null;
        }

        newLeaderboard.push(leaderboard);
      });

      return newLeaderboard;
    }

    return leaderboard;
  };

  public buildClientBadges = (badges: BadgesModel): ClientBadges => {
    let clientBadges: ClientBadges = new ClientBadges();

    clientBadges.general = this.extractEnabledBadgesId(badges.general);
    clientBadges.predictor = this.extractEnabledBadgesId(badges.predictor);
    clientBadges.topX = this.extractEnabledBadgesId(badges.topX);
    clientBadges.matchQuiz = this.extractEnabledBadgesId(badges.matchQuiz);

    return clientBadges;
  };

  public initFilters = (filters: any, type: FiltersType) => {
    switch (type) {
      case "loyalty":
        if (filters) {
          filters = new LoyaltyFilters(filters);
        }
        break;
      case "rankings":
        if (filters) {
          filters = new RankingsFilters(filters);
        }
        break;
    }

    return filters;
  };

  public addProfileModelToHighestSuccessRate = async (
    highestSuccessRate: HighestSuccessRateModel[]
  ): Promise<HighestSuccessRateModel[]> => {
    const profileIds = highestSuccessRate.map(
      (value: HighestSuccessRateModel) => value.profileId
    );

    if (!profileIds.length) return highestSuccessRate;

    const profiles = await this.profileNamespace.getByIds(profileIds);

    return highestSuccessRate.map((value: HighestSuccessRateModel) => {
      profiles.forEach((profile: ProfileModel) => {
        if (profile.id === value.profileId) {
          delete profile.interests;
          value.profileModel = profile;
        }
      });

      return value;
    });
  };

  public extractRankingsIdsMap = (rankings: RankingsModel[]) => {
    let topXGameIds: string[] = [];
    let matchQuizGameIds: string[] = [];
    let templateIds: string[] = [];
    rankings.forEach((value: RankingsModel) => {
      if (value.rankType === "GAME" && value.gameType === "TOP_X")
        topXGameIds.push(value.id);
      else if (value.rankType === "GAME" && value.gameType === "MATCH_QUIZ")
        matchQuizGameIds.push(value.id);
      else if (value.rankType === "TEMPLATE") templateIds.push(value.id);
    });

    return [topXGameIds, matchQuizGameIds, templateIds];
  };

  public setModelsForUserRankings = (
    topXGames: GamesListModel[],
    matchQuizGames: GamesListModel[],
    templates: TemplateModel[],
    userRankings: FootballPaginationModel
  ): FootballPaginationModel => {
    userRankings.data.forEach((userRanking: RankingsModel) => {
      if (userRanking.gameType === "MATCH_QUIZ" && matchQuizGames) {
        userRanking.model = matchQuizGames.filter(
          (game: GamesListModel) => game.id === userRanking.id
        )[0];
      } else if (userRanking.gameType === "TOP_X" && topXGames) {
        userRanking.model = topXGames.filter(
          (game: GamesListModel) => game.id === userRanking.id
        )[0];
      } else if (userRanking.rankType === "TEMPLATE" && templates) {
        userRanking.model = templates.filter(
          (template: TemplateModel) => template.id === userRanking.id
        )[0];
      }
    });

    return userRankings;
  };

  public completeContestWinners = async (
    contestWinners: ContestWinners
  ): Promise<ContestWinners> => {
    const profileIds = contestWinners.userList.map(
      (value: UserListWinners) => value.profileId
    );
    const profiles = await this.profileNamespace.getByIds(profileIds);
    contestWinners.userList.forEach((userWinner: UserListWinners) => {
      const profileModel = profiles.filter(
        (profileModel: ProfileModel) => profileModel.id === userWinner.profileId
      )[0];
      delete profileModel.interests;
      userWinner.profileModel = profileModel;
    });

    return contestWinners;
  };

  public validateEntitiesFollowsFilters = (filters: EntitiesFollowsFilters) => {
    const maxLimitOfEntities = 24;

    if (
      !filters ||
      ((!filters.footballCompetitions ||
        !filters.footballCompetitions.length) &&
        (!filters.footballTeams || !filters.footballTeams.length) &&
        (!filters.footballPlayers || !filters.footballPlayers.length))
    ) {
      if (this.errorHandlingMode === "default") {
        throw new FansUnitedSdkException(
          ErrorCodes.BAD_METHOD_CALL,
          ErrorStatuses.INVALID_FILTERS,
          ErrorMessages.ENTITIES_NEEDED
        );
      }

      throw new StandardFansUnitedException(
        ErrorCodes.BAD_METHOD_CALL,
        ErrorStatuses.INVALID_FILTERS,
        ErrorMessages.ENTITIES_NEEDED
      ).response;
    }

    if (
      (filters.footballCompetitions &&
        filters.footballCompetitions.length > maxLimitOfEntities) ||
      (filters.footballTeams &&
        filters.footballTeams.length > maxLimitOfEntities) ||
      (filters.footballPlayers &&
        filters.footballPlayers.length > maxLimitOfEntities)
    ) {
      if (this.errorHandlingMode === "default") {
        throw new FansUnitedSdkException(
          ErrorCodes.BAD_METHOD_CALL,
          ErrorStatuses.EXCEEDED_LENGTH,
          ErrorMessages.ENTITIES_FOLLOWS_FILTERS_EXCEEDED
        );
      }

      throw new StandardFansUnitedException(
        ErrorCodes.BAD_METHOD_CALL,
        ErrorStatuses.EXCEEDED_LENGTH,
        ErrorMessages.ENTITIES_FOLLOWS_FILTERS_EXCEEDED
      ).response;
    }
  };

  public remapEntitiesFollowsFilters = async (
    filters: EntitiesFollowsFilters
  ) => {
    const copyFilters = JSON.parse(
      JSON.stringify(filters)
    ) as EntitiesFollowsFilters;
    const remappedFitlers: any = {};
    const objIds: any = {};

    if (
      copyFilters.footballCompetitions &&
      copyFilters.footballCompetitions.length
    ) {
      objIds.competition = copyFilters.footballCompetitions;
    }

    if (copyFilters.footballTeams && copyFilters.footballTeams.length) {
      objIds.team = copyFilters.footballTeams;
    }

    if (
      copyFilters.footballPlayers &&
      copyFilters.footballPlayers.length
    ) {
      objIds.player = copyFilters.footballPlayers;
    }

    const nativeIdsObj =
      await this.idMappingService.idMappingFacade.getEntitiesByIds(
        objIds,
        this.idMappingService.idSchema,
        IdSchemaEnum.NATIVE
      );

    Object.keys(nativeIdsObj).forEach((entity: string) => {
      if (entity === "competition") {
        remappedFitlers.footballCompetitions = nativeIdsObj[entity];
      } else if (entity === "team") {
        remappedFitlers.footballTeams = nativeIdsObj[entity];
      } else if (entity === "player") {
        remappedFitlers.footballPlayers = nativeIdsObj[entity];
      }
    });

    return remappedFitlers;
  };

  public addModelsToEntitiesFollows = async (
    entitiesFollows: EntitiesFollows
  ) => {
    const competitionIds: string[] = [];
    const competitionIdStandard = "fb:c:";
    let competitionsMap = new Map<string, CompetitionBasicModel>();

    const teamIds: string[] = [];
    const teamIdStandard = "fb:t:";
    let teamsMap = new Map<string, TeamBasicModel>();

    const playerIds: string[] = [];
    const playerIdStandard = "fb:p:";
    let playersMap: any = null;

    // Extract entity ids
    entitiesFollows.breakdown.forEach((value: EntitiesFollowsBreakdown) => {
      if (value.id.includes(competitionIdStandard)) {
        competitionIds.push(value.id);
      } else if (value.id.includes(teamIdStandard)) {
        teamIds.push(value.id);
      } else if (value.id.includes(playerIdStandard)) {
        playerIds.push(value.id);
      }
    });

    if (competitionIds.length) {
      competitionsMap =
        await this.footballNamespace.footballFacade.competitionsFacade.getCompetitionsMapWithNativeIds(
          competitionIds
        );
    }

    if (teamIds.length) {
      teamsMap =
        await this.footballNamespace.footballFacade.teamsFacade.getTeamsMapWithNativeIds(
          teamIds
        );
    }

    if (playerIds.length) {
      playersMap =
        await this.footballNamespace.footballFacade.playerFacade.getPlayersMapWithNativeIds(
          playerIds
        );
    }

    // Set new remapped entity ids
    entitiesFollows.breakdown.forEach((value: EntitiesFollowsBreakdown) => {
      if (value.id.includes(competitionIdStandard)) {
        value.model = competitionsMap.get(value.id);
        value.id = value.model.id;
      } else if (value.id.includes(teamIdStandard)) {
        value.model = teamsMap.get(value.id);
        value.id = value.model.id;
      } else if (value.id.includes(playerIdStandard)) {
        value.model = playersMap[value.id];
        value.id = value.model.id;
      }
    });

    return entitiesFollows;
  };

  private extractEnabledBadgesId = (badges: BadgesValue[]): string[] => {
    let badgesIds: string[] = [];

    badges.forEach((badgeValue: BadgesValue) => {
      if (badgeValue.enabled) {
        badgesIds.push(badgeValue.id);
      }
    });

    return badgesIds;
  };
}
