import RequirementModel from "./RequirementModel";

type BadgeAssets = {
  mainImageUrl: string;
};
export default class BadgesValue {
  public id: string;
  public label: string;
  public assets: BadgeAssets = { mainImageUrl: "" };
  public enabled: boolean;
  public requirements: RequirementModel = new RequirementModel();
}
