import ImagesModel from "../../../../Global/Models/Images/ImagesModel";
import RelatedEntity from "../../../../Global/Models/Related/RelatedEntity";
import { LeaderboardStatus } from "../../Types/LoyaltyTypes";

export default class TemplateModel {
    public id: string = null;
    public name: string = null;
    public description: string = null;
    public type: string = null;
    public fromDate: string = null;
    public toDate: string = null;
    public markets: string[] = [];
    public teamIds: string[] = [];
    public matchIds: string[] = [];
    public gameIds: string[] = [];
    public gameTypes: string[] = [];
    public competitionIds: string[] = [];
    public excludedProfileIds: string[] = [];
    public rules: string = null;
    public flags: string[] = null;
    public images: ImagesModel = new ImagesModel();
    public status: LeaderboardStatus  = null;
    public automaticallyChangeStatus: boolean = true;
    public createdAt: string = null;
    public updatedAt: string = null;
    public related: RelatedEntity[] = null;
}