import LocalStorage from "../../../Global/LocalStorage";
import FootballHttps from "../../../Https/FootballHttps";
import IdMappingService from "../../../IdMapping/IdMappingService";
import CompetitionBasicModel from "../Models/Competition/CompetitionBasicModel";
import CompetitionFullModel from "../Models/Competition/CompetitionFullModel";
import CompetitionFilters from "../Models/Competition/CompetitionFilters";
import { sortArrayInAscOrder } from "../../../Global/Helper";

export default class CompetitionsFacade {
    private isBrowser:boolean = false;
    private localStorage: LocalStorage = {} as LocalStorage;
    private https: FootballHttps = null;
    private idMapping: IdMappingService = null;

    constructor(localStorage: LocalStorage, https: FootballHttps, idMapping: IdMappingService) {
        this.isBrowser = typeof window !== 'undefined';
        this.localStorage = localStorage;
        this.idMapping = idMapping;
        this.https = https;
    }


    public getCompetitionsIdMapping = async (filters?: CompetitionFilters, disableCache?: boolean): Promise<CompetitionBasicModel[]> => {
        try {
            let isEmpty = false;
            if (filters) {
                filters = new CompetitionFilters(filters);
                if (filters.countryId) {
                    const countryId = await this.idMapping.getEntityIdsBySchemaId([`${filters.countryId}`], "country", "native");
                    if (countryId.country.length === 0) {
                        isEmpty = true;
                    }
                    filters.countryId = countryId.country[0];
                }
                if (filters.competitionIds) {
                    const competitionId = await this.idMapping.getEntityIdsBySchemaId(filters.competitionIds, "competition", "native");
                    if (competitionId.competition.length === 0) {
                        isEmpty = true;
                    }
                    filters.competitionIds = competitionId.competition;
                }
                if (isEmpty) {
                    return [];
                }
            }

            const competitions = await this.getCompetitions(filters, disableCache);
            return await this.idMapping.remapEntities(competitions, 'competition');
        } catch (e) {
            console.warn('There was a problem browsing all of the competitions', e);
            throw e;
        }
    };

    public getCompetitionsMapWithNativeIds = async (competitionIds: string[]): Promise<Map<string, CompetitionBasicModel>> => {
        let competitionsMap = new Map<string, CompetitionBasicModel>();
        const limit = competitionIds.length;
        const filters = new CompetitionFilters({ competitionIds, limit });
        const competitions = await this.getCompetitions(filters);
        competitions.sort((a: CompetitionBasicModel, b: CompetitionBasicModel) => a.id.localeCompare(b.id));
        const sortedCompetitionIds = sortArrayInAscOrder(competitionIds);
        const remappedCompetitions = await this.idMapping.remapEntities(competitions, "competition");

        sortedCompetitionIds.forEach((id: string, idx: number) => competitionsMap.set(id, remappedCompetitions[idx]));

        return competitionsMap;
    };

    private getCompetitions = async (filters?: CompetitionFilters, disableCache?: boolean): Promise<CompetitionBasicModel[]> => {
        let competitions: CompetitionBasicModel[] = null;
        disableCache = !disableCache ? false : disableCache;

        /*
         If method is called for the first time, we will fetch all competitions from Football API and store them in Local Storage
         From there we will apply the provided filters from client. That way we avoid returning incorrect competitions from cache.
         IDEA: Apply some sort of refreshing (update) logic (e.g. every month fetch the competitions from API even if we have them in Local Storage).
         */
        if (this.isBrowser) {
            if (!this.localStorage.getCompetitionsLength()) {
                try {
                    competitions = await this.https.getCompetitions();
                } catch (e: any) {
                    console.warn('There was a problem retrieving all competitions', e);
                    throw e;
                }
                this.localStorage.setCompetitions(competitions);
            }
            return this.localStorage.getCompetitions(filters);
        } else {
            return this.https.getCompetitions(filters, disableCache);
        }
    };

    public getCompetitionByIdIdMapping = async (id: string, disableCache?: boolean): Promise<CompetitionFullModel> => {
        try {
            const idObj = await this.idMapping.getEntityIdsBySchemaId([id], "competition", "native");
            const nativeId = idObj.competition[0];
            const competition = await this.getCompetitionById(nativeId, disableCache);
            return await this.idMapping.remapEntities(competition, 'competition');
        } catch (e) {
            console.warn(`There was a problem browsing the following competition:${id}`, e);
            throw e;
        }
    };

    private getCompetitionById = async (id: string, disableCache?: boolean): Promise<CompetitionFullModel> => {
        try {
            disableCache = !disableCache ? false : disableCache;

            return await this.https.getCompetitionById(id, disableCache);
        } catch (e) {
            console.warn(`There was a problem retrieving the competition with the following id: ${id}`, e);
            throw e;
        }
    };

    public getTopCompetitionsIdMapping = async (disableCache?: boolean): Promise<CompetitionBasicModel[]> => {
        try {
            const topCompetitions = await this.getTopCompetitions(disableCache);
            return await this.idMapping.remapEntities(topCompetitions, 'competition');
        } catch (e) {
            console.warn('There was a problem browsing the top competitions', e);
            throw e;
        }
    };

    private getTopCompetitions = async (disableCache?: boolean): Promise<CompetitionBasicModel[]> => {
        try {
            disableCache = !disableCache ? false : disableCache;

            return await this.https.getTopCompetitions(disableCache);
        } catch (e) {
            console.warn('There was a problem retrieving the top competitions', e);
            throw e;
        }
    };
}
