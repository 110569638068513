import SDKConfigurationModel from "../../Configurator/Models/SDKConfiguraitonModel";
import PaginationModel from "../../Global/Models/Pagination/PaginationModel";
import ClientHttps from "../../Https/ClientHttps";
import LoyaltyHttps from "../../Https/LoyaltyHttps";
import LoyaltyFacade from "./Facade/LoyaltyFacade";
import LoyaltyFilters from "./Models/Filters/LoyaltyFilters";
import ClientBadges from "./Models/Badges/ClientBadges";
import { FeaturesConfigModels } from "../../Global/Types/GlobalTypes";
import RankingsFilters from "./Models/Filters/RankingsFilters";
import ProfileHttps from "../../Https/ProfileHttps";
import LocalStorage from "../../Global/LocalStorage";
import HighestSuccessRateModel from "./Models/HighestSuccessRate/HighestSuccessRateModel";
import ContestWinners from "../TopX/Models/Games/Winners/ContestWinners";
import EntitiesFollowsFilters from "./Models/Filters/EntitiesFollowsFilters";
import EntitiesFollows from "./Models/EntitiesFollows/EntitiesFollows";
import TemplateByIdModel from "./Models/Template/TemplateByIdModel";
import HighestSuccessRateFilters from "./Models/Filters/HighestSuccessRateFilters";

export default class Loyalty {
    private clientHttps: ClientHttps = null;
    private profileHttps: ProfileHttps = null;
    private loyaltyHttps: LoyaltyHttps = null;
    private loyaltyFacade: LoyaltyFacade = null;
    private localStorage: LocalStorage = null;

    constructor(config: SDKConfigurationModel) {
        this.clientHttps = new ClientHttps(config);
        this.profileHttps = new ProfileHttps(config);
        this.loyaltyHttps = new LoyaltyHttps(config);
        this.localStorage = new LocalStorage(config);
        this.loyaltyFacade = new LoyaltyFacade(config, this.clientHttps, this.loyaltyHttps, this.profileHttps, this.localStorage);
    }

    public getConfig = async (): Promise<FeaturesConfigModels> => {
        return await this.loyaltyFacade.getConfig();
    };

    public getClientBadges = async (): Promise<ClientBadges> => {
        return await this.loyaltyFacade.getClientBadges();
    };

    public getTemplates = async (disableCache?: boolean): Promise<PaginationModel> => {
        return await this.loyaltyFacade.getTemplates(disableCache);
    };

    public getTemplateById = async (templateId: string, disableCache? : boolean): Promise<TemplateByIdModel> => {
        return await this.loyaltyFacade.getTemplateById(templateId, disableCache);
    };

    public getLeaderboard = async (templateId: string, filters?: LoyaltyFilters, disableCache?: boolean): Promise<PaginationModel> => {
        return await this.loyaltyFacade.getLeaderboard(templateId, filters, disableCache);
    };

    public getLeaderboardForGame = async (gameId: string, filters?: LoyaltyFilters, disableCache?: boolean): Promise<PaginationModel> => {
        return await this.loyaltyFacade.getLeaderboardForGame(gameId, filters, disableCache);
    };

    public getOwnRankings = async (filters?: RankingsFilters, disableCache?: boolean) => {
        return await this.loyaltyFacade.getOwnRankings(filters, disableCache);
    };

    public getUserRankings = async (userId: string, filters?: RankingsFilters, disableCache?: boolean) => {
        return await this.loyaltyFacade.getUserRankings(userId, filters, disableCache);
    };

    public getHighestSuccessRate = async (filters?: HighestSuccessRateFilters, disableCache?: boolean): Promise<HighestSuccessRateModel[]> => {
        return await this.loyaltyFacade.getHighestSuccessRate(filters, disableCache);
    };

    public getTemplateWinners = async (templateId: string): Promise<ContestWinners> => {
        return await this.loyaltyFacade.getTemplateWinners(templateId);
    };

    public getEntitiesFollows = async (filters: EntitiesFollowsFilters, disableCache?: boolean): Promise<EntitiesFollows> => {
        return await this.loyaltyFacade.getEntitiesFollows(filters, disableCache);
    };
}