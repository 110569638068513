import { IdSchemaEnum } from "../../../Configurator/Enums/IdSchemaEnum";
import SDKConfigurationModel from "../../../Configurator/Models/SDKConfiguraitonModel";
import { IdSchemaType } from "../../../Configurator/Types/ConfiguratorTypes";
import ActivityHttps from "../../../Https/ActivityHttps";
import { ActionsEnum } from "../Enums/ActionsEnum";
import ActivityResponseBody from "../Models/ActivityResponseBody";
import PaginationActivities from "../Models/PaginationActivities";
import CampaignModel from "../Models/RequestBody/Context/CampaignModel";
import ContentModel from "../Models/RequestBody/Context/ContentModel";
import TagsModel from "../Models/RequestBody/Context/TagsModel";
import CreateActivityRequestBody from "../Models/RequestBody/CreateActivityRequestBody";
import ActivityService from "../Service/ActivityService";
import OwnActivityFilters from "../Models/Filters/OwnActivityFilters";
import UserActivityFilters from "../Models/Filters/UserActivityFilters";

export default class ActivityFacade {
    private idSchema: IdSchemaType;
    private activityHttps: ActivityHttps;
    private activityService: ActivityService;

    constructor(config: SDKConfigurationModel) {
        this.idSchema = config.idSchema;
        this.activityHttps = new ActivityHttps(config);
        this.activityService = new ActivityService(config);
    }

    public add = async (action: ActionsEnum, tags: TagsModel[], content?: ContentModel, campaign?: CampaignModel): Promise<ActivityResponseBody> => {
        if (this.idSchema != IdSchemaEnum.NATIVE) {
            const idSchemaTags = JSON.parse(JSON.stringify(tags));
            const nativeIdsTags = await this.activityService.remapTagsIds(tags, this.idSchema, IdSchemaEnum.NATIVE);

            const requestBody = new CreateActivityRequestBody(action, nativeIdsTags, content, campaign);

            const response = await this.activityHttps.createActivity(requestBody);
            response.context.tags = idSchemaTags;

            return response;
        }
        const requestBody = new CreateActivityRequestBody(action, tags, content, campaign);

        return this.activityHttps.createActivity(requestBody);

    }

    public delete = async (activityId: string): Promise<boolean> => {
        return await this.activityHttps.deleteActivity(activityId);
    }

    public like = async (tags: TagsModel[], content?: ContentModel, campaign?: CampaignModel): Promise<ActivityResponseBody> => {
        const action = ActionsEnum.LIKE;

        return await this.add(action, tags, content, campaign);
    }

    public dislike = async (tags: TagsModel[], content?: ContentModel, campaign?: CampaignModel): Promise<ActivityResponseBody> => {
        const action = ActionsEnum.DISLIKE;

        return await this.add(action, tags, content, campaign);
    }

    public addPageview = async (tags: TagsModel[], content?: ContentModel, campaign?: CampaignModel): Promise<ActivityResponseBody> => {
        const action = ActionsEnum.PAGE_VIEW;

        return await this.add(action, tags, content, campaign);
    }

    public addContentConsumed = async (tags: TagsModel[], content?: ContentModel, campaign?: CampaignModel): Promise<ActivityResponseBody> => {
        const action = ActionsEnum.CONTENT_CONSUMED;

        return await this.add(action, tags, content, campaign);
    }

    public addShare = async (tags: TagsModel[], content?: ContentModel, campaign?: CampaignModel): Promise<ActivityResponseBody> => {
        const action = ActionsEnum.SHARE;

        return await this.add(action, tags, content, campaign);
    }

    public addComment = async (tags: TagsModel[], content?: ContentModel, campaign?: CampaignModel): Promise<ActivityResponseBody> => {
        const action = ActionsEnum.COMMENT;

        return await this.add(action, tags, content, campaign);
    }

    public addClickAd = async (tags: TagsModel[], content?: ContentModel, campaign?: CampaignModel): Promise<ActivityResponseBody> => {
        const action = ActionsEnum.CLICK_AD;

        return await this.add(action, tags, content, campaign);
    }

    public addConversion = async (tags: TagsModel[], content?: ContentModel, campaign?: CampaignModel): Promise<ActivityResponseBody> => {
        const action = ActionsEnum.CONVERSION;

        return await this.add(action, tags, content, campaign);
    }

    public getOwn = async (filters?: OwnActivityFilters, disableCache?: boolean): Promise<PaginationActivities> => {
        disableCache = disableCache ? disableCache : false;

        if (filters) {
            filters = new OwnActivityFilters(filters);
        }

        if (this.idSchema != IdSchemaEnum.NATIVE) {
            const response = await this.activityHttps.getOwnActivities(filters, disableCache);

            for (const activity of response.data) {
                await this.activityService.remapTagsIds(activity.context.tags, IdSchemaEnum.NATIVE, this.idSchema);
            }

            return response;
        }

        return await this.activityHttps.getOwnActivities(filters, disableCache);
    }

    public getForUser = async (userId: string, filters?: UserActivityFilters, disableCache?: boolean): Promise<PaginationActivities> => {
        disableCache = disableCache ? disableCache : false;

        if (filters) {
            filters = new UserActivityFilters(filters);
        }

        if (this.idSchema != IdSchemaEnum.NATIVE) {
            const response = await this.activityHttps.getActivitiesForUser(userId, filters, disableCache);

            for (const activity of response.data) {
                await this.activityService.remapTagsIds(activity.context.tags, IdSchemaEnum.NATIVE, this.idSchema);
            }

            return response;
        }

        return await this.activityHttps.getActivitiesForUser(userId, filters, disableCache);

    }
}