import Profile from "./Core/Namespaces/Profile/Profile";
import SDKConfigurationModel from "./Core/Configurator/Models/SDKConfiguraitonModel";
import ConfigurationValidator from "./Core/Configurator/ConfigurationValidator";
import Football from "./Core/Namespaces/Football/Football";
import { IdSchemaEnum } from "./Core/Configurator/Enums/IdSchemaEnum";
import Predictor from "./Core/Namespaces/Predictor/Predictor";
import Loyalty from "./Core/Namespaces/Loyalty/Loyalty";
import { ValidationResponseEnum } from "./Core/Configurator/Enums/ValidationResponseEnum";
import { EnvironmentEnum } from "./Core/Configurator/Enums/EnvironmentEnum";
import { LangEnum } from "./Core/Configurator/Enums/LangEnum";
import TopX from "./Core/Namespaces/TopX/TopX";
import MatchQuiz from "./Core/Namespaces/MatchQuiz/MatchQuiz";
import Activity from "./Core/Namespaces/Activity/Activity";
import ConfigValidationModel from "./Core/Configurator/Models/ConfigValidationModel";
import { LogEnum } from "./Core/Configurator/Enums/LogEnum";
import AuthProviderConfigModel from "./Core/Configurator/Models/AuthProviderConfigModel";
import { ErrorHandlingModeEnum } from "./Core/Configurator/Enums/ErrorHandlingModeEnum";

export function FansUnitedSDK(config: SDKConfigurationModel){
    const configValidator = new ConfigurationValidator();
    const validation = configValidator.validate(config);

    if (validation.idSchema.validation === ValidationResponseEnum.MISSING_VALUE || validation.idSchema.validation === ValidationResponseEnum.INVALID_VALUE) {
        console.warn(validation.idSchema.message.text);
        config.idSchema = IdSchemaEnum.NATIVE;
    }
    if (validation.environment.validation === ValidationResponseEnum.MISSING_VALUE || validation.environment.validation === ValidationResponseEnum.INVALID_VALUE) {
        console.warn(validation.environment.message.text);
        config.environment = EnvironmentEnum.PROD;
    }
    if (validation.lang.validation === ValidationResponseEnum.MISSING_VALUE || validation.lang.validation === ValidationResponseEnum.INVALID_VALUE) {
        console.warn(validation.lang.message.text);
        config.lang = LangEnum.EN;
    }
    if (validation.errorHandlingMode.validation === ValidationResponseEnum.MISSING_VALUE || validation.errorHandlingMode.validation === ValidationResponseEnum.INVALID_VALUE) {
        console.warn(validation.errorHandlingMode.message.text);
        config.errorHandlingMode = ErrorHandlingModeEnum.DEFAULT;
    }
    if (validation.authProvider.validation === ValidationResponseEnum.INVALID_VALUE) {
        console.warn(validation.authProvider.message.text);
    }

    if (validation.isValid) {
        return {
            profile: new Profile(config),
            football: new Football(config),
            predictor: new Predictor(config),
            loyalty: new Loyalty(config),
            topX: new TopX(config),
            matchQuiz: new MatchQuiz(config),
            activity: new Activity(config)
        }
    } else {
        throwValidationMessage(validation);
    }
}

const throwValidationMessage = (validation: ConfigValidationModel) => {
    const authProvider = 'authProvider';
    const isValid = 'isValid';

    Object.keys(validation).forEach((property: string) => {
        //@ts-ignore
        const fieldValidation = validation[property];
        if (property !== isValid && property !== authProvider) {
            if (fieldValidation.message.type === LogEnum.ERROR) {
                throw new Error(fieldValidation.message.text);
            }
        } else if (property !== isValid && property === authProvider) {
            Object.keys(new AuthProviderConfigModel()).forEach((key: string) => {
                if (fieldValidation[key].message.type === LogEnum.ERROR) {
                    throw new Error(fieldValidation[key].message.text);
                }
            });
        }
    });
};