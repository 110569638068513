import { MarketEnum } from "../../Predictor/Enums/MarketEnum";
import ProfileStatsModel from "../../Profile/Models/Stats/ProfileStatsModel";
import SuccessRatePercent from "../../Profile/Models/Stats/SuccessRatePercent";
import ActivityResponseBody from "../Models/ActivityResponseBody";
import PaginationActivities from "../Models/PaginationActivities";

export default class ActivityRemapper {
  public remapSingleResponse = (response: any) => {
    const model = new ActivityResponseBody();

    model.id = response.id;
    model.profileId = response.profile_id;
    model.property = response.property;
    model.value = response.value;
    model.action = response.action;
    model.context.content = response.context.content;
    model.context.tags = response.context.tags;
    model.context.campaign = response.context.campaign;
    model.points = response.points;

    return model;
  };

  public remapActivitiesResponse = (response: any) => {
    const model = new PaginationActivities();

    model.meta.pagination.currentPage = response.meta.pagination.current_page;
    model.meta.pagination.itemsPerPage =
      response.meta.pagination.items_per_page;
    model.meta.pagination.numberOfPages =
      response.meta.pagination.number_of_pages;
    model.meta.pagination.totalItems = response.meta.pagination.total_items;
    model.data = response.data.map((data: any) =>
      this.remapSingleResponse(data)
    );

    return model;
  };

  public remapStatsReponse = (response: any) => {
    const model = new ProfileStatsModel();

    model.profileId = response.profile_id;
    model.tier = response.tier;
    model.points = response.points;
    model.successRates.overallPercent = response.success_rates.overall_percent;
    model.successRates.byFootballCompetition = this.constructNewRecord(
      response.success_rates.by_competition
    );
    model.successRates.byFootballTeam = this.constructNewRecord(
      response.success_rates.by_team
    );
    model.successRates.byFootballMarket = this.constructNewRecord(
      response.success_rates.by_market
    );

    return model;
  };

  private constructNewRecord = (
    entity: any
  ): Record<string, SuccessRatePercent> => {
    /*
      Record<Keys, Type>:
        Constructs an object type whose property keys are string and whose property values are SuccessRatePercent.
        This utility can be used to map the properties of a type to another type.
      */
    let newRecord: Record<string, SuccessRatePercent>;

    if (Object.entries(entity).length) {
      Object.entries(entity).forEach(([key, value]) => {
        newRecord = {
          ...newRecord,
          [key]: this.remapSuccessRatePercent(value),
        };

        //@ts-ignore
        if (MarketEnum[key]) {
          delete newRecord[key].model;
        }
      });
    } else {
      return entity;
    }

    return newRecord;
  };

  private remapSuccessRatePercent = (response: any) => {
    const newSuccessRatePercent = new SuccessRatePercent();

    newSuccessRatePercent.successRatePercent = response.success_rate_percent;

    return newSuccessRatePercent;
  };
}
