import SDKConfigurationModel from "../../Configurator/Models/SDKConfiguraitonModel";
import PredictorHttps from "../../Https/PredictorHttps";
import IdMappingService from "../../IdMapping/IdMappingService";
import PredictorFacade from "./Facade/PredictorFacade";
import MatchSummaryModel from "./Models/Summary/MatchSummaryModel";
import { MarketEnum } from "./Enums/MarketEnum";
import ClientHttps from "../../Https/ClientHttps";
import PredictionsFilters from "./Models/Predictions/PredictionsFilters";
import PaginationModel from "../../Global/Models/Pagination/PaginationModel";
import { FeaturesConfigModels } from "../../Global/Types/GlobalTypes";
import PredictionResponseModel from "./Models/Predictions/PredictionResponseModel";

export default class Predictor {
    private predictorHttps: PredictorHttps = null;
    private clientHttps: ClientHttps = null;
    private predictorFacade: PredictorFacade = null;
    private idMapping: IdMappingService = null;

    constructor(config: SDKConfigurationModel) {
        this.idMapping = new IdMappingService(config);
        this.predictorHttps = new PredictorHttps(config, this.idMapping);
        this.clientHttps = new ClientHttps(config);
        this.predictorFacade = new PredictorFacade(config, this.predictorHttps, this.clientHttps, this.idMapping);
    }

    public makeFootballPrediction = async (matchId: string, market: MarketEnum, value: any, playerId?: string) => {
        return await this.predictorFacade.makeFootballPrediction(matchId, market, value, playerId);
    };

    public getMatchSummary = async (matchId: string, disableCache?: boolean): Promise<MatchSummaryModel> => {
        return await this.predictorFacade.getMatchSummary(matchId, disableCache);
    };

    public getMarketSummary = async (matchId: string, market: MarketEnum, playerId?:string, disableCache?: boolean): Promise<any> => {
        return await this.predictorFacade.getMarketSummary(matchId, market, playerId, disableCache);
    };

    public getConfig = async (): Promise<FeaturesConfigModels> => {
        return await this.predictorFacade.getConfig();
    };

    public deleteFootballPrediction = async (predictionId: string): Promise<boolean> => {
        return await this.predictorFacade.deleteFootballPrediction(predictionId);
    };

    public getMyPredictions = async (filters?: PredictionsFilters): Promise<PaginationModel> => {
        return await this.predictorFacade.getMyPredictions(filters);
    };

    public getMyCurrentPredictions = async (filters?: PredictionsFilters): Promise<PaginationModel> => {
        return await this.predictorFacade.getMyCurrentPredictions(filters);
    };

    public getMyPastPredictions = async (filters?: PredictionsFilters): Promise<PaginationModel> => {
        return await this.predictorFacade.getMyPastPredictions(filters);
    };

    public getMyPredictionsForMatches = async (matchIds: string[], filters?: PredictionsFilters): Promise<PaginationModel> => {
        return await this.predictorFacade.getMyPredictionsForMatches(matchIds, filters);
    };

    public getUserPredictions = async (userId: string, filters?: PredictionsFilters, disableCache?: boolean): Promise<PaginationModel> => {
        return await this.predictorFacade.getUserPredictions(userId, filters, disableCache);
    };

    public getUserCurrentPredictions = async (userId: string, filters?: PredictionsFilters, disableCache?: boolean): Promise<PaginationModel> => {
        return await this.predictorFacade.getUserCurrentPredictions(userId, filters, disableCache);
    };

    public getUserPastPredictions = async (userId: string, filters?: PredictionsFilters, disableCache?: boolean): Promise<PaginationModel> => {
        return await this.predictorFacade.getUserPastPredictions(userId, filters, disableCache);
    };

    public getUserPredictionsForMatches = async (userId: string, matchIds: string[], filters?: PredictionsFilters, disableCache?: boolean): Promise<PaginationModel> => {
        return await this.predictorFacade.getUserPredictionsForMatches(userId, matchIds, filters, disableCache);
    };

    public getPredictionById = async (predictionId: string): Promise<PredictionResponseModel> => {
        return await this.predictorFacade.getPredictionById(predictionId);
    };
}