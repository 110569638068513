interface FansUnitedException {
  error: {
    code: number;
    status: string;
    message: string;
  };
}

export default class StandardFansUnitedException {
  /* Wrap in response, because in every HTTPS service we throw the response when there is catched exception.
    That is because when we receive exception from the API, to be able to access that error we need to throw the response from the axios error.
    */
  response: FansUnitedException = {
    error: {
      code: 0,
      status: "",
      message: "",
    },
  };

  constructor(code: number, status: string, message: string) {
    this.response.error.code = code;
    this.response.error.status = status;
    this.response.error.message = message;
  }
}
