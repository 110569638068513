export const ID_TYPES = {
  native: "id",
  sportradar: "sportradar_id",
  sportal365: "sportal365_id",
  enetpulse: "enetpulse_id",
};

export const documentationLinks = {
  PREDICTOR_USER_RELATED_OPERATION:
    "https://docs.fansunitedmedia.com/docs/predictor-operations#user-related-operations",
};

export const validFixtureFields = [
  "matchId",
  "matchType",
  "market",
  "prediction",
];

export const marketToKeySummaryMap = new Map<string, string>()
  .set("FT_1X2", "fullTimeOneXTwo")
  .set("HT_1X2", "halfTimeOneXTwo")
  .set("BOTH_TEAMS_SCORE", "bothTeamsScore")
  .set("DOUBLE_CHANCE", "doubleChance")
  .set("HT_FT", "halfTimeFullTime")
  .set("PLAYER_SCORE", "playerScore")
  .set("PLAYER_YELLOW_CARD", "playerYellowCard")
  .set("PLAYER_RED_CARD", "playerRedCard")
  .set("RED_CARD_MATCH", "redCardMatch")
  .set("PENALTY_MATCH", "penaltyMatch")
  .set("PLAYER_SCORE_FIRST_GOAL", "playerScoreFirstGoal")
  .set("CORNERS_MATCH", "cornersMatch")
  .set("CORRECT_SCORE", "correctScore")
  .set("CORRECT_SCORE_ADVANCED", "correctScoreAdvanced")
  .set("CORRECT_SCORE_HT", "correctScoreHalfTime")
  .set("PLAYER_SCORE_HATTRICK", "playerScoreHattrick")
  .set("PLAYER_SCORE_TWICE", "playerScoreTwice")
  .set("OVER_GOALS_0_5", "overZeroPointFiveGoals")
  .set("OVER_GOALS_1_5", "overOnePointFiveGoals")
  .set("OVER_GOALS_2_5", "overTwoPointFiveGoals")
  .set("OVER_GOALS_3_5", "overThreePointFiveGoals")
  .set("OVER_GOALS_4_5", "overFourPointFiveGoals")
  .set("OVER_GOALS_5_5", "overFivePointFiveGoals")
  .set("OVER_GOALS_6_5", "overSixPointFiveGoals")
  .set("OVER_CORNERS_6_5", "overSixPointFiveCorners")
  .set("OVER_CORNERS_7_5", "overSevenPointFiveCorners")
  .set("OVER_CORNERS_8_5", "overEightPointFiveCorners")
  .set("OVER_CORNERS_9_5", "overNinePointFiveCorners")
  .set("OVER_CORNERS_10_5", "overTenPointFiveCorners")
  .set("OVER_CORNERS_11_5", "overElevenPointFiveCorners")
  .set("OVER_CORNERS_12_5", "overTwelvePointFiveCorners")
  .set("OVER_CORNERS_13_5", "overThirteenPointFiveCorners");
