import { limitIds, setBaseUrl } from "../Global/Helper";
import SDKConfiguraitonModel from "../Configurator/Models/SDKConfiguraitonModel";
import FootballCountryModel from "../Namespaces/Football/Models/Country/FootballCountryModel";
import FootballCountryRemapper from "../Namespaces/Football/Models/Country/Remappers/FootballCountryRemapper";
import CompetitionFilters from "../Namespaces/Football/Models/Competition/CompetitionFilters";
import CompetitionBasicModel from "../Namespaces/Football/Models/Competition/CompetitionBasicModel";
import CompetitionFullModel from "../Namespaces/Football/Models/Competition/CompetitionFullModel";
import CompetitionBasicRemapper from "../Namespaces/Football/Models/Competition/Remappers/CompetitionBasicRemapper";
import CompetitionFullRemapper from "../Namespaces/Football/Models/Competition/Remappers/CompetitionFullRemapper";
import TeamBasicModel from "../Namespaces/Football/Models/Team/TeamBasicModel";
import TeamFullModel from "../Namespaces/Football/Models/Team/TeamFullModel";
import TeamBasicRemapper from "../Namespaces/Football/Models/Team/Remappers/TeamBasicRemapper";
import TeamFullRemapper from "../Namespaces/Football/Models/Team/Remappers/TeamFullRemapper";
import MatchFullModel from "../Namespaces/Football/Models/Match/MatchFullModel";
import MatchBasicRemapper from "../Namespaces/Football/Models/Match/Remappers/MatchBasicRemapper";
import MatchFullRemapper from "../Namespaces/Football/Models/Match/Remappers/MatchFullRemapper";
import TopPlayerModel from "../Namespaces/Football/Models/Player/TopPlayerModel";
import PlayerFullModel from "../Namespaces/Football/Models/Player/PlayerFullModel";
import TopPlayerRemapper from "../Namespaces/Football/Models/Player/Remappers/TopPlayerRemapper";
import PlayerBasicRemapper from "../Namespaces/Football/Models/Player/Remappers/PlayerBasicRemapper";
import PlayerFullRemapper from "../Namespaces/Football/Models/Player/Remappers/PlayerFullRemapper";
import SearchRemapper from "../Namespaces/Football/Models/Search/SearchRemapper";
import TeamFilters from "../Namespaces/Football/Models/Team/TeamFilters";
import MatchFilters from "../Namespaces/Football/Models/Match/MatchFilters";
import PlayerFilters from "../Namespaces/Football/Models/Player/PlayerFilters";
import SearchFilters from "../Namespaces/Football/Models/Search/SearchFilters";
import SearchModel from "../Namespaces/Football/Models/Search/SearchModel";
import FootballPaginationModel from "../Namespaces/Football/Models/Pagination/FootballPaginationModel";
import FootballMetaRemapper from "../Namespaces/Football/Models/Pagination/Remapper/FootballMetaRemapper";
import Https from "./Https";
export default class FootballHttps extends Https {
    private limitIds: number = 200;
    private countryRemapper: FootballCountryRemapper = null;
    private competitionBasicRemapper: CompetitionBasicRemapper = null;
    private competitionFullRemapper: CompetitionFullRemapper = null;
    private teamBasicRemapper: TeamBasicRemapper = null;
    private teamFullRemapper: TeamFullRemapper = null;
    private matchBasicRemapper: MatchBasicRemapper = null;
    private matchFullRemapper: MatchFullRemapper = null;
    private playerBasicRemapper: PlayerBasicRemapper = null;
    private playerFullRemapper: PlayerFullRemapper = null;
    private topPlayerRemapper: TopPlayerRemapper = null;
    private searchRemapper: SearchRemapper = null;
    private footballMetaRemapper: FootballMetaRemapper = null;

    constructor(config: SDKConfiguraitonModel) {
        super(config, setBaseUrl(config.environment, "football"));
        this.apiSignInUrl = config.lang !== "en" ? this.apiSignInUrl + `&lang=${config.lang}` : this.apiSignInUrl;
        this.countryRemapper = new FootballCountryRemapper();
        this.competitionBasicRemapper = new CompetitionBasicRemapper();
        this.competitionFullRemapper = new CompetitionFullRemapper();
        this.teamBasicRemapper = new TeamBasicRemapper();
        this.teamFullRemapper = new TeamFullRemapper();
        this.matchBasicRemapper = new MatchBasicRemapper();
        this.matchFullRemapper = new MatchFullRemapper();
        this.playerBasicRemapper = new PlayerBasicRemapper();
        this.playerFullRemapper = new PlayerFullRemapper();
        this.topPlayerRemapper = new TopPlayerRemapper();
        this.searchRemapper = new SearchRemapper();
        this.footballMetaRemapper = new FootballMetaRemapper();
    }

    public getCountries = async (disableCache: boolean): Promise<FootballCountryModel[]> => {
        try {
            let url = `/countries${this.apiSignInUrl}`;
            const timestampCache = Date.now();

            if (disableCache) {
                url += `&disable_cache=${timestampCache}`;
            }

            const response = await this.instance.get(url);
            return this.countryRemapper.countriesFromResponse(response.data.data);
        } catch (e) {
            const fansUnitedException = this.transformAxiosErrorToFansUnitedException(e);
            console.warn('There was a problem with the football countries request', fansUnitedException);
            throw fansUnitedException.response;
        }
    };

    /**
     * Makes request for fetching competitions from Football API with set filters. When ids exceed the limit in URL the requests are splited.
     * @param filters
     * @returns List of competitions with concrete filters. If no filters are passed we retrieve list of competitions sorted by ids.
     */

    public getCompetitions = async (filters?: CompetitionFilters, disableCache?: boolean): Promise<CompetitionBasicModel[]> => {
        try {
            let response: any = {};
            const timestampCache = Date.now();
            disableCache = !disableCache ? false : disableCache;

            if (filters && filters.competitionIds && filters.competitionIds.length > this.limitIds - 1) {
                let competitionsArrays: any = [];
                const limitedIds = limitIds(filters.competitionIds, this.limitIds);
                for (const ids of limitedIds) {
                    filters.competitionIds = ids;
                    let url = `/competitions${this.apiSignInUrl}${filters.constructFilterUrl()}`;

                    if (disableCache) {
                        url += `&disable_cache=${timestampCache}`;
                    }

                    response = await this.instance.get(url);
                    competitionsArrays.push(response.data.data.map((competition: any) => this.competitionBasicRemapper.fromResponse(competition)));
                }
                //Merge an array of arrays
                const competitions = Array.prototype.concat.apply([], competitionsArrays);

                return competitions;
            } else {
                let url = `/competitions${this.apiSignInUrl}`;

                if (filters) {
                    url += `${filters.constructFilterUrl()}`;
                }

                if (disableCache) {
                    url +=`&disable_cache=${timestampCache}`;
                  }

                response = await this.instance.get(url);
                return response.data.data.map((competition: any) => this.competitionBasicRemapper.fromResponse(competition));
            }
        } catch (e) {
            const fansUnitedException = this.transformAxiosErrorToFansUnitedException(e);
            console.warn("There was a problem with getCompetitions request", fansUnitedException);
            throw fansUnitedException.response;
        }
    };

    public getCompetitionById = async (id: string, disableCache: boolean): Promise<CompetitionFullModel> => {
        try {
            let url = `/competitions/${id}${this.apiSignInUrl}`;
            const timestampCache = Date.now();

            if (disableCache) {
                url += `&disable_cache=${timestampCache}`;
            }

            const response = await this.instance.get(url);
            return this.competitionFullRemapper.fromResponse(response.data.data);
        } catch (e) {
            const fansUnitedException = this.transformAxiosErrorToFansUnitedException(e);
            console.warn("There was a problem with getCompetitionById request", fansUnitedException);
            throw fansUnitedException.response;
        }
    };

    public getTopCompetitions = async (disableCache: boolean): Promise<CompetitionBasicModel[]> => {
        try {
            let url = `/competitions/top${this.apiSignInUrl}`;
            const timestampCache = Date.now();

            if (disableCache) {
                url += `&disable_cache=${timestampCache}`;
            }

            const response = await this.instance.get(url);
            return response.data.data.map((resp: any) => this.competitionBasicRemapper.fromResponse(resp));
        } catch (e) {
            const fansUnitedException = this.transformAxiosErrorToFansUnitedException(e);
            console.warn("There was a problem with getTopCompetitions request", fansUnitedException);
            throw fansUnitedException.response;
        }
    };

    /**
     * Makes request for fetching teams from Football API with set filters. When ids exceed the limit in URL the requests are splited.
     * @param filters
     * @returns List of teams with concrete filters. If no filters are passed we retrieve list of teams sorted by ids.
     */

    public getTeams = async (filters?: TeamFilters, disableCache?: boolean): Promise<FootballPaginationModel> => {
        try {
            let response: any = {};
            const timestampCache = Date.now();

            if (filters && filters.teamIds && filters.teamIds.length > this.limitIds) {
                let teamsArrays: any = [];
                const limitedIds = limitIds(filters.teamIds, this.limitIds);
                for (const ids of limitedIds) {
                    filters.teamIds = ids;
                    let url = `/teams${this.apiSignInUrl}${filters.constructFilterUrl()}`;

                    if (disableCache) {
                        url += `disable_cache=${timestampCache}`;
                    }

                    response = await this.instance.get(url);
                    teamsArrays.push(response.data.data.map((team: any) => this.teamBasicRemapper.fromResponse(team)));
                }
                //Merge an array of arrays
                const teams = Array.prototype.concat.apply([], teamsArrays);

                return teams;
            } else {
                let teamsPaginationModel = new FootballPaginationModel();
                let url = `/teams${this.apiSignInUrl}`;

                if (filters) {
                    url += `${filters.constructFilterUrl()}`;
                }

                if (disableCache) {
                    url +=`&disable_cache=${timestampCache}`;
                }

                response = await this.instance.get(url);
                teamsPaginationModel.meta = this.footballMetaRemapper.remapMetaProp(response.data.meta);
                teamsPaginationModel.data = response.data.data.map((team: any) => this.teamBasicRemapper.fromResponse(team));

                return teamsPaginationModel;
            }
        } catch (e) {
            const fansUnitedException = this.transformAxiosErrorToFansUnitedException(e);
            console.warn("There was a problem with getTeams request", fansUnitedException);
            throw fansUnitedException.response;
        }
    };

    public getTeamById = async (id: string, disableCache: boolean): Promise<TeamFullModel> => {
        try {
            let url = `/teams/${id}${this.apiSignInUrl}`;
            const timestampCache = Date.now();

            if (disableCache) {
                url += `disable_cache=${timestampCache}`;
            }

            const response = await this.instance.get(url);
            return this.teamFullRemapper.fromResponse(response.data.data);
        } catch (e) {
            const fansUnitedException = this.transformAxiosErrorToFansUnitedException(e);
            console.warn('There was a problem with getTeamById request', fansUnitedException);
            throw fansUnitedException.response;
        }
    };

    public getTopTeams = async (disableCache: boolean): Promise<TeamBasicModel[]> => {
        try {
            let url = `/teams/top${this.apiSignInUrl}`;
            const timestampCache = Date.now();

            if (disableCache) {
                url += `$disable_cache=${timestampCache}`;
            }

            const response = await this.instance.get(url);
            return response.data.data ? response.data.data.map((team: any) => this.teamBasicRemapper.fromResponse(team)) : [];
        } catch (e) {
            const fansUnitedException = this.transformAxiosErrorToFansUnitedException(e);
            console.warn('There was a problem with getTopTeams request', fansUnitedException);
            throw fansUnitedException.response;
        }
    };

    public getNextMatchForTeam = async (id: string, disableCache: boolean): Promise<MatchFullModel> => {
        try {
            let url = `/teams/${id}/next-match${this.apiSignInUrl}`;
            const timestampCache = Date.now();

            if (disableCache) {
                url += `&disable_cache=${timestampCache}`;
            }

            const response = await this.instance.get(url);
            return this.matchFullRemapper.fromResponse(response.data.data);
        } catch (e) {
            const fansUnitedException = this.transformAxiosErrorToFansUnitedException(e);
            console.warn('There was a problem with getNextMatchForTeam request', fansUnitedException);
            throw fansUnitedException.response;
        }
    };

    public getPrevMatchForTeam = async (id: string, disableCache: boolean): Promise<MatchFullModel> => {
        try {
            let url = `/teams/${id}/previous-match${this.apiSignInUrl}`;
            const timestampCache = Date.now();

            if (disableCache) {
                url += `disable_cache=${timestampCache}`;
            }

            const response = await this.instance.get(url);
            return this.matchFullRemapper.fromResponse(response.data.data);
        } catch (e) {
            const fansUnitedException = this.transformAxiosErrorToFansUnitedException(e);
            console.warn('There was a problem with getPrevMatchForTeam request', fansUnitedException);
            throw fansUnitedException.response;
        }
    };

    /**
     * Makes request for fetching players from Football API with set filters. When ids exceed the limit in URL the requests are splited.
     * @param filters
     * @returns List of players with concrete filters. If no filters are passed we retrieve list of players sorted by ids.
     */

    public getPlayers = async (filters?: PlayerFilters, disableCache?: boolean): Promise<FootballPaginationModel> => {
        try {
            let response: any = {};
            const timestampCache = Date.now();

            if (filters && filters.playerIds && filters.playerIds.length > this.limitIds - 1) {
                let playersArrays: any = [];
                const limitedIds = limitIds(filters.playerIds, this.limitIds);

                for (const ids of limitedIds) {
                    filters.playerIds = ids;
                    let url = `/players${this.apiSignInUrl}${filters.constructFilterUrl()}`;

                    if (disableCache) {
                        url += `&disable_cache=${timestampCache}`;
                    }

                    response = await this.instance.get(url);
                    playersArrays.push(response.data.data.map((resp: any) => this.teamBasicRemapper.fromResponse(resp)));
                }
                //Merge an array of arrays
                const players = Array.prototype.concat.apply([], playersArrays);

                return players;
            } else {
                let playersPaginationModel = new FootballPaginationModel();
                let url = `/players${this.apiSignInUrl}`;

                if (filters) {
                    url += `${filters.constructFilterUrl()}`;
                }

                if (disableCache) {
                    url +=`&disable_cache=${timestampCache}`;
                }

                response = await this.instance.get(url);
                playersPaginationModel.meta = this.footballMetaRemapper.remapMetaProp(response.data.meta);
                playersPaginationModel.data = response.data.data.map((player: any) => this.playerBasicRemapper.fromResponse(player));

                return playersPaginationModel;
            }
        } catch (e) {
            const fansUnitedException = this.transformAxiosErrorToFansUnitedException(e);
            console.warn('There was a problem with getPlayers request', fansUnitedException);
            throw fansUnitedException.response;
        }
    };

    public getPlayerById = async (id: string, disableCache: boolean): Promise<PlayerFullModel> => {
        try {
            let url = `/players/${id}${this.apiSignInUrl}`;
            const timestampCache = Date.now();

            if (disableCache) {
                url += `&disable_cache=${timestampCache}`;
            }

            const response = await this.instance.get(url);
            return this.playerFullRemapper.fromResponse(response.data.data);
        } catch (e) {
            const fansUnitedException = this.transformAxiosErrorToFansUnitedException(e);
            console.warn('There was a problem with getPlayerById request', fansUnitedException);
            throw fansUnitedException.response;
        }
    };

    public getTopPlayers = async (disableCache: boolean): Promise<TopPlayerModel[]> => {
        try {
            let url = `/players/top${this.apiSignInUrl}`;
            const timestampCache = Date.now();

            if (disableCache) {
                url += `&disable_cache=${timestampCache}`;
            }

            const response = await this.instance.get(url);
            return response.data.data.map((player: any) => this.topPlayerRemapper.fromResponse(player));
        } catch (e) {
            const fansUnitedException = this.transformAxiosErrorToFansUnitedException(e);
            console.warn('There was a problem with getTopPlayers request', fansUnitedException);
            throw fansUnitedException.response;
        }
    };

    public getNextMatchForPlayer = async (id: string, disableCache: boolean): Promise<MatchFullModel> => {
        try {
            let url = `/players/${id}/next-match${this.apiSignInUrl}`;
            const timestampCache = Date.now();

            if (disableCache) {
                url += `&disable_cache=${timestampCache}`;
            }

            const response = await this.instance.get(url);
            return this.matchFullRemapper.fromResponse(response.data.data);
        } catch (e) {
            const fansUnitedException = this.transformAxiosErrorToFansUnitedException(e);
            console.warn('There was a problem with getNextMatchForPlayer request', fansUnitedException);
            throw fansUnitedException.response;
        }
    };

    public getPrevMatchForPlayer = async (id: string, disableCache: boolean): Promise<MatchFullModel> => {
        try {
            let url = `/players/${id}/previous-match${this.apiSignInUrl}`;
            const timestampCache = Date.now();

            if (disableCache) {
                url += `&disable_cache=${timestampCache}`;
            }

            const response = await this.instance.get(url);
            return this.matchFullRemapper.fromResponse(response.data.data);
        } catch (e) {
            const fansUnitedException = this.transformAxiosErrorToFansUnitedException(e);
            console.warn('There was a problem with getPrevMatchForPlayer request', fansUnitedException);
            throw fansUnitedException.response;
        }
    };

    public getMatchById = async (id: string, disableCache: boolean): Promise<MatchFullModel> => {
        try {
            let url = `/matches/${id}${this.apiSignInUrl}`;
            const timestampCache = Date.now();

            if (disableCache) {
                url += `&disable_cache=${timestampCache}`;
            }

            const response = await this.instance.get(url);
            return this.matchFullRemapper.fromResponse(response.data.data);
        } catch (e) {
            const fansUnitedException = this.transformAxiosErrorToFansUnitedException(e);
            console.warn('There was a problem with getMatchById request', fansUnitedException);
            throw fansUnitedException.response;
        }
    };

    public getMatches = async (filters?: MatchFilters, disableCache?: boolean): Promise<FootballPaginationModel> => {
        try {
            let matchesPaginationModel = new FootballPaginationModel();
            let url = `/matches${this.apiSignInUrl}`;
            const timestampCache = Date.now();

            if (filters) {
                url += `${filters.constructFilterUrl()}`;
            }

            if (disableCache) {
                url +=`&disable_cache=${timestampCache}`;
            }

            const response = await this.instance.get(url);
            matchesPaginationModel.meta = this.footballMetaRemapper.remapMetaProp(response.data.meta);
            matchesPaginationModel.data = response.data.data.map((match: any) => this.matchBasicRemapper.fromResponse(match));

            return matchesPaginationModel;
        } catch (e) {
            const fansUnitedException = this.transformAxiosErrorToFansUnitedException(e);
            console.warn("There was a problem with getMatches request", fansUnitedException);
            throw fansUnitedException.response;
        }
    };

    public search = async (filters: SearchFilters, disableCache: boolean): Promise<SearchModel> => {
        try {
            let url = `/search${this.apiSignInUrl}${filters.constructFilterUrl()}`;
            const timestampCache = Date.now();

            if (disableCache) {
                url += `&disable_cache=${timestampCache}`;
            }

            const response = await this.instance.get(url);
            return this.searchRemapper.fromResponse(response.data.data);
        } catch (e) {
            const fansUnitedException = this.transformAxiosErrorToFansUnitedException(e);
            console.warn("There was a problem with search request", fansUnitedException);
            throw fansUnitedException.response;
        }
    };
}
